// FILTER TO ELIMINATE THOSE ALREADY SEEN
const filterRecommendedViews = (data): any => {
    const recommendedViews = checkRecommendedViews();
    return data.filter((product: any) => !recommendedViews.includes(product.order_product_id));
};

// CHECK RECOMMENDED ONES ALREADY SEEN IN CE TO REMOVE THEM
const checkRecommendedViews = () => {
    const getValuesLocalStorage = JSON.parse(localStorage.getItem('recommended_products_viewed'));
    if (!!getValuesLocalStorage && getValuesLocalStorage.length) return getValuesLocalStorage;
    return [];
};

export default filterRecommendedViews;
