// COMPONENTS
import { YouCanSave } from './YouCanSave';
import { YouAreSaving } from './YouAreSaving';
// UTILS && HOOKS
import { useUser } from 'utils/hooks';
// STYLES
import styles from './TableSaving.module.css';

const TableSaving = ({
    skeleton,
    orderId,
    getProductSelected,
    minimunPurchase,
    handlerModalMinimunPurchase,
    allProductHasChecked,
    unselectedProductNotificationVisibility,
    withoutRecommendations,
    uncheckedProductsByDrugNamesMinOrMax,
    handlerModalMinMaxQuantity,
    loadingProduct,
    getProductQuantity,
}) => {
    const { user } = useUser({});
    const EX6144 = user?.EX6144;
    const GRID_ROW_SAVING = EX6144 ? (withoutRecommendations ? 4 : 5) : withoutRecommendations ? 5 : 6;
    return (
        <div className={styles['grid-saving']} style={{ gridRow: GRID_ROW_SAVING }}>
            {/* left box */}
            <YouAreSaving skeleton={skeleton} />
            {/* right box */}
            <YouCanSave
                getProductQuantity={getProductQuantity}
                orderId={orderId}
                getProductSelected={getProductSelected()}
                minimunPurchase={minimunPurchase}
                handlerModalMinimunPurchase={handlerModalMinimunPurchase}
                allProductHasChecked={allProductHasChecked()}
                unselectedProductNotificationVisibility={unselectedProductNotificationVisibility}
                uncheckedProductsByDrugNamesMinOrMax={uncheckedProductsByDrugNamesMinOrMax}
                handlerModalMinMaxQuantity={handlerModalMinMaxQuantity}
                loadingProduct={loadingProduct}
                skeleton={skeleton}
            />
        </div>
    );
};

export default TableSaving;
