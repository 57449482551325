import { useState, useEffect, useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
// CONFIGS
import {
    EX4329,
    EX4503,
    EX4562,
    EX6156,
    EX6209,
    EX6753,
    EX6785,
    EX7160,
    FFSPLIT,
    PERFOMANCE_02,
} from 'config/flags';
// CONTEXTS
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
import { useModalWithoutCredit } from 'context/ModalWithoutCredit/ModalWithoutCredit';
import { useModalCdO } from 'context/modalCdO/modalCdO';
import {
    useDrugManufacturersContext,
    useFetchingContext,
    useGenericsContext,
    useLoadingProductContext,
    usePointOfSale,
    useSelectAllStateContext,
    useUpdateParentProductContext,
} from 'context';
// APIS
import { updateProductGroupWithForceSuggested, updateProductGroup, updateProductGroupBySplit } from 'api';
// TYPES
import { ServiceId } from 'common/types';
// UTILS && HOOKS
import { useUser } from '../use-user';
import { OfferCenters } from 'utils/enums';
import { DrugManufacturersId, StatusCdo } from 'utils/interfaces';
import { isEmptyObjectAtrribute } from 'utils/object';
import { isZettiFormat } from 'utils/pointOfSale';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { useIsManualCheckedContext } from 'context/productComparison/isManualChecked-context';

interface useTableRowV2Props {
    item: any;
    getCreditById: (id: number, selectItem: any) => void;
    setProductInfo: (obj: any) => void;
}

export const useTableRowV2 = ({ item, getCreditById, setProductInfo }: useTableRowV2Props) => {
    const router = useRouter();
    const { user } = useUser({});
    const { loading } = useFetchingContext();
    const { setLoadingProduct } = useLoadingProductContext();
    const { updateParentProduct } = useUpdateParentProductContext();
    const { isManualChecked, setIsManualChecked } = useIsManualCheckedContext();

    const { selectAllState } = useSelectAllStateContext();

    const { drugManufacturer, enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const { showAllGenerics } = useGenericsContext();

    // validation is zetti
    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);

    // FF's
    const EX4903 = user?.EX4903;
    const EX4904 = user?.EX4904;
    const EX4927 = user?.EX4927;
    const EX4800 = user?.EX4800 && !isZetti; // ff generics
    const EX5223 = user?.EX5223; //ff pvp render in discount
    const EX5506 = user?.EX5506; //FF epic insufficientCredit V2
    const EX5937 = user?.EX5937; //FF split stock

    // credit context
    const { openModal, setTypeModal } = useModalWithoutCredit();

    // item
    const [productGroup, setProductGroup] = useState<any>(() => item as any);

    const [showGeneric, setShowGeneric] = useState<boolean>(false);

    useEffect(() => {
        
            if (
                productGroup?.generics?.length &&
                productGroup?.generics?.some((generic) => !!generic?.properties?.generic_type)
            ) {
                setShowGeneric(true);
            }
        
    }, [productGroup?.generics]);

    /* #region MANUAL SELECTED */
    const [isManualSelected, setIsManualSelected] = useState(false);
    /* #endregion */

    /* #region WITHOUT SELECTION RED CHIP */
    const [isAppliedIndex, setIsAppliedIndex] = useState([]);
    /* #endregion */

    /* #region PROMO */
    const [multiplierPerAppliedPromo, setMultiplierPerAppliedPromo] = useState(0);

    // state to use it based on updateQuantityRequired function
    const [isFetchingProductGroups, setIsFetchingProductGroups] = useState(false);
    const [childrenGenerics, setChildrenGenerics] = useState([]);

    //selectAllPrevious
    const [selectAllStatePrevious, setSelectAllStatePrevious] = useState(null);

    /* #region generics validation */
    const [generics] = useState<boolean>(EX4800 ? productGroup?.generics?.length > 0 : false);
    const [isGenerics] = useState(!!EX4800 && generics);
    /* #endregion */

    const [genericsIdsAdd, setGenericsIdsAdd] = useState<number[]>([]);

    const CDO_DRUGMANUFACTURER: any = drugManufacturer?.find(
        (d: any) => d?.id === DrugManufacturersId.CentralDeOfertas,
    );

    const isInitialMount = useRef(true);

    useEffect(() => {
        
            if (isInitialMount.current) {
                // Esto sucede en la inicialización
                isInitialMount.current = false;
                if(EX7160 && productGroup?.generics?.length > 0 && productGroup?.generics?.some(generic => generic?.quantity > 0)) {
                    const isAddArray = productGroup.generics?.filter(generic => generic?.quantity > 0)?.map(g => g?.order_product_id);
                    setGenericsIdsAdd(isAddArray);
                }
            } else {
                // Esto sucede en actualizaciones posteriores
                if (productGroup?.generics?.length > 0 && showAllGenerics !== showGeneric) {
                    setShowGeneric(showAllGenerics);
                }
            }
        
    }, [showAllGenerics]);

    const handleCheckboxOnChange = async (quantity) => {
        let res = null;
        try {
            const itemCheckbox = checkbox.find((item) => item.isChecked === true);
            const priceItemSelected = productGroup?.products[itemCheckbox?.id]?.quantityDiscountApplies
                ? productGroup?.products[itemCheckbox?.id]?.discount?.price * quantity
                : productGroup?.products[itemCheckbox?.id]?.price * quantity;

            const product = {
                barcode: productGroup?.barcode,
                amountSelected: priceItemSelected,
            };

            const validation = EX5506 || getCreditById(itemCheckbox?.id, product);

            if (validation || !EX4903) {
                let updateProductGroupModel: any = {
                    barcode: productGroup.barcode,
                    quantity: quantity,
                };

                if (FFSPLIT && isManualSelected) {
                    updateProductGroupModel.force_suggested = drugManufacturerIdSelected()[0];
                }

                let { data } = await updateProductGroup(router.query.orderId, updateProductGroupModel);
                setProductGroup(data?.data);
                if (EX5937) {
                    if (!data?.data?.is_split) {
                        // EX6156 => mantener selección manual cuando actualiza cantidad en counterbox
                        if (EX6156) {
                            return setCheckbox(
                                checkbox?.map((item) => {
                                    return {
                                        ...item,
                                        isChecked: EX6753
                                            ? String(item.id) === String(data?.data?.suggested[0])
                                            : isManualSelected
                                            ? item?.isChecked
                                            : String(item.id) === String(data?.data?.suggested[0]),
                                    };
                                }),
                            );
                        } else {
                            return setCheckbox(
                                checkbox?.map((item) => {
                                    return {
                                        ...item,
                                        isChecked: String(item.id) === String(data?.data?.suggested[0]),
                                    };
                                }),
                            );
                        }
                    } else {
                        return setCheckbox(
                            checkbox?.map((item) => {
                                return {
                                    ...item,
                                    isChecked: data?.data?.suggested?.includes(item.id),
                                };
                            }),
                        );
                    }
                }
                res = true;
            } else {
                const name = drugManufacturer.find((drugManufacturer) => drugManufacturer?.id === itemCheckbox?.id)
                    ?.name;

                setTypeModal('');
                openModal(name);
                res = false;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.data?.validation?.quantity[0];
            if (errorMessage.match(/ZERO_QUANTITY/)) {
                let itemZero = item as any;
                itemZero.quantity = quantity;
                Object.keys(itemZero.products).map((drugManufacturerId) => {
                    if (itemZero.products[drugManufacturerId]?.total) {
                        itemZero.products[drugManufacturerId].total = 0;
                    }
                });
                setProductGroup(itemZero as any);
            }
        } finally {
            setLoadingProduct(false);
        }
        return res;
    };

    // update parent to receive validation in counterbox and delete item.
    useEffect(() => {
        if (EX4800 && !EX5937) {
            const areAllChildrenWithZeroQuantity = updateParentProduct?.every((child) => child?.quantity === 0);
            const findObjectWithSameId = updateParentProduct?.find(
                (elem) => elem?.orderProductIdParent === productGroup?.order_product_id,
            );
            if (!!findObjectWithSameId && Object.values(findObjectWithSameId)?.length > 0) {
                if (
                    +productGroup?.order_product_id === +findObjectWithSameId?.orderProductIdParent &&
                    +findObjectWithSameId?.quantity !== 0
                ) {
                    handleCheckboxOnChange(productGroup?.quantity);
                } else if (areAllChildrenWithZeroQuantity) {
                    handleCheckboxOnChange(1);
                }
            }
        }
    }, [updateParentProduct]);

    const drugManufacturerIdSelectedWithFF = () => {
        if (EX5937) {
            const selectedIds = drugManufacturerIdSelected();
            if (selectedIds?.length === 0) return true;
            // TO DO REVISAR
            const hasMatchingId = productGroup?.drugManufacturerIdSelected?.some((d) => selectedIds?.includes(d));
            return !!hasMatchingId;
        } else {
            return productGroup?.drugManufacturerIdSelected === drugManufacturerIdSelected();
        }
    };

    /* #endregion */

    /* #region DRUGMANUFACTURERS */
    const drugManufacturers = useCallback(
        () =>
            drugManufacturer?.map((drug_manufacturer) => ({
                ...drug_manufacturer,
            })),
        [drugManufacturer],
    );

    const hasDrugManufacturerError = (id: number) => {
        if (EX4904) {
            return extractedDataErrorIds?.includes(id);
        } else {
            return false;
        }
    };
    /* #endregion */

    const checkBonus = (bonusArray, productQuantity) => {
        let leftovers = productQuantity;
        let appliedBonuses = [];

        bonusArray?.forEach((bonus) => {
            if (leftovers >= bonus?.paid) {
                let freeUnits = Math.floor(leftovers / bonus.paid) * bonus.free;
                leftovers %= bonus.paid;

                if (freeUnits > 0) {
                    appliedBonuses.push({
                        paid: bonus.paid,
                        free: bonus.free,
                        gift: freeUnits,
                        apply: true,
                    });
                }
            }
        });

        // Añadimos esta verificación por si no se aplica ningún bonus
        if (appliedBonuses.length === 0) {
            appliedBonuses.push({
                gift: 0,
                apply: false,
            });
        }

        return appliedBonuses;
    };

    const bonus = useCallback(
        (id: number) => {
            const product = productItemByDrugmanufacturer(id) || {};

            if ('bonus' in product && product?.bonus) {
                return product?.bonus;
            }
        },
        [productGroup],
    );
    /* #endregion */

    /* #region CDO */
    const { openModalCdo } = useModalCdO();

    const currentStatusCdO = drugManufacturer?.find((drug) => drug?.id === DrugManufacturersId.CentralDeOfertas)
        ?.cdo_status_id;
    /* #endregion */

    /* #region CREDENTIALS */
    const { extractedDataError } = useCredentialErrors();
    const extractedDataErrorIds = extractedDataError?.map((item: any) => item.id);
    /* #endregion */

    const orderFill = useCallback(() => productGroup?.selectedProduct, [productGroup]);

    const productItemByDrugmanufacturer = useCallback(
        (drugManufacturerId, isGeneric = false, cellGenerics = {}): any => {
            if (!productGroup?.products) {
                return {} as any;
            } else if (isGeneric) {
                return cellGenerics;
            }
            return productGroup?.products[drugManufacturerId];
        },
        [productGroup],
    );

    const unitPrice = useCallback(
        (id, isGenerics = false, cellGenerics = {}) => {
            const { quantityDiscountApplies, discount, price } =
                productItemByDrugmanufacturer(id, isGenerics, cellGenerics) || {};

            if (isGenerics && !!cellGenerics?.quantityDiscountApplies) cellGenerics?.discount?.price;
            else cellGenerics?.price;

            if (quantityDiscountApplies) {
                return discount?.price;
            } else {
                return price;
            }
        },
        [productGroup],
    );

    /* #region USER */
    const pointOfSale = useCallback(() => {
        return user?.pointsOfSale?.find((item) => item?.id === +router?.query?.posId);
    }, [user]);
    /* #endregion */

    const lastUnitPrice = (id, isGenerics = false, cellGenerics = null) => {
        if (EX5223) {
            if (productGroup?.pvp) {
                return productGroup?.pvp;
            } else {
                return productItemByDrugmanufacturer(id, isGenerics)?.price;
            }
        } else {
            return productItemByDrugmanufacturer(id, isGenerics)?.price;
        }
    };

    const totalPrice = (id) => productItemByDrugmanufacturer(id)?.total;

    /* #region PRODUCT IDS */
    const productsIds = useCallback(
        () =>
            Object.entries(productGroup?.products || {})
                ?.map(([key, value]) => {
                    if (!value) return null;
                    return parseInt(key);
                })
                ?.filter((i) => i),
        [productGroup],
    );
    /* #endregion */

    /* #region new function because the suggested one is modified */
    const IdIncludeInSuggested = (id: number): boolean => {
        if (EX5937 && Array.isArray(productGroup?.suggested)) {
            // Now productGroup?.suggested is array
            return productGroup?.suggested?.includes(id);
        } else {
            return productGroup?.suggested === id;
        }
    };
    /* #endregion */

    const productSelectedCondition = useCallback(
        (id) => {
            if (EX5937) {
                return (
                    orderFill()?.drug_manufacturer_id === id ||
                    (!Boolean(orderFill()?.drug_manufacturer_id) && IdIncludeInSuggested(id))
                );
            } else {
                return orderFill()?.drug_manufacturer_id === id
                    ? true
                    : !Boolean(orderFill()?.drug_manufacturer_id) && productGroup?.suggested === id;
            }
        },
        [productGroup],
    );

    /* #region CHECKBOX */
    const [checkbox, setCheckbox] = useState<{ id: number; isChecked: boolean }[]>(() =>
        drugManufacturers()?.map(({ id }) => ({
            id,
            isChecked: productSelectedCondition(id),
        })),
    );

    const [helper, setHelper] = useState(0);

    const updateCheckbox = (drugManufacturerId) => {
        const product = {
            barcode: productGroup?.barcode,
            amountSelected: productGroup?.products[drugManufacturerId]?.total,
        };
        const res = EX5506 || getCreditById(parseInt(drugManufacturerId), product);
        if (res || !EX4903) {
            if (
                +drugManufacturerId === DrugManufacturersId.CentralDeOfertas &&
                drugManufacturers().at(-1)?.cdo_status_id !== StatusCdo.ENABLED
            ) {
                return;
            } else {
                if (EX5937) {
                    setCheckbox(
                        checkbox?.map((item) => {
                            if (String(item.id) === String(drugManufacturerId) && !item.isChecked) {
                                // celda seleccionada manualmente
                                return {
                                    ...item,
                                    isChecked: true,
                                };
                            } else {
                                // celda no seleccionada manualmente pero si esta dentro de los sugeridos por el spliteo
                                if (IdIncludeInSuggested(item.id) && productGroup?.is_split && item.isChecked) {
                                    return item;
                                } else {
                                    return {
                                        ...item,
                                        isChecked: String(item.id) === String(drugManufacturerId),
                                    };
                                }
                            }
                        }),
                    );
                } else {
                    setCheckbox(
                        checkbox?.map((item) => {
                            return {
                                id: item.id,
                                isChecked: String(item.id) === String(drugManufacturerId),
                            };
                        }),
                    );
                }
            }
        } else if (helper !== 0) {
            const name = drugManufacturer.find((drugManufacturer) => drugManufacturer?.id === drugManufacturerId)?.name;
            setTypeModal('');
            openModal(name);
        }
        setHelper(1);
    };

    // UPDATE QUANTITY BY MIN/MAX REQUIRED
    const updateQuantityRequired = (id: number, quantity: number) => {
        const minCantRequired = productCant(id)?.mincant;
        const maxCantRequired = productCant(id)?.maxcant;
        if (minCantRequired && maxCantRequired) {
            if (quantity < minCantRequired) {
                return minCantRequired;
            } else if (quantity > maxCantRequired) {
                return maxCantRequired;
            } else {
                return quantity;
            }
        } else if (minCantRequired && !maxCantRequired) {
            if (quantity < minCantRequired) {
                return minCantRequired;
            } else {
                return quantity;
            }
        } else if (maxCantRequired && !minCantRequired) {
            if (quantity > maxCantRequired) {
                return maxCantRequired;
            } else {
                return quantity;
            }
        }
    };

    const executeForceSuggested = async (drugManufacturerId) => {
        let { data } = await updateProductGroupWithForceSuggested(router.query.orderId, {
            barcode: productGroup.barcode,
            quantity: +productGroup?.initial_quantity,
            force_suggested: drugManufacturerId,
        });
        setProductGroup(data?.data);
        if (!data?.data?.is_split) {
            return setCheckbox(
                checkbox?.map((item) => {
                    return {
                        ...item,
                        isChecked: String(item.id) === String(data?.data?.suggested[0]),
                    };
                }),
            );
        } else {
            return setCheckbox(
                checkbox?.map((item) => {
                    return {
                        ...item,
                        isChecked: data?.data?.suggested?.includes(item.id),
                    };
                }),
            );
        }
    };

    const handleOnCheck = async (drugManufacturerId: number) => {
        // if (+drugManufacturerId === DrugManufacturersId.CentralDeOfertas && isManualChecked) {
        //     setCheckedCdO(true);
        // } else {
        //     setCheckedCdO(false);
        // }

        if (EX4329 && !isManualChecked) setIsManualChecked(true);

        if (
            !!isTheLastColumn(+drugManufacturerId) &&
            !!EX4562 &&
            user?.EX4562 &&
            drugManufacturers().at(-1)?.cdo_status_id === StatusCdo.DISABLED
        ) {
            openModalCdo();
        }
        setIsManualSelected(true);
        updateCheckbox(drugManufacturerId);
        const productWithMinMaxRequired = productCant(drugManufacturerId);
        const hasMinMaxValidation = productWithMinMaxRequired?.mincant || productWithMinMaxRequired?.maxcant;
        const isSuggestedProduct = IdIncludeInSuggested(drugManufacturerId);
        const isSplitProduct = !!productGroup?.allow_split;

        // PRIMERO DEBE CORRER VALIDACIÓN DE MIN && MAX
        if (hasMinMaxValidation) {
            const validatedQuantity = updateQuantityRequired(drugManufacturerId, productGroup?.quantity);
            if (validatedQuantity !== productGroup?.quantity) {
                setIsFetchingProductGroups(true);
                if (EX6753) {
                    await executeForceSuggested(+drugManufacturerId);
                } else {
                    await handleCheckboxOnChange(validatedQuantity);
                }
            } else {
                setIsFetchingProductGroups(false);
            }
        } else if (EX5937 && !isSuggestedProduct && isSplitProduct) {
            handleSplitClick();
        } else {
            //Si al hacer click sobre un suggested tiene que volver a splitear
            // execute request
            if (EX5937) {
                await executeForceSuggested(+drugManufacturerId);
            }
        }
    };

    const isChecked = useCallback(
        (drugManufacturerId) => checkbox?.find(({ id }) => id === drugManufacturerId)?.isChecked,
        [checkbox],
    );
    /* #endregion */

    /* #region GET ID BY DRUGMANUFACTURER SELECTED */
    const drugManufacturerIdSelected: number | any[number] = useCallback(() => {
        if (EX5937) {
            const drugManufacturerIdsSelected = checkbox?.filter(({ isChecked }) => isChecked);
            return drugManufacturerIdsSelected.map((v) => v.id);
        } else {
            return checkbox?.find(({ isChecked }) => isChecked)?.id;
        }
    }, [checkbox]);
    /* #endregion */

    const uncheckAllProducts = useCallback(() => checkbox?.forEach((check) => (check.isChecked = false)), [checkbox]);

    const areAllItemChecked = useCallback(() => {
        const itemsChecked = checkbox?.filter(({ isChecked }) => isChecked);
        const getDMVendor = drugManufacturer?.find((dm) => dm.type_id === EDrugManufacturerTypeId.vendor);
        const key = productGroup?.lowest_without_cdo;

        const drugLowest = drugManufacturer?.find((d) => (!!key ? d?.id === key[0] : false));
        const isVendorActive = !!drugLowest?.vendor_accepted;
        const drugVendor = drugLowest?.type_id === EDrugManufacturerTypeId.vendor;

        if (enabledOnOff) {
            const hasAnyChecked = itemsChecked?.length === 0 && enabledOnOff;

            if (hasAnyChecked && key?.length > 1) {
                return true;
            }

            if (hasAnyChecked) {
                return false;
            }

            return itemsChecked?.length === 0;
        } else {
            if (itemsChecked?.length === 0 && key?.length > 1) {
                return true;
            } else if (itemsChecked?.length === 0 && key?.length === 0 && currentStatusCdO !== StatusCdo.ENABLED) {
                if (EX5937) {
                    const values = drugManufacturerIdSelected();
                    for (let i = 0; i < values?.length; i++) {
                        if (getDMVendor?.id !== i) {
                            return false;
                        }
                        if (!!productCant(i)) {
                            return true;
                        }
                    }
                    return false;
                } else {
                    if (getDMVendor?.id !== drugManufacturerIdSelected()) {
                        return false;
                    }
                    if (!!productCant(drugManufacturerIdSelected())) {
                        return true;
                    }
                    return false;
                }
            }

            if (drugVendor && isVendorActive) {
                return itemsChecked?.length === 0;
            } else {
                return false;
            }
            
        }
    }, [
        checkbox,
        enabledOnOff,
        // products,
    ]);

    const productCant = useCallback(
        (id) => {
            if (productGroup?.products) {
                const product: any = productGroup?.products[id];
                return {
                    maxcant: product?.maxcant,
                    mincant: product?.mincant,
                };
            }
        },
        [productGroup],
    );

    const isTheLastColumn = (drugManufacturerId) =>
        EX4503 && drugManufacturers()?.length ? drugManufacturers().at(-1)?.id === drugManufacturerId : false;

    const DEPENDENCY_DRUG_WITH_FF = EX5937 ? drugManufacturerIdSelected()?.length : drugManufacturerIdSelected();

    /* #region AMOUNT SELECTED */
    const amountSelected = useCallback(() => {
        const id = drugManufacturerIdSelected();
        if (EX5937) {
            if (id?.length === 0) {
                return 0;
            }
            const values = id?.map((i) => {
                return totalPrice(i) || 0;
            });
            if (values?.length) {
                return values?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            }
        } else {
            if (!id) {
                return 0;
            }
            return totalPrice(id);
        }
    }, [DEPENDENCY_DRUG_WITH_FF, productGroup]);
    /* #endregion */

    /* #region IS LOWEST */
    const lowest = useCallback(
        (id) => {
            // if id is equal CDO_ID
            if (id === DrugManufacturersId.CentralDeOfertas) {
                // if the point of sale has cdo enabled
                if (productGroup && productGroup?.lowest?.includes(id)) {
                    // BACKGROUNDCOLOR GREEN
                    return true;
                }
            } else {
                // if the point of sale does not have cdo enabled
                if (pointOfSale()?.cdo_status_id !== OfferCenters.Enabled) {
                    if (productGroup && productGroup?.lowest_without_cdo?.includes(id)) {
                        // BACKGROUNDCOLOR GREEN
                        return true;
                    }
                } else {
                    // if the point of sale has cdo enabled
                    if (productGroup && productGroup?.lowest?.includes(id)) {
                        // BACKGROUNDCOLOR GREEN
                        return true;
                    }
                }
            }
        },
        [productGroup],
    );
    /* #endregion */

    /* #region IS SUGGESTED */
    const isSuggested = useCallback(
        (id) => {
            return productGroup && IdIncludeInSuggested(id);
        },
        [productGroup],
    );
    /* #endregion */

    const setGroupControl = useCallback(() => {
        if (
            !productGroup ||
            (productGroup?.amountSelected === amountSelected() && drugManufacturerIdSelectedWithFF())
        ) {
            return null;
        }
        if (productGroup?.generics?.length) {
            const getChildrenGenericWithQuantities = productGroup?.generics?.filter((generic) => {
                if (generic.quantity > 0) {
                    return { ...generic };
                }
            });
            setChildrenGenerics(getChildrenGenericWithQuantities);
        }
        setProductGroup((prevProps) => {
            const productData = {
                ...prevProps,
                drugManufacturerIdSelected: drugManufacturerIdSelected(),
                areAllItemChecked: areAllItemChecked(),
                amountSelected: amountSelected(),
                isManualSelected: isManualSelected,
                isFatherGeneric: productGroup?.generics?.length > 0,
            };

            if (EX5937) {
                let bonusIsApplied = [];
                productData.drugManufacturerIdSelected?.forEach((drugManufacturerId) => {
                    bonusIsApplied = [
                        ...bonusIsApplied,
                        ...checkBonus(bonus(drugManufacturerId), productData.quantity),
                    ];
                });
                if (bonusIsApplied) {
                    setIsAppliedIndex(bonusIsApplied);
                }
            } else {
                const bonusIsApplied = checkBonus(bonus(productData.drugManufacturerIdSelected), productData.quantity);

                if (bonusIsApplied) {
                    setIsAppliedIndex(bonusIsApplied);
                }
            }
            // // Crear y despachar el evento
            // const customEvent = new CustomEvent('update-product', {
            //     detail: productData,
            // });
            // window.dispatchEvent(customEvent);
            setProductInfo(productData);
            return productData;
        });
    }, [productGroup, drugManufacturerIdSelected(), amountSelected()]);

    /* #region LISTENER VERY IMPORTANT BY SET PRODUCT GROUP IN ROW AND CONTEXT */
    useEffect(() => {
        if (EX4927) {
            if (item !== 0) {
                //item is zero when first load before productsGroups complete 200
                setGroupControl();
            }
        } else {
            setGroupControl();
        }
    }, [productGroup, amountSelected(), drugManufacturerIdSelected()]);
    /* #endregion */

    /* #region LISTENER WHEN SELECT ALL COLUMN */
    useEffect(() => {
        // new validation when select all with FF
        if (EX6209) {
            if (selectAllState && productGroup) {
                const drugLowest = drugManufacturer?.find((d) => d?.id === productGroup.lowest[0]);
                const isVendorActive = !!drugLowest?.vendor_accepted;
                const drugVendor = drugLowest?.type_id === EDrugManufacturerTypeId.vendor;

                if (productGroup?.quantity !== 0) {
                    if (EX6785) {
                        if (drugVendor && productGroup?.products[selectAllState] === null) {
                            if (isVendorActive) {
                                const currentProductSelected =
                                    productGroup?.products[selectAllState] !== null
                                        ? selectAllState
                                        : productGroup.lowest[0];
                                setSelectAllStatePrevious(currentProductSelected);
                                // chequear toda la columna si no hay selección manual en la row
                                if (!isManualSelected) {
                                    updateCheckbox(currentProductSelected);
                                } else {
                                    // si hay selección manual en la row validamos si el selectAll es en otra columna
                                    // para eso comparamos el currentProductSelected contra el selectAllStatePrevious
                                    if (currentProductSelected !== selectAllStatePrevious) {
                                        updateCheckbox(currentProductSelected);
                                    }
                                }
                            }
                        } else {
                            const currentProductSelected =
                                productGroup?.products[selectAllState] !== null
                                    ? selectAllState
                                    : productGroup.lowest[0];
                            setSelectAllStatePrevious(currentProductSelected);
                            // chequear toda la columna si no hay selección manual en la row
                            if (!isManualSelected) {
                                updateCheckbox(currentProductSelected);
                            } else {
                                // si hay selección manual en la row validamos si el selectAll es en otra columna
                                // para eso comparamos el currentProductSelected contra el selectAllStatePrevious
                                if (currentProductSelected !== selectAllStatePrevious) {
                                    updateCheckbox(currentProductSelected);
                                }
                            }
                        }
                    } else {
                        const currentProductSelected =
                            productGroup?.products[selectAllState] !== null ? selectAllState : productGroup.lowest[0];
                        setSelectAllStatePrevious(currentProductSelected);
                        // chequear toda la columna si no hay selección manual en la row
                        if (!isManualSelected) {
                            updateCheckbox(currentProductSelected);
                        } else {
                            // si hay selección manual en la row validamos si el selectAll es en otra columna
                            // para eso comparamos el currentProductSelected contra el selectAllStatePrevious
                            if (currentProductSelected !== selectAllStatePrevious) {
                                updateCheckbox(currentProductSelected);
                            }
                        }
                    }
                }
            }
        } else {
            if (selectAllState && productGroup) {
                if (productGroup?.quantity !== 0) {
                    const currentProductSelected =
                        productGroup?.products[selectAllState] !== null ? selectAllState : productGroup.lowest[0];
                    updateCheckbox(currentProductSelected);
                }
            }
        }
    }, [
        selectAllState,
        productGroup,
        // products,
    ]);
    /* #endregion */

    /* #region PAYMENT DUE */
    const paymentDue = (drugManufacturerId: number) => {
        return (
            productGroup &&
            productGroup?.products[drugManufacturerId] &&
            productGroup?.products[drugManufacturerId]?.payment_due
        );
    };
    /* #endregion */

    /* #region PRIORITY SERVICE */
    const isBestByToleranceInPriorityService = useCallback((id) => productGroup?.bestByTolerance === id, [
        productGroup,
    ]);

    const productGroupHasServiceByPriority = useCallback(
        () => Boolean(productGroup?.service?.id === ServiceId.ByPriority),
        [productGroup],
    );
    /* #endregion */

    /* #region PERCENTAGE SERVICE */
    const applyToleranceInDistribution = useCallback(
        (id) => {
            if (productGroupHasServiceByPercentage() && productGroup.d_tolerance > 0) {
                // HAS TOLERANCE BY PERCENTAGE BACKGROUND COLOR YELLOW ONLY DRUGMANUFACTURER
                return productGroup.in_distribution.includes(id);
            }
        },
        [productGroup],
    );

    const productGroupHasServiceByPercentage = useCallback(
        () => Boolean(productGroup?.service?.id === ServiceId.ByPercentage),
        [productGroup],
    );
    /* #endregion */

    const withStockAllDrugManufacturers = useCallback(
        (): boolean => isEmptyObjectAtrribute(productGroup?.products || []),
        [productGroup],
    );

    const handleSplitClick = async () => {
        const request = {
            barcode: productGroup?.barcode,
            allow_split: !productGroup?.allowed_split,
        };
        const splitResponse = await updateProductGroupBySplit(router.query.orderId, request);
        if (splitResponse?.status === 200) {
            setProductGroup(splitResponse?.data?.data);
            // add updatecheckbox when split is on and off
            if (!splitResponse?.data?.data?.is_split && EX5937) {
                return setCheckbox(
                    checkbox?.map((item) => {
                        return {
                            ...item,
                            isChecked: String(item.id) === String(splitResponse?.data?.data?.suggested[0]),
                        };
                    }),
                );
            } else {
                return setCheckbox(
                    checkbox?.map((item) => {
                        return {
                            ...item,
                            isChecked: splitResponse?.data?.data?.suggested?.includes(item.id),
                        };
                    }),
                );
            }
        }
    };

    const gridRef = useRef(null);

    /* #region isLowestSuggested  */
    //Esto es para chequear si las droguerias seleccionadas son los precios bajos y estan en los sugeridos
    const isLowestSuggested = useCallback(() => {
        if (productGroup && productGroup.suggested) {
            const isSuggestedSelected = drugManufacturerIdSelected().every(
                (dmSelected) =>
                    productGroup?.suggested?.includes(dmSelected) &&
                    productGroup?.lowest_without_cdo?.includes(dmSelected),
            );

            if (isSuggestedSelected) {
                return true;
            }
            return false;
        }

        return false;
    }, [productGroup]);
    /* #endregion */

    return {
        handleCheckboxOnChange,
        productGroup,
        hasDrugManufacturerError,
        gridRef,
        withStockAllDrugManufacturers,
        isManualSelected,
        setIsManualSelected,
        bonus,
        isAppliedIndex,
        multiplierPerAppliedPromo,
        unitPrice,
        lastUnitPrice,
        productsIds,
        applyToleranceInDistribution,
        productGroupHasServiceByPriority,
        isBestByToleranceInPriorityService,
        isChecked,
        handleOnCheck,
        drugManufacturerIdSelected,
        areAllItemChecked,
        lowest,
        isSuggested,
        paymentDue,
        isFetchingProductGroups,
        updateQuantityRequired,
        uncheckAllProducts,
        childrenGenerics,
        setChildrenGenerics,
        handleSplitClick,
        productCant,
        drugManufacturer,
        isEnabledDrugManufacturer,
        setShowChildren: () => setShowGeneric(!showGeneric),
        showChildren: showGeneric,
        loading,
        generics,
        isGenerics,
        CDO_DRUGMANUFACTURER,
        isZetti,
        isLowestSuggested,
        genericsIdsAdd,
        setGenericsIdsAdd
    };
};
