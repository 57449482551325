// react
import { useEffect, useMemo, useRef, useState } from 'react';
// next
import { useRouter } from 'next/router';
// component
import { ProgressBarPriceModule } from '@commons/modules';
import { Header, NewHeader, Sidebar } from '.';
import { ModalDontHaveCredentials } from '../Modals';
import { ModalTerms } from '../Modals/ModalT&C';
import { OnBoarding } from '../OnBoarding';
import { ModalOnboarding } from '../OnBoarding/ModalOnboarding';
import FooterRsuite from './Footer/Footer';
// context
import {
    NewUseCart,
    NewUseSnackbar,
    useCreateOrder,
    useLayoutFooterContext,
    useModalDontHaveCredentials,
    useNewSearchProduct,
    usePointOfSale,
    useProgressBarPriceContext,
    useRecommendedProducts,
} from 'context';
import { useStatusContext } from 'context/control-state';
import { useDragAndDrop } from 'context/dragAndDropContext';
// style
import style from './Layouts.module.css';
// rsuite v5
import { Container, Content } from 'rsuite';
import { RsuiteNotification } from '../RsuiteNotification';
// flags
import { EX3991, EX4204, EX4467, EX4780, EX5539 } from 'config/flags';
// trasnalte
// utils
import NewModalCredentials from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCardConnectionV3/NewModalCredentials/NewModalCredentials';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { typeAlerts } from 'utils/enums/typeAlert/typeAlert';
import { useModal, useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import useHasHydrated from 'utils/hooks/useHydratation';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
import { AlertBannerNotification } from '../AlertBannerNotification';
import { ModalPrivacyPolicy } from '../Modals/ModalPrivacyPolicy';
import { hiddenCart } from './utils/hiddenCart';
import useExpandCollapse from 'utils/hooks/useExpandCollapse';
import { t } from 'i18next';
import NewCredentialCardConnectionV4 from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCardConnectionV3/NewCredentialCardConnectionV4';

const Layouts = ({
    children,
    orders = null,
    delivery = null,
    pointOfSales = null,
    drugManufacturers = null,
    orderDelivery = null,
    detailsOrders = null,
    order = null,
    dely = null,
    agree = null,
    tableAgree = null,
    displayFooter = null,
    disableScroll = null,
}) => {
    const { user } = useUser({});
    const EX4904 = user?.EX4904;
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX3991) useChangeLanguage(userLocaleCountryCode);
    const { pointOfSale } = usePointOfSale();
    const EX5943 = user?.EX5943; //epic rediseño home

    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);
    //useDragAndDrop
    const { setVisibleDragDrop, handlerDragEnter, visibleDragDrop } = useDragAndDrop();
    // next
    const router = useRouter();
    // context snackbar
    const { showSnackbar, handlePress } = NewUseSnackbar();
    // context create order
    const { showLoadingScreen, hideBreadcrum, fileResults, setShowLoadingScreen, setErrors } = useCreateOrder();
    // context new cart
    const { handleSetterVisibility, visibility } = NewUseCart();
    //context onboarding
    const { displayModalOnBoarding, isTourOpen, counterStep } = useStatusContext();
    // context search
    const { isOpen, handleOpen, searchData } = useNewSearchProduct();
    //context start E.P
    const { dontHaveCredentialsModal, setDontHaveCredentialsModal } = useModalDontHaveCredentials();
    //context recommended products
    const { setReFetching, pagination, recommendedProducts } = useRecommendedProducts();
    // NEW CONTEXT VALUES PROGRESS BAR PRICES
    const { hasErrors } = useProgressBarPriceContext();
    // context modal credentials
    const { toggleCard } = useExpandCollapse();
    const { showPreventAlert, toggleModal } = useModalCredentialsContext();

    // alert plataform
    const PUSHERDOWN = user?.PUSHERDOWN;

    //cookie
    let checkOnboarding = false;
    let firstLogin = false;
    if (typeof window !== 'undefined') {
        checkOnboarding = localStorage.getItem('checkOnboarding') !== 'true';
        firstLogin = localStorage.getItem('firstLogin') === 'true';
    }
    const [pdv, setPdv] = useState(null);
    const [removePadding, setRemovePadding] = useState(false);
    // states footer
    const [expandedFooter, setExpandedFooter] = useState<boolean>(false);
    const [openModalTerms, setOpenModalTerms] = useState<boolean>(false);
    const [openModalPrivacyPolicy, setOpenModalPrivacyPolicy] = useState<boolean>(false);
    const [linkActive, setLinkActive] = useState<any>({
        value: '',
        state: false,
    });
    // states pagination infinite scroll
    const contentRef: any = useRef(null);
    const [isEndOfPage, setIsEndOfPage] = useState(false);
    const isHydratated = useHasHydrated();
    const { setFooterExpanded, setShowFooterContext } = useLayoutFooterContext();

    const isHomeView = ['/[clientId]/efficient-purchase', '/[clientId]/efficient-purchase/[posId]'].includes(
        router.pathname,
    ) && !showLoadingScreen; //rediseño home modulo de droguerias

    useEffect(() => {
        if (isHomeView) {
            setFooterExpanded(expandedFooter);
        }
    }, [expandedFooter]);

    const handleClick = () => {
        // Agrega un evento click al documento
        if (hiddenCart(router.pathname)) {
            // Validación para chequear si no es la home
            handleSetterVisibility();
        }

        if (expandedFooter) setExpandedFooter(false);
    };

    const handleClickBackDrop = () => {
        if (EX4467 && hiddenCart(router.pathname)) {
            // Validación para chequear si no es la home
            handleSetterVisibility();
        }
        if (router.pathname.split('/').at(-1) === 'search-results') {
            handleOpen(false);
        }
    };

    // set cookie onboarding
    useEffect(() => {
        const pdv = localStorage.getItem('pdvSelected') === null;
        setPdv(pdv);
        if (
            (!!pdv || router?.pathname === '/[clientId]/efficient-purchase') &&
            router?.pathname !== `/[clientId]/efficient-purchase/[posId]`
        ) {
            localStorage.setItem('firstLogin', 'true');
        } else {
            localStorage.setItem('firstLogin', 'false');
        }
    }, [pdv, router]);

    const [isNewDesign, setIsNewDesign] = useState(false);

    useEffect(() => {
        if (EX5539) {
            if (
                router?.pathname !== `/[clientId]/efficient-purchase/[posId]` &&
                router?.pathname !== '/[clientId]/efficient-purchase' &&
                !isZetti &&
                EX5943 &&
                !isGestorERP
            ) {
                setVisibleDragDrop(false);
            }

            if (router?.pathname === `/[clientId]/settings/POS/[posId]/posDetails`) {
                setIsNewDesign(true);
            } else {
                setIsNewDesign(false);
            }
        } else {
            if (
                router?.pathname !== `/[clientId]/efficient-purchase/[posId]` &&
                router?.pathname !== '/[clientId]/efficient-purchase' &&
                !isZetti &&
                EX5943
            ) {
                setVisibleDragDrop(false);
            }

            if (router?.pathname === `/[clientId]/settings/POS/[posId]/posDetails`) {
                setIsNewDesign(true);
            } else {
                setIsNewDesign(false);
            }
        }
    }, [router]);

    const hiddenHeader = (): boolean => {
        if (!showLoadingScreen) return true;
        return false;
    };

    const validateBackgroundWhite =
        router?.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
        (!EX4904 && router?.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation') ||
        router?.pathname === '/[clientId]/settings/POS' ||
        router?.pathname === '/[clientId]/settings/trade-agreements' ||
        router?.pathname === '/[clientId]/settings/POS/[posId]/posDetails';

    const validateHiddenFooter = (): boolean => {
        return router?.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison';
    };

    const RemovePadding = () => {
        setRemovePadding(router?.pathname === `/[clientId]/my-orders`);
    };

    // useEffect infinite scroll
    useEffect(() => {
        if (EX5539) {
            if (EX4204 && !isZetti && !isGestorERP) {
                const handleScroll = () => {
                    const contentElement = contentRef.current;
                    const scrollPosition = contentElement.scrollTop + contentElement.clientHeight;
                    const scrollableHeight = contentElement.scrollHeight;
                    const offset = 140; //esto le resta 140px al alto para hacer el fetching antes de llegar al final

                    if (!visibleDragDrop && scrollPosition >= scrollableHeight - offset) {
                        // Se ha alcanzado el final de la página
                        setIsEndOfPage(true);
                    } else {
                        setIsEndOfPage(false);
                    }
                };

                // Agregar el controlador de eventos de desplazamiento al elemento
                const contentElement = contentRef.current;
                if (contentElement) {
                    contentElement.addEventListener('scroll', handleScroll);
                }

                return () => {
                    // Limpiar el controlador de eventos cuando el componente se desmonta
                    if (contentElement) {
                        contentElement.removeEventListener('scroll', handleScroll);
                    }
                };
            }
        } else {
            if (EX4204 && !isZetti) {
                const handleScroll = () => {
                    const contentElement = contentRef.current;
                    const scrollPosition = contentElement.scrollTop + contentElement.clientHeight;
                    const scrollableHeight = contentElement.scrollHeight;
                    const offset = 140; //esto le resta 140px al alto para hacer el fetching antes de llegar al final

                    if (!visibleDragDrop && scrollPosition >= scrollableHeight - offset) {
                        // Se ha alcanzado el final de la página
                        setIsEndOfPage(true);
                    } else {
                        setIsEndOfPage(false);
                    }
                };

                // Agregar el controlador de eventos de desplazamiento al elemento
                const contentElement = contentRef.current;
                if (contentElement) {
                    contentElement.addEventListener('scroll', handleScroll);
                }

                return () => {
                    // Limpiar el controlador de eventos cuando el componente se desmonta
                    if (contentElement) {
                        contentElement.removeEventListener('scroll', handleScroll);
                    }
                };
            }
        }
    }, []);

    const isValidationFooterHiddenOrNot = () => {
        const currentPath = router?.pathname;

        return (
            currentPath === '/[clientId]/efficient-purchase/[posId]' ||
            currentPath === '/[clientId]/efficient-purchase/[posId]/search-results' ||
            currentPath === '/[clientId]/efficient-purchase/[posId]/confirmation' ||
            currentPath === `/[clientId]/settings/POS` ||
            currentPath === `/[clientId]/my-orders` ||
            currentPath === '/[clientId]/efficient-purchase/[posId]/order-sent' ||
            currentPath === '/[clientId]/settings/trade-agreements/create' ||
            currentPath === `/[clientId]/settings/trade-agreements`
        );
    };

    const checkForScroll = () => {
        if (EX4204) {
            const contentElement = contentRef?.current;

            if (contentElement?.scrollHeight > contentElement?.clientHeight) {
                // Hay scroll en la página
                return true;
            } else {
                // No hay scroll en la página
                return false;
            }
        }
    };

    const isValidate =
        // recommendedProducts?.length > 6 &&
        (router?.pathname === '/[clientId]/efficient-purchase/[posId]' && checkForScroll()) ||
        (searchData?.length >= 20 && router.pathname === '/[clientId]/efficient-purchase/[posId]/search-results') ||
        (pointOfSales?.length >= 10 && router.pathname === `/[clientId]/settings/POS`) ||
        (router.pathname === `/[clientId]/settings/trade-agreements` && tableAgree?.length > 7) ||
        (drugManufacturers?.data?.length >= 20 && router.pathname === `/[clientId]/settings/POS/[posId]/posDetails`) ||
        (router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' &&
            orderDelivery?.length > 0 &&
            checkForScroll()) ||
        (router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' &&
            orders?.length >= 1 &&
            checkForScroll()) ||
        (router.pathname === '/[clientId]/my-orders' && order?.length >= 10) ||
        (router.pathname === '/[clientId]/my-orders' && dely?.length >= 10) ||
        (router.pathname === '/[clientId]/settings/trade-agreements/create' && !!agree && !validateHiddenFooter());

    const isValidateNegative =
        // recommendedProducts?.length < 6 ||
        (router?.pathname === '/[clientId]/efficient-purchase/[posId]' && !checkForScroll()) ||
        (searchData?.length < 20 && router.pathname === '/[clientId]/efficient-purchase/[posId]/search-results') ||
        (pointOfSales?.length < 10 && router.pathname === `/[clientId]/settings/POS`) ||
        (drugManufacturers?.data?.length < 10 && router.pathname === `/[clientId]/settings/POS/[posId]/posDetails`) ||
        (router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' && orderDelivery?.length === 0) ||
        !checkForScroll() ||
        (router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' && orders?.length < 1) ||
        !checkForScroll() ||
        (router.pathname === '/[clientId]/my-orders/details/[order]' &&
            detailsOrders?.order_deliveries?.manual_delivery.length < 1) ||
        detailsOrders?.order_deliveries?.automatic_delivery.length < 1 ||
        (router.pathname === '/[clientId]/my-orders' && order?.length < 10) ||
        (router.pathname === '/[clientId]/my-orders' && dely?.lenght < 10) ||
        router.pathname === '/[clientId]/my-orders/deliveries/[delivery]/[order]' ||
        (router.pathname === '/[clientId]/settings/trade-agreements/create' && !agree) ||
        (router.pathname === `/[clientId]/settings/trade-agreements` &&
            tableAgree?.length < 2 &&
            !validateHiddenFooter());
    const handleFirstLogin =
        router.pathname !== '/[clientId]/efficient-purchase/[posId]/' &&
        router.pathname !== '/[clientId]/efficient-purchase';
    useEffect(() => {
        if (!!isEndOfPage && EX4204) {
            // add validations by pathname
            if (
                isValidationFooterHiddenOrNot() &&
                pagination?.pagination &&
                pagination?.pagination?.links?.next &&
                !!pagination?.pagination?.links?.next
            ) {
                setReFetching(true);
            } else {
                setIsEndOfPage(false);
            }
        }
    }, [isEndOfPage]);

    const steps = ['#step-1', '#step-2', 'body', '#step-7'];
    const controlStep = steps.find((element) => counterStep === element);

    useEffect(() => {
        RemovePadding();
    }, [router]);

    const validationBackdrop = (): boolean => {
        // solo en vista ordenes y config corren estas validaciones
        if (EX4467 && hiddenCart(router?.pathname)) {
            if (visibility && EX4467) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    // state by footer and transition fade
    const [showFooter, setShowFooter] = useState(true);
    const [transitionComplete, setTransitionComplete] = useState(false);

    // handle check scroll in content
    const hasVerticalScrollbar = () => {
        if (EX4780) {
            const contentElement = contentRef?.current;
            if (contentElement) {
                const hasVerticalScrollbar =
                    isHomeView
                        ? contentElement.scrollHeight - 56 > contentElement.clientHeight
                        : contentElement.scrollHeight > contentElement.clientHeight;

                if (hasVerticalScrollbar) {
                    // the content have scroll
                    return true;
                } else if (contentElement.scrollHeight === contentElement.clientHeight) {
                    // The content doesn't have scroll because they are equal in height
                    return false;
                }
            }
        }
    };

    // handle setter show footer and transition fade
    const handleChangeShowFooter = (value: boolean = true) => {
        if (EX4780) {
            if (!transitionComplete) setTransitionComplete(true); // desactivate transition footer fade
            setShowFooter(value);
        }
    };

    // dependencies that locate the footer or relocate
    const DEPENDENCIES_FOOTER_IN_VIEW =
        typeof window !== 'undefined' && isHomeView
            ? [window?.innerWidth, window?.innerHeight]
            : contentRef
            ? [contentRef?.current?.scrollHeight, contentRef?.current?.clientHeight, recommendedProducts]
            : [];

    // initial loading of the view
    useEffect(() => {
        if (EX4780) {
            if (!hasVerticalScrollbar()) {
                handleChangeShowFooter(true);
            } else {
                // the view doesn't scroll
                handleChangeShowFooter(false);
            }
        }
    }, DEPENDENCIES_FOOTER_IN_VIEW);

    // dependencies that locate the footer or relocate according to the resize
    const DEPENDENCIES_LISTENER_RESIZE = typeof window !== 'undefined' ? [window?.innerWidth, window?.innerHeight] : [];

    // listen to resize in views to set footer
    useEffect(() => {
        if (EX4780) {
            const handleResize = () => {
                if (!hasVerticalScrollbar()) {
                    handleChangeShowFooter(true);
                } else {
                    const contentElement = contentRef.current;
                    const scrollPosition = contentElement.scrollTop + contentElement.clientHeight;
                    const scrollableHeight = contentElement.scrollHeight;

                    // the scroll is below
                    if (scrollPosition !== scrollableHeight) {
                        handleChangeShowFooter(false);
                    }
                }
            };

            if (typeof window !== 'undefined') {
                // Add the resize event handler to the element
                window?.addEventListener('resize', handleResize);

                return () => {
                    // Clear event handler when component is unmounted
                    window?.removeEventListener('resize', handleResize);
                };
            }
        }
    }, DEPENDENCIES_LISTENER_RESIZE);

    // listen to scroll in views to set footer
    useEffect(() => {
        if (EX4780) {
            const handleScroll = () => {
                const contentElement = contentRef.current;
                const scrollPosition = contentElement.scrollTop + contentElement.clientHeight;
                const scrollableHeight = contentElement.scrollHeight;
                const footerHeight = 46;

                if (scrollPosition + footerHeight >= scrollableHeight) {
                    handleChangeShowFooter(true);
                } else {
                    if (!hasVerticalScrollbar()) {
                        handleChangeShowFooter(true);
                    } else {
                        // the view doesn't scroll
                        handleChangeShowFooter(false);
                    }
                }
            };

            // Add the scroll event handler to the element
            const contentElement = contentRef.current;
            if (contentElement) {
                contentElement.addEventListener('scroll', handleScroll);
            }

            return () => {
                // Clear event handler when component is unmounted
                if (contentElement) {
                    contentElement.removeEventListener('scroll', handleScroll);
                }
            };
        }
    }, []);

    const AlertPlatform = () => {
        if (PUSHERDOWN) {
            return (
                <div
                    style={{
                        background: '#FFF9E6',
                        width: '100%',
                        border: '1px solid #FFEA9F',
                        boxShadow: '0px 10px 15px -3px rgba(64, 67, 91, 0.10), 0px 4px 6px -2px rgba(64, 67, 91, 0.05)',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 12,
                        padding: '16px 20px',
                        height: 70,
                    }}
                >
                    <img src="/assets/images/warning_FILL.svg" alt="warning icon" width={24} height={24} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}
                    >
                        <p style={{ fontSize: 16, fontWeight: 400, margin: 0, color: '#40435B' }}>
                            ¡Actualización de la plataforma en progreso!
                        </p>
                        <p style={{ fontSize: 14, fontWeight: 400, margin: 0, color: '#6B6F93' }}>
                            Gracias por tu paciencia mientras llevamos a cabo estas tareas.
                        </p>
                    </div>
                </div>
            );
        }
    };

    const handleKeyDown = (event) => {
        const key = event?.key || event?.keyCode;
        // 27 is the keyCode for the Escape key
        if (key === 'Escape' || key === 27) {
            setVisibleDragDrop(false);
            setErrors(false);
        }
    };

    const handleDragLeave = () => {
        setVisibleDragDrop(false);
        setErrors(false);
    };

    useEffect(() => {
        if (!EX5943 && router?.pathname === '/[clientId]/efficient-purchase/[posId]') {
            const handleDragLeaveWindow = (event) => {
                // check if the dragleave event actually exited the window
                if (
                    event.clientX <= 0 ||
                    event.clientY <= 0 ||
                    event.clientX >= window.innerWidth ||
                    event.clientY >= window.innerHeight
                ) {
                    handleDragLeave();
                }
            };

            // Add event listener for keydown and dragleave
            window?.addEventListener('keydown', handleKeyDown);
            window?.addEventListener('dragleave', handleDragLeaveWindow);

            // Remove event listeners when unmounting the component
            return () => {
                window?.removeEventListener('keydown', handleKeyDown);
                window?.removeEventListener('dragleave', handleDragLeaveWindow);
            };
        }
    }, [user]);

    const alerts: any = Object.values(user?.client?.alerts ?? {});
    const [item] = alerts;

    const hasAlert =
        router.pathname === '/[clientId]/efficient-purchase/[posId]' &&
        item?.notification_location.includes(typeAlerts.EfficientPurchase);

    const shouldShowFooter = useMemo(() => {
        return displayFooter !== null ? displayFooter : showFooter && !showLoadingScreen;
    }, [displayFooter, showFooter, showLoadingScreen]);

    useEffect(() => {
        if (isHomeView) {
            setShowFooterContext(shouldShowFooter);
        }
    }, [shouldShowFooter]);

    const hiddenSideBar =
        showLoadingScreen || (router.pathname == '/[clientId]/efficient-purchase/[posId]/confirmation' && !!EX4904);

    const validateERP = EX5539 ? !isZetti && !isGestorERP : !isZetti;

    const isAnalytics = router.pathname === '/analytics'

    return (
        <Container>
            {isHydratated && validateERP ? <OnBoarding /> : <></>}
            {hiddenSideBar ? '' : <Sidebar />}
            <Container className={style['container-content']}>
                {/* Blur Content Page */}
                {!controlStep && <div className={style['wrapper-card-container-fullblur']}></div>}

                {firstLogin && !handleFirstLogin && <div className={style['wrapper-card-container-fullblur']}></div>}
                {/* BACKDROP BACKGROUND */}

                {(isOpen || validationBackdrop()) && (
                    <div
                        onClick={handleClickBackDrop}
                        aria-hidden="true"
                        className="rs-modal-backdrop rs-anim-fade rs-anim-in"
                    ></div>
                )}

                {/* HIDDEN HEADER */}
                {hiddenHeader() && <NewHeader orders={orders} delivery={delivery} />}

                {/* alert platform */}
                {(router?.pathname == '/[clientId]/efficient-purchase/[posId]' ||
                    router?.pathname === '/[clientId]/efficient-purchase') &&
                    PUSHERDOWN && <AlertPlatform />}

                {!!hasAlert && !showLoadingScreen && !!user?.EX4990 && (
                    <AlertBannerNotification
                        type={item?.notification_type == 2 ? 'warning' : 'error'}
                        header={item?.title}
                        subtext={item?.description}
                        closeText={item?.link_text}
                        link_text={item?.link_address}
                    />
                )}

                {/* Modal dont have credentials */}
                <ModalDontHaveCredentials
                    dontHaveCredentialsModal={dontHaveCredentialsModal}
                    setDontHaveCredentialsModal={setDontHaveCredentialsModal}
                    clientId={router.query.clientId}
                    posId={router.query.posId}
                />

                {/* End Blur Content Page */}

                {/* CONTENT */}
                <Content
                    ref={contentRef}
                    id="content-id"
                    className={
                        showLoadingScreen
                            ? `${style['content-progressBarPrice']} ${hasErrors ? style['with-errors'] : ''}`
                            : showLoadingScreen || validateBackgroundWhite
                            ? isNewDesign
                                ? style['content-background-v2']
                                : style['content-background-remove']
                            : removePadding
                            ? style['content-withOutPadding']
                            : counterStep === '#step-1' || counterStep === '#step-2'
                            ? style['content-withoutScroll']
                            : isAnalytics ? style['content-analytics']: style['content']
                    }
                    onDragEnter={handlerDragEnter}
                    onClick={handleClick}
                    style={{
                        overflowY: (visibleDragDrop && !isZetti) || (disableScroll && EX4204) ? 'hidden' : 'scroll',
                        padding: isHomeView ? 0 : '',
                        paddingBottom: EX4780 ? 50 : '',
                        height:
                            isHomeView
                                ? '100vh'
                                : router.pathname == '/[clientId]/efficient-purchase/[posId]/confirmation' && !!EX4904
                                ? '100vh'
                                : '70vh',
                    }}
                >
                    <NewCredentialCardConnectionV4 mutateClientPos={() => {}} toggleCard={toggleCard} />
                    {showLoadingScreen ? (
                        <ProgressBarPriceModule
                            setHideBreadcrum={hideBreadcrum}
                            dataOrderCreated={fileResults}
                            efficientPurchaseFromCart={true}
                            setShowLoadingScreen={() => setShowLoadingScreen(false)}
                        />
                    ) : (
                        children
                    )}

                    {/* Modal notifications */}
                    {showSnackbar && (
                        <RsuiteNotification
                            text={!EX3991 ? 'Producto eliminado' : t('shoppingCart.shoppingCart_Thirteen')}
                            action={'Deshacer'}
                            handlePress={handlePress}
                            gtagClass="link__remove-product-undo"
                        />
                    )}

                    {/* Onboarding flow */}
                    {/* turn off onboarding new header*/}
                    {!EX5943 &&
                    !isTourOpen &&
                    displayModalOnBoarding &&
                    !showLoadingScreen &&
                    validateERP &&
                    checkOnboarding &&
                    pointOfSale ? (
                        <ModalOnboarding />
                    ) : (
                        <></>
                    )}
                    {/* Terms & Cond */}
                    <ModalTerms
                        openModal={openModalTerms}
                        setOpenModal={() => setOpenModalTerms(!openModalTerms)}
                        setLinkActive={setLinkActive}
                        expandedFooter={expandedFooter}
                        userLocaleCountryCode={userLocaleCountryCode}
                    />

                    {/* Privacy Policy */}
                    <ModalPrivacyPolicy
                        openModal={openModalPrivacyPolicy}
                        setOpenModal={() => setOpenModalPrivacyPolicy(!openModalPrivacyPolicy)}
                        setLinkActive={setLinkActive}
                        expandedFooter={expandedFooter}
                    />
                </Content>
                {EX4780 && (
                    <div
                        id="pharma-footer"
                        style={{
                            position: 'fixed',
                            visibility: shouldShowFooter ? 'visible' : 'hidden',
                            // height: shouldShowFooter ? 'auto' : 0,
                            bottom: 0,
                            width:
                                router.pathname == '/[clientId]/efficient-purchase/[posId]/confirmation' && !!EX4904
                                    ? '100%'
                                    : 'calc(100% - 80px)',
                            opacity: 1,
                            //transition: 'opacity 0.5s ease',
                            zIndex: validateERP ? 1 : 1000,
                        }}
                    >
                        {shouldShowFooter && (
                            <FooterRsuite
                                expandedFooter={expandedFooter}
                                setExpandedFooter={() => setExpandedFooter(!expandedFooter)}
                                setOpenModalTerms={() => setOpenModalTerms(true)}
                                setOpenModalPrivacyPolicy={() => setOpenModalPrivacyPolicy(true)}
                                linkActive={linkActive}
                                setLinkActive={setLinkActive}
                            />
                        )}
                    </div>
                )}
            </Container>
        </Container>
    );
};

export default Layouts;
