import React from 'react';
// COMPONENTS
import { Chip } from '../../Chip';
import { PriceSection } from './PriceSection';
// UTILS
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
// STYLES
import Animations from '../Animations.module.css';
import cellStyles from '../../CellStyles.module.css';
import styles from './RecommendedCellPrice.module.css';

const RecommendedCellPrice = ({
    product,
    productPVP,
    fadeOutRecommended,
    recommendationLimit,
    skeleton,
    chipColor,
    cdo_is_visible,
    isLastCell,
    setShowRecommendations,
    drugManufacturerEnabled,
    drugManufacturerName,
    setOpenModalDiscounts,
    hasDrugManufacturerError,
    drugManufacturerId,
    user,
    lastUnitPrice,
    unitPrice,
    isRecommended
}): JSX.Element => {
    return (
        <div
            className={`${isLastCell && !cdo_is_visible ? styles['cell'] : ''} ${
                fadeOutRecommended ? Animations['fade-out'] : ''
            } ${recommendationLimit ? cellStyles['without-border'] : ''} ${styles['cell-container']} `}
            style={{
                borderRight: isLastCell || recommendationLimit ? '' : '0.5px solid #FFCDAD',
            }}
        >
            <div className={`${cellStyles['top']}`}>
                <PriceSection
                    recommendationLimit={recommendationLimit}
                    skeleton={skeleton}
                    drugManufacturerEnabled={drugManufacturerEnabled}
                    hasDrugManufacturerError={hasDrugManufacturerError}
                    product={product}
                    chipColor={chipColor}
                    cdo_is_visible={cdo_is_visible}
                    isLastCell={isLastCell}
                    setShowRecommendations={setShowRecommendations}
                    userLocale={user?.client?.country_alfa_2}
                    lastUnitPrice={lastUnitPrice}
                    unitPrice={unitPrice}
                />
            </div>
            {!skeleton && !!product && !!product?.discount && !recommendationLimit && !hasDrugManufacturerError && (
                <Chip
                    chipColor={!drugManufacturerEnabled && !skeleton ? CHIP_DISCOUNT_COLOR.DISABLED : chipColor}
                    skeleton={skeleton}
                    product={product}
                    productPVP={productPVP}
                    drugManufacturerName={drugManufacturerName}
                    setOpenModalDiscounts={setOpenModalDiscounts}
                    drugManufacturerId={drugManufacturerId}
                    bonus={product?.bonus}
                    checked={false}
                    quantityForBonus={0}
                    isAppliedIndex={null}
                    multiplierPerAppliedPromo={false}
                    user={user}
                    isRecommended={isRecommended}
                />
            )}
        </div>
    );
};

export default RecommendedCellPrice;
