import { useEffect, useState } from 'react';
// contexts
import { NewUseCart, useCreateOrder, useNewSearchProduct, usePointOfSale } from 'context';
import { useStatusContext } from 'context/control-state';
import { useDragAndDrop } from 'context/dragAndDropContext';
// utils
import { EX5539, EX6229, EX6243 } from 'config/flags';
import { useUser } from 'utils/hooks';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';

const useDragDropUploader = () => {
    // Custom hooks
    const {
        handleUpload,
        blockerFormat,
        errors,
        dataWithError,
        isFetching,
        formatFile,
        handlerUploadWithErrors,
        setErrors,
        isLoadingFileWithErrors,
        setBlockerFormat,
        setDataWithError,
    } = useCreateOrder();

    const { quantityCart, visibility, isExpanded, handleResetVisibility } = NewUseCart();

    const { isOpen } = useNewSearchProduct();

    const { handleDragLeave, setVisibleDragDrop, isDragging, visibleDragDrop } = useDragAndDrop();

    const { setDisplayModalOnBoarding, displayModalOnBoarding } = useStatusContext();

    const { pointOfSale } = usePointOfSale();

    const { userLocaleCountryCode, user } = useUser({});

    const EX6708 = user?.EX6708;

    // State
    const [files, setFiles] = useState<any>([]);
    const isZetti: boolean = isZettiFormat(pointOfSale);
    const isGestorERP: boolean = isGestorERPFormat(pointOfSale);

    const ERP_DEPENDENCY = EX5539 ? [isZetti, isGestorERP, user] : [isZetti];

    const handleCloseIcon = (): void => {
        if(!isZetti && !isGestorERP) {
            setVisibleDragDrop(false);
        }
        setErrors(false);
        setBlockerFormat(false);
        if (EX6229) {
            setDataWithError([]);
        }
    };

    const resetErrorsWhenHaveFormatError = () => {
        setDataWithError([]);
        setErrors(false);
    };

    const handleKeyDown = (event): void => {
        const key = event?.key || event?.keyCode;
        // 27 is the keyCode for the Escape key
        if (key === 'Escape' || key === 27) {
            if(!isZetti && !isGestorERP) {
                setVisibleDragDrop(false);
            }
            setErrors(false);
            setBlockerFormat(false);
            setDataWithError([]);
        }
    };

    useEffect(() => {
        if (EX5539) {
            if (EX6708) {
                const handleDragLeaveWindow = (event) => {
                    // check if the dragleave event actually exited the window
                    if (
                        event.clientX <= 0 ||
                        event.clientY <= 0 ||
                        event.clientX >= window.innerWidth ||
                        event.clientY >= window.innerHeight
                    ) {
                        if (EX6243) {
                            handleResetVisibility();
                        }
                        handleDragLeave(event);
                    }
                };

                // Add event listener for keydown and dragleave
                window.addEventListener('keydown', handleKeyDown);
                window.addEventListener('dragleave', handleDragLeaveWindow);

                // Remove event listeners when unmounting the component
                return () => {
                    window.removeEventListener('keydown', handleKeyDown);
                    window.removeEventListener('dragleave', handleDragLeaveWindow);
                };
            } else {
                if (!isZetti && !isGestorERP) {
                    const handleDragLeaveWindow = (event) => {
                        // check if the dragleave event actually exited the window
                        if (
                            event.clientX <= 0 ||
                            event.clientY <= 0 ||
                            event.clientX >= window.innerWidth ||
                            event.clientY >= window.innerHeight
                        ) {
                            if (EX6243) {
                                handleResetVisibility();
                            }
                            handleDragLeave(event);
                        }
                    };

                    // Add event listener for keydown and dragleave
                    window.addEventListener('keydown', handleKeyDown);
                    window.addEventListener('dragleave', handleDragLeaveWindow);

                    // Remove event listeners when unmounting the component
                    return () => {
                        window.removeEventListener('keydown', handleKeyDown);
                        window.removeEventListener('dragleave', handleDragLeaveWindow);
                    };
                }
            }
        } else {
            if (!isZetti) {
                const handleDragLeaveWindow = (event) => {
                    // check if the dragleave event actually exited the window
                    if (
                        event.clientX <= 0 ||
                        event.clientY <= 0 ||
                        event.clientX >= window.innerWidth ||
                        event.clientY >= window.innerHeight
                    ) {
                        if (EX6243) {
                            handleResetVisibility();
                        }
                        handleDragLeave(event);
                    }
                };

                // Add event listener for keydown and dragleave
                window.addEventListener('keydown', handleKeyDown);
                window.addEventListener('dragleave', handleDragLeaveWindow);

                // Remove event listeners when unmounting the component
                return () => {
                    window.removeEventListener('keydown', handleKeyDown);
                    window.removeEventListener('dragleave', handleDragLeaveWindow);
                };
            }
        }
    }, ERP_DEPENDENCY);

    return {
        handleUpload,
        blockerFormat,
        errors,
        dataWithError,
        isFetching,
        formatFile,
        handlerUploadWithErrors,
        setErrors,
        isLoadingFileWithErrors,
        handleDragLeave,
        setVisibleDragDrop,
        visibleDragDrop,
        isDragging,
        setDisplayModalOnBoarding,
        displayModalOnBoarding,
        pointOfSale,
        userLocaleCountryCode,
        files,
        setFiles,
        isZetti,
        handleCloseIcon,
        setDataWithError,
        setBlockerFormat,
        resetErrorsWhenHaveFormatError,
        isOpen,
        quantityCart,
        visibility,
        isExpanded,
        isGestorERP,
    };
};

export default useDragDropUploader;
