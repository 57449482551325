import React, { memo } from 'react';
import { t } from 'i18next';
// styles
import styles from './WelcomeText.module.css';

const WelcomeText = () => {
    return (
        <div className={styles['image']}>
            <div className={styles['welcome-text']}>
                <img
                    alt="extendeal_pharma_logo"
                    src="/assets/images/IsologoHorizontalWhite.png"
                    width={315}
                    height={43}
                />
                <p className={styles['text']}>{t('login.login22')}</p>
            </div>
        </div>
    );
};

export default WelcomeText;
