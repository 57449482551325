import { memo } from 'react';
import isEqual from 'lodash/isEqual';
// COMPONENTS
import { CellProduct } from './CellProduct';
import { Cell } from './Cell';
import { Generics } from './Generics';
// INTERFACE
import { RowProps } from './Row.interface';
// utils
import { useUser } from 'utils/hooks';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { useTableRowV2 } from 'utils/hooks/TableComparativeRow/useTableRowV2';
// STYLES
import grid from '../../Grid.module.css';
import styles from './Row.module.css';

const Row = ({ idx, item, removeEvent, setOpenModalDiscounts, getCreditById, setProductInfo }: RowProps) => {
    const {
        gridRef,
        handleCheckboxOnChange,
        hasDrugManufacturerError,
        productGroup,
        withStockAllDrugManufacturers,
        setIsManualSelected,
        bonus,
        multiplierPerAppliedPromo,
        isAppliedIndex,
        unitPrice,
        lastUnitPrice,
        applyToleranceInDistribution,
        isBestByToleranceInPriorityService,
        productGroupHasServiceByPriority,
        handleOnCheck,
        isChecked,
        drugManufacturerIdSelected,
        areAllItemChecked,
        lowest,
        isSuggested,
        paymentDue,
        isFetchingProductGroups,
        updateQuantityRequired,
        uncheckAllProducts,
        childrenGenerics,
        handleSplitClick,
        productCant,
        drugManufacturer,
        isEnabledDrugManufacturer,
        setShowChildren,
        showChildren,
        loading,
        generics,
        isGenerics,
        CDO_DRUGMANUFACTURER,
        isZetti,
        isLowestSuggested,
        genericsIdsAdd,
        setGenericsIdsAdd
    } = useTableRowV2({ item, getCreditById, setProductInfo });

    const { user } = useUser({});
    const EX4800 = user?.EX4800 && !isZetti; // ff generics
    const EX6017 = user?.EX6017;

    // dejo comment para una v2 de la comparativa para integrar el effecto
    //${
    //    removeClick == item?.barcode ? styles['slide-out-blurred-right'] : ''
    //}

    return (
        <>
            <div
                className={`${grid['grid-system']} 
                ${showChildren ? styles['row-container-expanded'] : styles['row-container']}`}
                style={{ marginTop: idx === 1 ? 0 : 4, marginBottom: generics && showChildren ? 0 : 4 }}
                id={`productIndex-${productGroup?.order_product_id}`}
            >
                <CellProduct
                    idx={idx}
                    // DESCRIPTION
                    description={productGroup?.description}
                    // BARCODE
                    barcode={productGroup?.barcode}
                    // LAB
                    laboratory={productGroup?.laboratory}
                    // DRUG
                    drug={productGroup?.drug}
                    // CATEGORY
                    category={productGroup?.category}
                    // SKELETON
                    skeleton={loading}
                    // QUANTITY
                    quantity={productGroup?.quantity}
                    // CHECKBOX
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    // WITHOUT CHECK
                    withoutChecks={
                        !loading && productGroup?.barcode
                            ? !!areAllItemChecked() && !withStockAllDrugManufacturers()
                            : false
                    }
                    // DELETE PRODUCT
                    removeEvent={removeEvent}
                    // GENERICS
                    showChildren={showChildren}
                    setShowChildren={setShowChildren}
                    generics={generics}
                    parentRef={gridRef}
                    // RECOMMENDED
                    isRecommended={productGroup?.is_recommended}
                    // ORDER PRODUCT ID
                    orderProductId={productGroup?.order_product_id}
                    // USER
                    user={user}
                    // FETCHING PRODUCT GROUPS
                    isFetchingProductGroups={isFetchingProductGroups}
                    updateQuantityRequired={updateQuantityRequired}
                    drugmanufacturerIdSelected={drugManufacturerIdSelected()}
                    childrenGenerics={childrenGenerics}
                    uncheckAllProducts={uncheckAllProducts}
                    handleSplitClick={handleSplitClick}
                    is_split={productGroup?.is_split}
                    allow_split={productGroup?.allowed_split}
                    maxQuantitySplit={productGroup?.max_quantity ?? 0}
                    initial_quantity={productGroup?.initial_quantity ?? 0}
                    productMaxMin={productCant(drugManufacturerIdSelected())}
                    setIsManualSelected={setIsManualSelected}
                    // identify parent generic product
                    parentGenericProduct={productGroup?.isFatherGeneric}
                    genericsIdsAdd={genericsIdsAdd}
                />
                <div
                    className={`${!CDO_DRUGMANUFACTURER ? styles['middle-without-cdo'] : styles['middle']} ${
                        grid['grid-column-drugManufacturers']
                    }`}
                    id={`row-product-groups-${idx}`}
                >
                    {drugManufacturer.map(
                        (
                            { id: drugManufacturerId, name, cdo_status_id, type_id, vendor_accepted, enabled },
                            drugManufacturerIdx,
                        ) => {
                            return (
                                <Cell
                                    key={drugManufacturerIdx + 1}
                                    // PRODUCT BY DRUGMANUFACTURER ID
                                    product={productGroup && productGroup?.products[drugManufacturerId]}
                                    productPVP={productGroup?.pvp}
                                    // SKELETON
                                    skeleton={loading}
                                    // DRUGMANUFACTURER
                                    cdo_drugManufacturer={CDO_DRUGMANUFACTURER}
                                    drugManufacturerId={drugManufacturerId}
                                    drugManufacturerEnabled={isEnabledDrugManufacturer(drugManufacturerId)}
                                    drugManufacturerName={name}
                                    hasDrugManufacturerError={hasDrugManufacturerError(drugManufacturerId)}
                                    // DISCOUNTS MODAL
                                    setOpenModalDiscounts={setOpenModalDiscounts}
                                    // CHECKED
                                    checked={isChecked(drugManufacturerId)}
                                    handleOnCheck={handleOnCheck}
                                    // IS LOWEST
                                    lowest={lowest(drugManufacturerId)}
                                    // IS SUGGESTED
                                    isSuggested={isSuggested(drugManufacturerId)}
                                    // SERVICE
                                    productGroupHasServiceByPriority={productGroupHasServiceByPriority()}
                                    applyToleranceInDistribution={applyToleranceInDistribution(drugManufacturerId)}
                                    isBestByToleranceInPriorityService={isBestByToleranceInPriorityService(
                                        drugManufacturerId,
                                    )}
                                    // PAYMENT DUE
                                    paymentDue={paymentDue(drugManufacturerId)}
                                    // BONUS
                                    bonus={bonus(drugManufacturerId)}
                                    multiplierPerAppliedPromo={multiplierPerAppliedPromo}
                                    isAppliedIndex={isAppliedIndex}
                                    // USER
                                    user={user}
                                    // WITHOUT CHECK
                                    withoutChecks={!!areAllItemChecked()}
                                    // DISCOUNT
                                    lastUnitPrice={lastUnitPrice(drugManufacturerId)}
                                    unitPrice={unitPrice(drugManufacturerId)}
                                    // generics
                                    isGenerics={isGenerics}
                                    // QUANTITY
                                    quantity={productGroup?.quantity}
                                    // identify parent generic product
                                    parentGenericProduct={productGroup?.isFatherGeneric}
                                    //Splited
                                    allowSplit={productGroup?.allowed_split}
                                    isProductGroupSplit={productGroup?.is_split}
                                    // disabled radio
                                    isDisabledVendors={
                                        EX6017 && !vendor_accepted && type_id === EDrugManufacturerTypeId.vendor
                                    }
                                    isLowestSuggested={isLowestSuggested()}
                                />
                            );
                        },
                    )}
                </div>
            </div>

            {/* CHILDREN (generics) */}
            <div className={styles['row-generics-container']}>
                {EX4800 &&
                    productGroup?.generics?.map((itemGeneric) => {
                        return (
                            <Generics
                                key={itemGeneric?.barcode}
                                drugManufacturer={drugManufacturer}
                                cdo_drugManufacturer={CDO_DRUGMANUFACTURER}
                                idx={idx}
                                skeleton={loading}
                                removeEvent={removeEvent}
                                showChildren={showChildren}
                                setShowChildren={setShowChildren}
                                generics={generics}
                                setOpenModalDiscounts={setOpenModalDiscounts}
                                isEnabledDrugManufacturer={isEnabledDrugManufacturer}
                                user={user}
                                isGenerics={isGenerics}
                                itemGeneric={itemGeneric}
                                // ORDER PRODUCT ID
                                orderProductNonGenericId={productGroup?.order_product_id}
                                getCreditById={getCreditById}
                                setProductInfo={setProductInfo}
                                setGenericsIdsAdd={setGenericsIdsAdd}
                            />
                        );
                    })}
            </div>
        </>
    );
};

const areEqual = (prevProps, nextProps) => {
    // Extraer las propiedades necesarias
    const prevRelevantProps = {
        idx: prevProps.idx,
        amountSelected: prevProps.item?.amountSelected,
        drugManufacturerIdSelected: prevProps.item?.drugManufacturerIdSelected,
        lowest_without_cdo: prevProps.item?.lowest_without_cdo,
        areAllItemChecked: prevProps.item?.areAllItemChecked
    };

    const nextRelevantProps = {
        idx: nextProps.idx,
        amountSelected: nextProps.item?.amountSelected,
        drugManufacturerIdSelected: nextProps.item?.drugManufacturerIdSelected,
        lowest_without_cdo: nextProps.item?.lowest_without_cdo,
        areAllItemChecked: nextProps.item?.areAllItemChecked
    };
    
    return isEqual(prevRelevantProps, nextRelevantProps);
};

export default memo(Row, areEqual);
