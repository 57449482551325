import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// apis
import { deleteItem, undoDeleteProduct } from 'api';
// contexts
import { useLoadingProductContext, useProductsContext, useSnackbar, useUnitsContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';

export const useRemoveProduct = () => {
    const { replace } = useRouter();

    // contexts
    const {
        orderId,
        setUndoTimeout,
        setDisabledButtonMakeOrderByDelete,
        originalProductGroups,
        setOriginalProductGroups,
    } = useProductComparison();
    const { products, productGroups, setProducts, setProductGroups } = useProductsContext();
    const { setLoadingProduct } = useLoadingProductContext();
    const { units, setUnits } = useUnitsContext();

    // snackbar
    const snackbar = useSnackbar();

    // states
    const [clickedRemoveButton, setClickedRemoveButton] = useState({
        clicked: false,
        barcode: 0,
        index: 0,
        orderProductId: null,
    });

    const handleUndo = async () => {
        setLoadingProduct(false);
        try {
            setUndoTimeout(true);
            await undoDeleteProduct(orderId, clickedRemoveButton.orderProductId);
            undoItem(clickedRemoveButton.barcode, orderId, clickedRemoveButton.index);
            setClickedRemoveButton({ barcode: 0, clicked: false, index: 0, orderProductId: null });
            snackbar.hide();
        } catch {
            setUndoTimeout(true);
            setClickedRemoveButton({ barcode: 0, clicked: false, index: 0, orderProductId: null });
            snackbar.hide();
        }
    };

    const newDeleteItemT = async (orderId, productId) => {
        await deleteItem(orderId, productId);
        setLoadingProduct(false);
        setDisabledButtonMakeOrderByDelete(false);
    };

    const removeItem = async (barcode, orderId) => {
        setDisabledButtonMakeOrderByDelete(true);
        let filteredProduct = products.find((e) => barcode == e.barcode);

        let filteredProducts = products.filter((e) => filteredProduct.barcode !== e.barcode);
        let filteredProductGroups = productGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
        let filteredOriginalProductGroups = originalProductGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
        let filteredUnits = units.map((item) => {
            return {
                ...item,
                products: item.products.filter((product) => product.barcode !== barcode),
            };
        });

        setProducts(filteredProducts);
        setProductGroups(filteredProductGroups);
        setUnits(filteredUnits);
        setOriginalProductGroups(filteredOriginalProductGroups);
        const productId = filteredProduct.order_product_id;
        newDeleteItemT(orderId, productId);
    };

    const undoItem = async (barcode, orderId, index = 0) => {
        // get product
        let filteredProduct = products.find((e) => barcode == e.barcode);
        // search product in products
        let filteredProducts = products.filter((e) => filteredProduct.barcode !== e.barcode);
        // search product in product-groups
        let filteredProductGroups = productGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
        let filteredOriginalProductGroups = originalProductGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
        // push in correct position
        filteredProducts.splice(index, 0, filteredProduct);
        filteredProductGroups.splice(index, 0, filteredProduct);
        // set states
        setProductGroups(filteredProductGroups);
        setProducts(filteredProducts);
        setOriginalProductGroups(filteredOriginalProductGroups);
    };

    useEffect(() => {
        if (clickedRemoveButton.clicked) {
            setLoadingProduct(true);
            if (products.length == 1) {
                replace('/efficient-purchase/');
                return;
            }

            snackbar.show({
                message: t('shoppingCart.shoppingCart_Thirteen'),
                actionButton: {
                    label: t('efficientPurchasing.comparative.efficientPurchasing_comparativeFourteen'),
                    action: handleUndo,
                },
                success: true,
            });

            removeItem(clickedRemoveButton.barcode, orderId);
            setClickedRemoveButton({ ...clickedRemoveButton, clicked: false });
        }
    }, [clickedRemoveButton.clicked]);

    return {
        clickedRemoveButton,
        setClickedRemoveButton,
    };
};
