import React from 'react';
import SliderComponent from '../Slider/SliderComponent';
import { useStyles } from './InputRow.styles';
import Tooltip from 'components/Tooltip/Tooltip';

const InputRow = ({ open, drugmanufacturer, errors, handleChange, percentages, showErrors, newDrugmanufacturer }) => {
    const classes = useStyles();
    const name = drugmanufacturer?.name;
    const nameComponent = <div className={classes.inputNameValue}>{name}</div>;
    return (
        <div>
            <div className={classes.inputRowContainer}>
                <Tooltip
                    arrow={true}
                    label={name}
                    textAlign="center"
                    placement="bottom-start"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    maxHeight="20px"
                    top="-12px"
                    disable={name?.length <= 17}
                >
                    {nameComponent}
                </Tooltip>
                <div
                    style={{
                        width: 296,
                        position: 'relative',
                    }}
                >
                    <p
                        className={classes.tooltipNewDrugmanufacturer}
                        style={{
                            display:
                                newDrugmanufacturer[drugmanufacturer.id] &&
                                !percentages[drugmanufacturer.id]?.percentage
                                    ? 'flex'
                                    : 'none',
                        }}
                    >
                        {newDrugmanufacturer[drugmanufacturer.id]?.text}
                    </p>
                    <SliderComponent handleChange={handleChange} id={drugmanufacturer.id} percentages={percentages} />
                </div>
            </div>
            <div className={classes.inputError}>
                {errors[drugmanufacturer.id] && showErrors ? errors[drugmanufacturer.id] : ''}
            </div>
        </div>
    );
};

export default InputRow;
