import { Grid, Row, Col } from 'rsuite';
import style from './Header.module.css';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { EX5232 } from 'config/flags';
import { DeliveryNumber } from '@commons/components';
import { useUser } from 'utils/hooks';

const Header = ({ deliveryId, date, delivery_type, track_id }) => {
    const router = useRouter();
    const { query, pathname } = router;
    const { user } = useUser({})
    //translation by Country
    const { t, i18n } = useTranslation();

    // Config locale to serve as actual lang to dayjs
    const locale = i18n.language || 'es'; // Default fallback 'es'
    dayjs.locale(locale);

    // Format date
    const dateFormat = {
        es: 'DD [de] MMMM HH:mm [hs]',
        'en-US': 'MMMM DD [at] hh:mm A',
        'pt-br': 'DD [de] MMMM [às] HH:mm [h]',
    };

    const customDate = EX5232
        ? dayjs(date).format(dateFormat[locale] || dateFormat['es'])
        : dayjs(date).locale('es').format('DD [de] MMMM HH:mm [hs]'); // Default fallback 'es'

    const title =
        delivery_type === 'Manual delivery'
            ? EX5232
                ? t('myOrders.detailsDeliveryTypeOne')
                : 'O.C. con envío manual'
            : EX5232
            ? t('myOrders.detailsDeliveryTypeTwo')
            : 'O.C. emitida';
    return deliveryId && date ? (
        <Grid
            fluid
            className={style['header']}
            style={{ height: delivery_type === 'Manual delivery' ? '93px' : '113px' }}
        >
            <>
                <Row>
                    <Col xs={24} sm={12}>
                        <span className={style['sub-title']}>
                            #{query.order}-{deliveryId}
                        </span>
                    </Col>
                    <Col xs={24} sm={12}>
                        {delivery_type !== 'Manual delivery'  && (
                            <DeliveryNumber
                                label={t('trackingOne')}
                                text={t('trackingTwo')}
                                hash={track_id}
                                containerClassName={style['delivery-number-container']}
                                labelClassName={style['label']}
                                deliveryNumberClassName={style['delivery-number']}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <p className={style['title']}>
                        {title} | {customDate}
                    </p>
                </Row>
            </>
        </Grid>
    ) : (
        <></>
    );
};

export default Header;
