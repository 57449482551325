import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { DrawerRow } from '.';
import styles from './DrawerBody.module.css';
import LoadingBody from '@components/LoadingBody/LoadingBody';
import { t } from 'i18next';

const DrawerBody = ({
  products,
  quantityCart,
  handleUpdateProduct,
  pointOfSale,
  fetching,
  animationInProduct
}) => {
  const { user } = useUser({});
  const EX5943 = user?.EX5943; //FF epic rediseño home
  const userLocaleCountryCode = user?.client?.country_alfa_2;
  useChangeLanguage(userLocaleCountryCode);
  
  return (
    <div id='drawer-body' className={`${styles['drawer-body']} ${EX5943 ? styles['scrollbar-new-style'] : ''}`}>
      {fetching ? (
        <LoadingBody />
      ) : !quantityCart ? (
        <div className={styles['empty-cart']}>
          <div>
            <Image
              src={'/assets/images/rocket-extendeal.svg'}
              alt='cohete'
              width={157}
              height={160}
            />
          </div>
          <div className={styles['empty-cart-text']}>
            <div>
              <p>
                { t('shoppingCart.shoppingCart_TwentyEight')}
              </p>
            </div>
            <div>
              <p>
                { t('shoppingCart.shoppingCart_TwentyThree')}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <DrawerRow
          products={products}
          handleUpdateProduct={handleUpdateProduct}
          pointOfSale={pointOfSale}
          animationInProduct={animationInProduct}
        />
      )}
    </div>
  );
};

export default DrawerBody;
