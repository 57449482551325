import { useCallback, useEffect, useMemo, useRef } from 'react';
// configs
import { FFRECOMMENDED, IS_LOCAL_ENV } from 'config/flags';
// contexts
import {
    UpdateParentProductProvider,
    useContainerRefContext,
    useDrugManufacturersContext,
    useFetchingContext,
    useModalDiscountsContext,
    useProductsContext,
    useScrollFooterContext,
} from 'context';
import { useProductComparison } from 'context/product-comparison.context';
import { useRecommendationsContext } from 'context/productComparison/recommendations-context';
// COMPONENTS
import { Recommended } from '../Recommended';
import { Row } from './Row';
import { Arrow } from './Arrow';
// HOOKS
import { useTableData, usePositionArrowLeft, usePositionArrowRight, useRemoveProduct } from './hooks';
import { useCDOStatus } from '@commons/modules/TableProductComparisonV2/hooks';
import { useUser } from 'utils/hooks';

const TableProducts = () => {
    if (IS_LOCAL_ENV) {
        console.log('component TableProducts');
    }

    const { containerRef } = useContainerRefContext();

    const { recommendations, getCreditById } = useProductComparison();

    const { productGroups, setProductInfo } = useProductsContext();

    const { loading } = useFetchingContext();

    const { drugManufacturer } = useDrugManufacturersContext();

    const { CDO_IS_VISIBLE } = useCDOStatus();

    //show or not show recommendations
    const { showRecommendations } = useRecommendationsContext();

    const DRUGMANUFACTURERS = useMemo(() => drugManufacturer, [drugManufacturer]);

    const WITHOUT_RECOMMENDATIONS = useMemo(() => {
        return !showRecommendations || recommendations?.length === 0;
    }, [showRecommendations, recommendations]);

    const RENDER_TABLE = useMemo(() => {
        return DRUGMANUFACTURERS?.length > 0 && productGroups?.length > 0;
    }, [DRUGMANUFACTURERS, productGroups]);

    // remove product hook
    const { setClickedRemoveButton } = useRemoveProduct();

    // context scroll footer
    const { showScrollArrowLeft, showScrollArrowRight, handleClickScroll } = useScrollFooterContext();

    // modal discounts context
    const { setOpenModalDiscounts } = useModalDiscountsContext();

    /* #region ref table */
    const tableRef = useRef(null);
    /* #endregion */

    const { user } = useUser({});

    const EX6144 = user?.EX6144;

    /* #region grid row number */
    const GRID_ROW_TABLE = EX6144 ? (WITHOUT_RECOMMENDATIONS ? 2 : 3) : WITHOUT_RECOMMENDATIONS ? 3 : 4;
    /* #endregion */

    /* #region hook data table */
    const { data } = useTableData();
    /* #endregion */

    /* #region hook position button arrow left by scroll */
    const { arrowLeft } = usePositionArrowLeft('[class^="CellProduct_cell__"]');
    /* #endregion */

    /* #region hook position button arrow right by scroll */
    const { arrowRight } = usePositionArrowRight(containerRef, CDO_IS_VISIBLE, tableRef, RENDER_TABLE);
    /* #endregion */

    return (
        <>
            {/* ------------ RECOMMENDATIONS------------  */}
            {!WITHOUT_RECOMMENDATIONS && FFRECOMMENDED && data?.length ? <Recommended /> : <></>}
            {/* ----------------- TABLE PRODUCTS-----------------  */}
            <div
                style={{ gridRow: GRID_ROW_TABLE, gridColumn: '1 / 4', marginTop: WITHOUT_RECOMMENDATIONS ? 4 : '' }}
                ref={tableRef}
            >
                {/* BTN ARROW LEFT HORIZONTAL SCROLL */}
                <Arrow
                    show={showScrollArrowLeft}
                    skeleton={loading}
                    parentRef={containerRef}
                    position={'left'}
                    positionValue={arrowLeft}
                    handlerClick={() => handleClickScroll('left')}
                />
                {/* PRODUCT GROUPS */}
                <UpdateParentProductProvider>
                    {Array.from({ length: data?.length }).map((_, idx) => (
                        <Row
                            key={data[idx]?.order_product_id}
                            idx={idx + 1}
                            item={data[idx]}
                            removeEvent={setClickedRemoveButton}
                            setOpenModalDiscounts={setOpenModalDiscounts}
                            getCreditById={getCreditById}
                            setProductInfo={setProductInfo}
                        />
                    ))}
                </UpdateParentProductProvider>
                {/* BTN ARROW RIGHT HORIZONTAL SCROLL */}
                <Arrow
                    show={showScrollArrowRight}
                    skeleton={loading}
                    parentRef={containerRef}
                    position={'right'}
                    positionValue={arrowRight}
                    handlerClick={() => handleClickScroll('right')}
                />
            </div>
        </>
    );
};

export default TableProducts;
