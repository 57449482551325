import { useState, useEffect } from 'react';
import { Panel, Form, SelectPicker, IconButton } from 'rsuite';
import style from './OpenpayFormDetails.module.css';
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
import { getPlansSubscriptions, postPlansSubscriptions } from 'api/backoffice/backoffice';
import { useUser } from 'utils/hooks';
import { useRouter } from 'next/router';
import CheckIcon from '@mui/icons-material/Check';
import { useClientDetails } from 'context/client-details.context';
import dayjs from 'dayjs';
const OpenpayFormDetails = () => {
    // Context and router setup
    const { query } = useRouter();
    // context
    const { client, mutateClient } = useClientDetails();
    const { has_payment_pending, has_subscription, openpay_plan }: any = client;

    // State management
    const [formData, setFormData] = useState({ plan: '', email: '' });
    const [plans, setPlans] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Handles form field changes and updates the corresponding state
    const handleChange = (value, name) => {
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === 'email') {
            validateEmail(value);
        }
    };

    // Validates email format
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
        if (email && !emailRegex.test(email)) {
            setEmailError('Por favor ingresa un correo electrónico válido.');
        } else {
            setEmailError('');
        }
    };

    // Submits form data to the API
    const handleSendFormData = async () => {
        const payload = {
            client_id: query.clientId,
            contact_email: formData.email,
        };
        try {
            setIsLoading(true);
            await postPlansSubscriptions(formData.plan, payload);
            setIsLoading(false);
            setFormData({ plan: '', email: '' });
            setIsSubmitting(true);
            mutateClient();
            return;
        } catch (error) {
            console.error('Error enviando los datos del formulario:', error.message);
        }
    };

    // Fetches subscription plans from the API and formats them for the SelectPicker
    const getPlansForSubscriptions = async () => {
        try {
            const response = await getPlansSubscriptions();
            const formattedPlans = response?.data?.map(({ name, id, amount }) => ({
                label: name,
                value: id,
                amount,
            }));
            setPlans(formattedPlans || []);
        } catch (error) {
            console.error('Error obteniendo planes de suscripción:', error.message);
        }
    };

    // Validates the form and enables/disables the submit button based on input
    useEffect(() => {
        const isFormValid = formData.plan && formData.email && !emailError;
        setIsButtonDisabled(!isFormValid);
    }, [formData, emailError]);

    // Fetches subscription plans on component mount
    useEffect(() => {
        getPlansForSubscriptions();
    }, []);

    // Determines the amount of the selected plan or provides a placeholder message
    const selectedPlanAmount = plans.find((plan) => plan.value === formData.plan)?.amount || 'Seleccioná un plan';
    const currencyAmount = !!formData.plan ? 'MXN' : '';

    const showSuccessView = isSubmitting || (!has_payment_pending && !!has_subscription);

    const showTitleView = !!has_payment_pending && !has_subscription;

    const plansDetails = {
        Fecha: dayjs(openpay_plan?.created_at).format('DD/MM/YYYY'),
        Plan: openpay_plan?.plan_name,
        Valor: openpay_plan?.plan_amount,
        Email: openpay_plan?.email,
    };

    return (
        <div className={style['container-form']}>
            {showSuccessView && (
                <>
                    <div className={style['container-success']}>
                        <p className={style['text-information']}>Información General</p>
                        <div className={style['container-plan-detail']}>
                            {Object.entries(plansDetails).map(([key, value]) => (
                                <p key={key}>
                                    <strong>{key}:</strong> {value}
                                </p>
                            ))}
                        </div>
                        <div className={style['success-button-active']}>
                            <CheckIcon />
                        </div>

                        <p>
                            El envío se realizó correctamente.
                            <br /> Por favor, pida al cliente que verifique su bandeja de entrada.
                        </p>
                    </div>
                </>
            )}
            {showTitleView && !showSuccessView && (
                <div className={style['container-success']}>
                    <div className={style['container-plan-detail']}>
                            {Object.entries(plansDetails).map(([key, value]) => (
                                <p key={key}>
                                    <strong>{key}:</strong> {value}
                                </p>
                            ))}
                        </div>
                    <div className={style['success-button-active']}>
                        <CheckIcon />
                    </div>

                    <p>
                        Te confirmamos que el correo ha sido enviado. <br /> Te invitamos a revisarlo y estamos a tu
                        disposición para cualquier consulta.
                    </p>
                </div>
            )}

            {!has_payment_pending && !has_subscription && !showSuccessView && (
                <Panel className={style['container-form-panel']}>
                    <Form>
                        {/* Selector de planes */}
                        <Form.Group controlId="plan">
                            <Form.ControlLabel className={style['container-label']}>
                                Planes de suscripción
                            </Form.ControlLabel>
                            <SelectPicker
                                data={plans}
                                className={style['input-picker-selector']}
                                placeholder="Planes de suscripción"
                                value={formData.plan}
                                onChange={(value) => handleChange(value, 'plan')}
                            />
                            <p className={style['text-amount']}>
                                Valor: {selectedPlanAmount} {currencyAmount}
                            </p>
                        </Form.Group>

                        {/* Campo de email */}
                        <FormInput
                            name="email"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={(value) => handleChange(value, 'email')}
                            error={emailError}
                        />
                        {/* Botón de envío */}
                        <div className={style['container-button-send']}>
                            <ExButton
                                loading={isLoading}
                                disabled={isButtonDisabled || isLoading}
                                className={style['button']}
                                onClick={handleSendFormData}
                            >
                                Enviar
                            </ExButton>
                        </div>
                    </Form>
                </Panel>
            )}
        </div>
    );
};

export default OpenpayFormDetails;
