import { useEffect, useState } from 'react';
// contexts
import { useProductComparison } from 'context/product-comparison.context';
import { useProductsContext } from 'context';

export const useOpenModalWithoutStock = () => {
    const { recommendationLimit, recommendations, isFilteringByOutofStock } = useProductComparison();
    const { productGroups } = useProductsContext();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [allProductsOutOfStock, setAllProductsOutOfStock] = useState<boolean>(false);

    // detect products without stock and validate CdO status
    const checkStockByProducts = () => {
        const allProductsInStock = productGroups?.some((product) => {
            return product?.is_product_in_stock === true;
        });
        if (!allProductsInStock && productGroups?.length > 0 && !isFilteringByOutofStock) {
            setAllProductsOutOfStock(true);
        } else {
            setAllProductsOutOfStock(false);
        }
    };

    useEffect(() => {
        checkStockByProducts();
    }, [productGroups]);

    useEffect(() => {
        if (recommendations.length > 0) {
            recommendationLimit && allProductsOutOfStock ? setOpenModal(true) : setOpenModal(false);
        } else {
            allProductsOutOfStock ? setOpenModal(true) : setOpenModal(false);
        }
    }, [recommendationLimit, allProductsOutOfStock]);

    return { openModal };
};
