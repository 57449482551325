import { useEffect, useRef } from 'react';
// context
import { useScrollFooterContext } from 'context';
// components
import { CellProduct } from '../../CellProduct';
import { Cell } from '../../Cell';
// utils && hooks
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

const GenericsRow = ({
    idx,
    itemGeneric,
    skeleton,
    productGroup,
    handleCheckboxOnChange,
    areAllItemChecked,
    removeEvent,
    showChildren,
    setShowChildren,
    generics,
    isGenerics,
    uncheckAllProducts,
    cdo_drugManufacturer,
    styles,
    grid,
    drugManufacturer,
    isEnabledDrugManufacturer,
    itemsGenerics,
    hasDrugManufacturerError,
    isChecked,
    productGroupHasServiceByPriority,
    bonus,
    lastUnitPrice,
    setOpenModalDiscounts,
    handleOnCheck,
    lowest,
    isSuggested,
    applyToleranceInDistribution,
    isBestByToleranceInPriorityService,
    multiplierPerAppliedPromo,
    paymentDue,
    isAppliedIndex,
    user,
    unitPrice,
    setIsManualSelected,
    setGenericsIdsAdd
}) => {
    const { footerRef } = useScrollFooterContext();
    const genericRef = useRef(null);
    const EX6017 = user?.EX6017;

    useEffect(() => {
        // si se despliega el generico actualizamos el scrollLeft para sincronizar con el scroll
        if (showChildren) {
            if (genericRef?.current) {
                genericRef.current.scrollLeft = footerRef.current.scrollLeft;
            }
        }
    }, [showChildren, genericRef]);

    return (
        <>
            <div style={{ display: 'grid', gridColumn: '1 / 2' }}>
                <CellProduct
                    idx={idx}
                    // DESCRIPTION
                    description={itemGeneric?.description}
                    // BARCODE
                    barcode={itemGeneric?.barcode}
                    // LAB
                    laboratory={itemGeneric?.laboratory}
                    // DRUG
                    drug={itemGeneric?.drug}
                    // CATEGORY
                    category={itemGeneric?.category}
                    // SKELETON
                    skeleton={skeleton}
                    // QUANTITY
                    quantity={productGroup?.quantity}
                    // CHECKBOX
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    // WITHOUT CHECK
                    withoutChecks={!skeleton && itemGeneric?.barcode ? !!areAllItemChecked() : false}
                    // DELETE PRODUCT
                    removeEvent={removeEvent}
                    // GENERICS
                    showChildren={showChildren}
                    setShowChildren={setShowChildren}
                    generics={generics}
                    isGenerics={isGenerics}
                    // RECOMMENDED
                    isRecommended={itemGeneric?.is_recommended}
                    orderProductId={itemGeneric?.order_product_id}
                    //UNCHECK
                    uncheckAllProducts={uncheckAllProducts}
                    setIsManualSelected={setIsManualSelected}
                    // USER
                    user={user}
                    tag={itemGeneric?.properties?.generic_type}
                    setGenericsIdsAdd={setGenericsIdsAdd}
                />
            </div>
            <div
                className={`${!cdo_drugManufacturer ? styles['middle-without-cdo'] : styles['middle-generics']} ${
                    grid['grid-column-drugManufacturers']
                }`}
                id={`row-product-groups-${idx}`}
                ref={genericRef}
            >
                {drugManufacturer.map(
                    ({ id: drugManufacturerId, name, type_id, vendor_accepted }, drugManufacturerIdx) => {
                        const cellGenerics = itemsGenerics(productGroup, drugManufacturerId);
                        return (
                            <div className={styles['cell-generics']}>
                                <Cell
                                    key={drugManufacturerIdx + 1}
                                    // PRODUCT BY DRUGMANUFACTURER ID
                                    product={cellGenerics}
                                    productPVP={itemGeneric?.pvp}
                                    // SKELETON
                                    skeleton={skeleton}
                                    // DRUGMANUFACTURER
                                    cdo_drugManufacturer={cdo_drugManufacturer}
                                    drugManufacturerId={drugManufacturerId}
                                    drugManufacturerEnabled={isEnabledDrugManufacturer(drugManufacturerId)}
                                    drugManufacturerName={name}
                                    hasDrugManufacturerError={hasDrugManufacturerError(drugManufacturerId)}
                                    // DISCOUNTS MODAL
                                    setOpenModalDiscounts={setOpenModalDiscounts}
                                    // CHECKED
                                    checked={isChecked(drugManufacturerId)}
                                    handleOnCheck={handleOnCheck}
                                    // IS LOWEST
                                    lowest={lowest(drugManufacturerId)}
                                    // IS SUGGESTED
                                    isSuggested={isSuggested(drugManufacturerId)}
                                    // SERVICE
                                    productGroupHasServiceByPriority={productGroupHasServiceByPriority()}
                                    applyToleranceInDistribution={applyToleranceInDistribution(drugManufacturerId)}
                                    isBestByToleranceInPriorityService={isBestByToleranceInPriorityService(
                                        drugManufacturerId,
                                    )}
                                    // PAYMENT DUE
                                    paymentDue={paymentDue(drugManufacturerId)}
                                    // BONUS
                                    bonus={bonus(drugManufacturerId)}
                                    multiplierPerAppliedPromo={multiplierPerAppliedPromo}
                                    isAppliedIndex={isAppliedIndex}
                                    // USER
                                    user={user}
                                    // WITHOUT CHECK
                                    withoutChecks={!!areAllItemChecked()}
                                    // DISCOUNT
                                    lastUnitPrice={lastUnitPrice(drugManufacturerId, isGenerics, cellGenerics)}
                                    unitPrice={unitPrice(drugManufacturerId, isGenerics, cellGenerics)}
                                    // Generics
                                    isGenerics={isGenerics}
                                    quantity={productGroup?.quantity}
                                    // disabled radio
                                    isDisabledVendors={
                                        EX6017 && !vendor_accepted && type_id === EDrugManufacturerTypeId.vendor
                                    }
                                />
                            </div>
                        );
                    },
                )}
            </div>
        </>
    );
};

export default GenericsRow;
