import { useMemo } from 'react';
// CONFIGS
import { IS_LOCAL_ENV } from 'config/flags';
// CONTEXTS
import { useProductComparison } from 'context/product-comparison.context';
// COMPONENTS
import { TableSaving } from './TableSaving';
import { TableTotals } from './TableTotals';
import { ModalMinimunAmount } from '@commons/components/Modals';
// UTILS
import { StatusCdo } from 'utils/interfaces';
import { useUser } from 'utils/hooks';
// HOOKS
import { useCDOStatus, useParseIdToNameDrugManufacturer } from '@commons/modules/TableProductComparisonV2/hooks';
import { useRecommendationsContext } from 'context/productComparison/recommendations-context';
import {
    useDrugManufacturersContext,
    useFetchingContext,
    useLoadingProductContext,
    useMinimunPurchaseContext,
    useModalMinimunQuantityContext,
} from 'context';

const TableFooter = () => {
    if (IS_LOCAL_ENV) {
        console.log('component TableFooter');
    }

    const {
        getProductSelected,
        allProductHasChecked,
        //@ts-ignore
        unselectedProductNotificationVisibility,
        orderId,
        recommendations,
        getProductQuantity,
    } = useProductComparison();

    const { handlerModalMinMaxQuantity } = useModalMinimunQuantityContext();

    const { loading } = useFetchingContext();

    const { loadingProduct } = useLoadingProductContext();

    const { drugManufacturer, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const {
        handlerModalMinimunPurchase,
        minimunPurchase,
        addMinimunPurchase,
        removeMinimunPurchase,
    } = useMinimunPurchaseContext();

    const { parseIdToNameDrugManufacturer: uncheckedProductsByDrugNamesMinOrMax } = useParseIdToNameDrugManufacturer();

    const { user, userLocaleCountryCode } = useUser({});

    const EX4856 = user?.EX4856;

    const { CDO_IS_VISIBLE, CDO_STATUS_BY_POS } = useCDOStatus();

    const { showRecommendations } = useRecommendationsContext();

    const WITHOUT_RECOMMENDATIONS = useMemo(() => {
        return !showRecommendations || recommendations?.length === 0;
    }, [showRecommendations, recommendations]);

    return (
        <>
            {!!EX4856 && <ModalMinimunAmount />}
            <TableTotals
                drugManufacturer={drugManufacturer}
                cdo_is_visible={CDO_IS_VISIBLE}
                skeleton={loading}
                withoutRecommendations={WITHOUT_RECOMMENDATIONS}
                userLocaleCountryCode={userLocaleCountryCode}
                cdoStatusByPOS={CDO_STATUS_BY_POS}
                cdo_is_enabled={CDO_STATUS_BY_POS === StatusCdo.ENABLED}
                isEnabledDrugManufacturer={isEnabledDrugManufacturer}
                user={user}
                addMinimunPurchase={addMinimunPurchase}
                removeMinimunPurchase={removeMinimunPurchase}
            />
            <TableSaving
                getProductQuantity={getProductQuantity}
                skeleton={loading}
                orderId={orderId}
                getProductSelected={getProductSelected}
                minimunPurchase={minimunPurchase}
                handlerModalMinimunPurchase={handlerModalMinimunPurchase}
                allProductHasChecked={allProductHasChecked}
                unselectedProductNotificationVisibility={unselectedProductNotificationVisibility}
                withoutRecommendations={WITHOUT_RECOMMENDATIONS}
                uncheckedProductsByDrugNamesMinOrMax={uncheckedProductsByDrugNamesMinOrMax}
                handlerModalMinMaxQuantity={handlerModalMinMaxQuantity}
                loadingProduct={loadingProduct}
            />
        </>
    );
};

export default TableFooter;
