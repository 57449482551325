import React from 'react';
import style from './OnBoarding.module.css';
import { Badge } from 'rsuite';
import { ExButton } from '@commons/EXComponentsLibrary';
import { t } from 'i18next';

const OnBoarding = ({ handlePopoverClick, hidden }) => {
    return (
        <div className={style['container-onboarding']} style={{visibility: hidden ? 'hidden' : 'visible'}}>
            <div className={style['content-badge-title']}>
                <Badge className={style['content-badge']} content={t('analytics.onboarding.badge')} />
                <p className={style['title-popover-analytics']}>{t('analytics.onboarding.title')}</p>
            </div>
            <p className={style['subtitle-popover-analytics']}>{t('analytics.onboarding.subtitle')}</p>
            <div className={style['container-button-analytics']}>
                <ExButton onClick={handlePopoverClick}>{t('analytics.onboarding.button')}</ExButton>
            </div>
        </div>
    );
};

export default OnBoarding;
