import { t } from 'i18next';
// components
import { Header } from './Header';
import { Panel } from 'rsuite';
import { FormRecoveryPassword } from './FormRecoveryPassword';
import { ExButton } from '@commons/EXComponentsLibrary';
import { ExpiredToken } from '../ActivateUser/Content/ExpiredToken';
// utils && hooks
import { useMailTo, useNewPassword } from '../hooks';
// styles
import styles from './NewPassword.module.css';
import { memo } from 'react';

const NewPassword = () => {
    const {
        formState,
        onSubmit,
        handleNewPasswordCallback,
        updateFormField,
        loading,
        success,
        runValidations,
        redirectToLogin,
        status,
    } = useNewPassword();

    const { showMailTo } = useMailTo();

    if (![200, 400].includes(status)) {
        return;
    }

    return (
        <div className={styles['container']}>
            <>
                {status === 400 ? (
                    <ExpiredToken loading={loading} redirectToLogin={redirectToLogin} />
                ) : (
                    <>
                        <Panel bordered className={`${styles['panel']} ${success ? styles['custom-width'] : ''}`}>
                            <Header />
                            <FormRecoveryPassword
                                formState={formState}
                                onSubmit={onSubmit}
                                loading={loading}
                                updateFormState={updateFormField}
                                success={success}
                                handleNewPasswordCallback={handleNewPasswordCallback}
                                runValidations={runValidations}
                            />
                            {success && (
                                <ExButton
                                    type="submit"
                                    status="initial"
                                    withArrow={true}
                                    fullWidth
                                    size="lg"
                                    loading={false}
                                    onClick={redirectToLogin}
                                >
                                    {t('login.login4')}
                                </ExButton>
                            )}
                        </Panel>
                        <div className={`link__back-to-login-1 ${styles['contact-us']}`} onClick={showMailTo}>
                            {t('login.login21')}
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export default memo(NewPassword);
