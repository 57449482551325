import { useFetchingContext, useProductsContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
import { useMemo } from 'react';

const useTableData = () => {
    const { filtersByTerms, searchTerms } = useProductComparison();
    const { productGroups } = useProductsContext();
    const { loading } = useFetchingContext();

    const data = useMemo(() => {
        if (!loading) {
            return filtersByTerms();
        } else {
            return Array(6).fill(0);
        }
    }, [loading, searchTerms, productGroups]);

    return { data };
};

export default useTableData;
