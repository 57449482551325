import { useMemo } from 'react';
// utils
import { useUser } from 'utils/hooks';

export const useFF = () => {
    const { user } = useUser({});
    // FF's
    const EX4562 = useMemo(() => !!user?.EX4562, [user?.EX4562]); //cdo
    const EX3523 = useMemo(() => process.env.NEXT_PUBLIC_EX3523 === 'true', []);
    const EX4903 = useMemo(() => !!user?.EX4903, [user?.EX4903]);
    const EX4856 = useMemo(() => !!user?.EX4856, [user?.EX4856]);
    const EX5506 = useMemo(() => !!user?.EX5506, [user?.EX5506]); //FF epic insufficientCredit V2
    const EX5943 = useMemo(() => !!user?.EX5943, [user?.EX5943]); //FF epic rediseño home
    const EX6144 = useMemo(() => !!user?.EX6144, [user?.EX6144]);;

    return {
        EX4562,
        EX3523,
        EX4903,
        EX4856,
        EX5506,
        EX5943,
        EX6144
    };
};
