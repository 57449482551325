// react
import { useMemo } from 'react';
import { t } from 'i18next';
// enum
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';
// interface
import { IErrorObject } from 'utils/interfaces/ErrorObject/ErrorObject';
// aux
import { auxiliary } from './utils/aux.utils';

const useErrorsObjects = (errorData) => {
    // aux
    const { getFilteredErrorsAndNames } = auxiliary();

    // Memoize filtered errors and names to ensure they are only computed when needed
    const errorDataMemoized = useMemo(() => {
        return {
            [EErrorCode.WRONG_USERNAME_PASSWORD]: getFilteredErrorsAndNames(
                errorData,
                EErrorCode.WRONG_USERNAME_PASSWORD,
            ),
            [EErrorCode.CREDIT_LIMIT_REACHED]: getFilteredErrorsAndNames(errorData, EErrorCode.CREDIT_LIMIT_REACHED),
            [EErrorCode.EXPIRED_PASSWORD]: getFilteredErrorsAndNames(errorData, EErrorCode.EXPIRED_PASSWORD),
            [EErrorCode.MISSING_ACCOUNT]: getFilteredErrorsAndNames(errorData, EErrorCode.MISSING_ACCOUNT),
            [EErrorCode.MISSING_DISPATCH_ADDRESS]: getFilteredErrorsAndNames(
                errorData,
                EErrorCode.MISSING_DISPATCH_ADDRESS,
            ),
            [EErrorCode.MISSING_FORMAT]: getFilteredErrorsAndNames(
                errorData,
                EErrorCode.MISSING_FORMAT,
            ),
        };
    }, [errorData]);

    // object error
    const ErrorObjects: { [key in EErrorCode]: IErrorObject } = {
        [EErrorCode.UNKNOWN_ERROR]: {
            id: 1,
            name: 'unknown_error',
            banner: {
                title: '',
                subtitle: '',
            },
            tooltip: {
                title: '',
                subtitle: '',
            },
            quantity:errorDataMemoized[EErrorCode.UNKNOWN_ERROR]?.quantity
        },
        [EErrorCode.WRONG_USERNAME_PASSWORD]: {
            id: 2,
            name: 'wrong_username_password',
            banner: {
                title: t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorTen'),
                subtitle: t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorEleven', {
                    value: errorDataMemoized[EErrorCode.WRONG_USERNAME_PASSWORD].names,
                }),
            },
            tooltip: {
                title:
                    errorData?.length >= 2
                        ? t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorTen')
                        : t('efficientPurchasing.credentialsError.errorUserOrPassword'),
                subtitle: t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorEleven', {
                    value: errorDataMemoized[EErrorCode.WRONG_USERNAME_PASSWORD].names,
                }),
            },
            quantity:errorDataMemoized[EErrorCode.WRONG_USERNAME_PASSWORD]?.quantity
        },
        [EErrorCode.OPERATION_FORBIDDEN]: {
            id: 3,
            name: 'operation_forbidden',
            banner: {
                title: '',
                subtitle: '',
            },
            tooltip: {
                title: '',
                subtitle: '',
            },
            quantity:errorDataMemoized[EErrorCode.OPERATION_FORBIDDEN]?.quantity
        },
        [EErrorCode.CREDIT_LIMIT_REACHED]: {
            id: 4,
            name: 'credit_limit_reached',
            banner: {
                title: t('efficientPurchasing.insufficientCredit.labelInsufficientCredit'),
                subtitle: t('efficientPurchasing.insufficientCredit.efficientPurchasing_insufficientCreditOne', {
                    nameDrugmanufacturerCredit: errorDataMemoized[EErrorCode.CREDIT_LIMIT_REACHED].names,
                }),
            },
            tooltip: {
                title: t('efficientPurchasing.insufficientCredit.labelInsufficientCredit'),
                subtitle: t('efficientPurchasing.insufficientCredit.efficientPurchasing_insufficientCreditTwo'),
                label:t('efficientPurchasing.insufficientCredit.notEnoughCreditInDrugmanufacturer'),
            },
            quantity:errorDataMemoized[EErrorCode.CREDIT_LIMIT_REACHED]?.quantity
        },
        [EErrorCode.REASON_PROVIDED]: {
            id: 5,
            name: 'reason_provided',
            banner: {
                title: '',
                subtitle: '',
            },
            tooltip: {
                title: '',
                subtitle: '',
            },
            quantity:errorDataMemoized[EErrorCode.REASON_PROVIDED]?.quantity
        },
        [EErrorCode.EXPIRED_PASSWORD]: {
            id: 6,
            name: 'expired_password',
            banner: {
                title: '',
                subtitle: '',
            },
            tooltip: {
                title: t('efficientPurchasing.credentialsError.One'),
                subtitle: t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorEleven', {
                    value: errorDataMemoized[EErrorCode.EXPIRED_PASSWORD].names,
                }),
            },
            quantity:errorDataMemoized[EErrorCode.EXPIRED_PASSWORD]?.quantity
        },
        [EErrorCode.MISSING_ACCOUNT]: {
            id: 7,
            name: 'missing_account',
            banner: {
                title: '',
                subtitle: '',
            },
            tooltip: {
                title: t('efficientPurchasing.credentialsError.Two'),
                subtitle: t('efficientPurchasing.credentialsError.Two_One', {
                    value: errorDataMemoized[EErrorCode.MISSING_ACCOUNT].names,
                }),
            },
            quantity:errorDataMemoized[EErrorCode.MISSING_ACCOUNT]?.quantity
        },
        [EErrorCode.MISSING_DISPATCH_ADDRESS]: {
            id: 8,
            name: 'missing_dispatch_address',
            banner: {
                title: '',
                subtitle: '',
            },
            tooltip: {
                title: t('efficientPurchasing.credentialsError.Three'),
                subtitle: t('efficientPurchasing.credentialsError.Three_One', {
                    value: errorDataMemoized[EErrorCode.MISSING_DISPATCH_ADDRESS].names,
                }),
            },
            quantity:errorDataMemoized[EErrorCode.MISSING_DISPATCH_ADDRESS]?.quantity
        },
        [EErrorCode.MISSING_FORMAT]: {
            id: 10,
            name: 'missing_format',
            banner: {
                title: 'Falta el formato para compra con archivo',
                subtitle: t('efficientPurchasing.credentialsError.missing_format_two'),
            },
            tooltip: {
                title: 'Falta el formato para compra con archivo',
                subtitle: t('efficientPurchasing.credentialsError.missing_format_two'),
            },
            quantity: errorDataMemoized[EErrorCode.MISSING_FORMAT]?.quantity
        }
    };

    return ErrorObjects;
};

export default useErrorsObjects;
