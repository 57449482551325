import { useMemo } from 'react';
// CONFIGS
import { IS_LOCAL_ENV } from 'config/flags';
// CONTEXTS
import { useExpandedFooterContext } from 'context/productComparison/expanded-footer';
import { useProductComparison } from 'context/product-comparison.context';
import { useRecommendationsContext } from 'context/productComparison/recommendations-context';
import {
    GenericsProvider,
    InsuficientCreditProvider,
    MinimunPurchaseProvider,
    SelectAllDrugManufacturerProvider,
    SelectAllStateProvider,
    useContainerRefContext,
    useDrugManufacturersContext,
} from 'context';
// COMPONENTS
import { TableToolbar } from './TableToolbar';
import { TableHeader } from './TableHeader';
import { TableProducts } from './TableProducts';
import { TableFooter } from './TableFooter';
import { WithoutSearching } from './WithoutSearching';
import { NewHeader } from '../Layouts';
// utils && hooks
import { useAlertBanner } from './hooks';
import { useAlerts } from '@commons/modules/TableProductComparisonV2/hooks';
// STYLES
import styles from './Layout.module.css';

const TableComparison = ({ children, fetchProductGroup }): JSX.Element => {
    const { containerRef } = useContainerRefContext();

    const { recommendations, withoutSearchings } = useProductComparison();

    const { drugManufacturer } = useDrugManufacturersContext();

    const { expandedFooter } = useExpandedFooterContext();

    const { showRecommendations } = useRecommendationsContext();

    const WITHOUT_RECOMMENDATIONS = useMemo(() => {
        return !showRecommendations || recommendations?.length === 0;
    }, [showRecommendations, recommendations]);

    if (IS_LOCAL_ENV) {
        console.log('component TableComparison');
    }

    const showAlert: boolean = useAlertBanner();

    const { hasAlert } = useAlerts();

    const isShowSomeAlert: boolean = (showAlert || hasAlert);

    return (
        <SelectAllStateProvider>
            <GenericsProvider>
                <div
                    className={`${styles['layout-grid-container-v2']}`}
                    ref={containerRef}
                    style={{
                        height: '100vh',
                        gridTemplateRows: isShowSomeAlert ? `56px max-content 64px auto` : `56px 64px auto`,
                    }}
                >
                    <div className={styles['header']}>
                        <NewHeader orders={null} delivery={null} />
                    </div>
                    {isShowSomeAlert && (
                        <div
                            style={{
                                gridRow: 2,
                                maxHeight: 74,
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: 'column',
                                zIndex: 10
                            }}
                        >
                            {children
                                ?.filter?.((c) => c !== false)
                                ?.map((alert, index) => (
                                    <div key={index} className="alert">
                                        {alert}
                                    </div>
                                ))}
                        </div>
                    )}
                    {/* TOOLBAR */}
                    <TableToolbar />
                    {/* TABLE */}
                    <div
                        style={{
                            display: drugManufacturer?.length === 0 ? 'none' : '',
                            padding: '0px 19px',
                            gridRow: isShowSomeAlert ? 4 : 3,
                        }}
                        className={`${
                            !expandedFooter
                                ? styles[
                                      WITHOUT_RECOMMENDATIONS
                                          ? 'layout-table-without-recommendations-v2'
                                          : 'layout-table-with-recommendations-v2'
                                  ]
                                : styles[
                                      WITHOUT_RECOMMENDATIONS
                                          ? 'layout-table-without-recommendations-expanded-footer-v2'
                                          : 'layout-table-with-recommendations-expanded-footer-v2'
                                  ]
                        }`}
                    >
                        {/* HEADER drugmanufacturer */}
                        {!withoutSearchings && (
                            <InsuficientCreditProvider>
                                <SelectAllDrugManufacturerProvider>
                                    <TableHeader fetchProductGroup={fetchProductGroup} />
                                </SelectAllDrugManufacturerProvider>
                            </InsuficientCreditProvider>
                        )}
                        {/* TABLE PRODUCTS */}
                        {!!withoutSearchings ? (
                            // RENDER NO DATA
                            <WithoutSearching />
                        ) : (
                            <TableProducts />
                        )}
                        {/* FOOTER TOTALS && SAVING */}
                        {
                            <MinimunPurchaseProvider>
                                <TableFooter />
                            </MinimunPurchaseProvider>
                        }
                    </div>
                </div>
            </GenericsProvider>
        </SelectAllStateProvider>
    );
};

export default TableComparison;
