// next
import { useRouter } from 'next/router';
// react
import { useState } from 'react';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// translate
import { t } from 'i18next';
// style
import style from './CellButtonVendor.module.css';
// utils
import { axiliaryFunctionsVendors } from './utils';
import { useHubspotStatus } from 'context/HubspotStatusContext/HubspotStatusContext';
import { useUser } from 'utils/hooks';

const CellButtonVendor = ({ drugManufacturerId }) => {
    // next
    const router = useRouter();
    const { posId } = router.query;

    const { user } = useUser({})
    const EX7402 = user?.EX7402

    const { disabledHubspotClient } = useHubspotStatus();
    // aux
    const auxFx = axiliaryFunctionsVendors();
    // state
    const [isRequiredVendor, setIsRequiredVendor] = useState<boolean>(
        JSON.parse(localStorage.getItem('isRequiredVendor')),
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // logic
    const handleSendEmail = () =>
        auxFx.handlerSubmitSendEmailVendors(
            drugManufacturerId,
            { point_of_sale_id: posId },
            setIsRequiredVendor,
            setIsLoading,
        );

    return (
        <>
            {disabledHubspotClient && EX7402 ? (
                <RsuiteTooltip trigger="hover" placement="top" text="Contactá a un ejecutivo de cuentas">
                    <span>
                        <ExButton
                            loading={isLoading}
                            onClick={() => handleSendEmail()}
                            className={`${
                                !isRequiredVendor ? style['button-type'] : style['button-type-required']
                            } button__request-wholesaler`}
                            disabled={isRequiredVendor || disabledHubspotClient}
                        >
                            {!isRequiredVendor ? t('efficientPurchase.vendorsOne') : t('efficientPurchase.vendorsTwo')}
                        </ExButton>
                    </span>
                </RsuiteTooltip>
            ) : (
                <ExButton
                    loading={isLoading}
                    onClick={() => handleSendEmail()}
                    className={`${
                        !isRequiredVendor ? style['button-type'] : style['button-type-required']
                    } button__request-wholesaler`}
                    disabled={isRequiredVendor || disabledHubspotClient}
                >
                    {!isRequiredVendor ? t('efficientPurchase.vendorsOne') : t('efficientPurchase.vendorsTwo')}
                </ExButton>
            )}
        </>
    );
};

export default CellButtonVendor;
