import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
// configs
import { EX4332 } from 'config/flags';
// components
import { InstitutionalHeader, InstitutionalLayout } from '@commons/components';
import { Content } from './Content';
import { Footer } from './Content/Form/Footer';

const FormSignup = () => {
    const { push } = useRouter();

    useEffect(() => {
        if (!EX4332) {
            push('/login');
        }
    }, []);

    return (
        <>
            <InstitutionalLayout>
                <InstitutionalHeader>
                    <Image
                        src={'/assets/images/institutional_logo.svg'}
                        alt="institutional logo"
                        width={216.82}
                        height={28.89}
                        priority={true}
                    />
                </InstitutionalHeader>
                <Content />
                <Footer />
            </InstitutionalLayout>
        </>
    );
};

export default FormSignup;
