import React from 'react';
import Link from 'next/link';
import { t } from 'i18next';
// components
import { Header } from './Header';
import { Panel } from 'rsuite';
import { FormRecoveryPassword } from './FormRecoveryPassword';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import { ExButton } from '@commons/EXComponentsLibrary';
// utils && hooks
import { useMailTo, useRecovery } from '../hooks';
// styles
import styles from './RecoveryPassword.module.css';

const RecoveryPassword = () => {
    const { formState, updateFormState, onSubmit, onChangeCaptcha, loading, success, redirectToLogin } = useRecovery();
    const { showMailTo } = useMailTo();

    return (
        <div className={styles['container']}>
            <Panel bordered className={`${styles['panel']} ${success ? styles['custom-width'] : ''}`}>
                <Header />
                <FormRecoveryPassword
                    formState={formState}
                    onSubmit={onSubmit}
                    onChangeCaptcha={onChangeCaptcha}
                    loading={loading}
                    updateFormState={updateFormState}
                    success={success}
                />
                {success && (
                    <ExButton
                        type="submit"
                        status="initial"
                        withArrow={true}
                        fullWidth
                        size="lg"
                        loading={loading}
                        onClick={redirectToLogin}
                    >
                        {t('login.login4')}
                    </ExButton>
                )}
                {!success && (
                    <Link href={'/login'} className={styles['redirect-login']}>
                        <ArrowLeftLineIcon />
                        <p>{t('login.login27')}</p>
                    </Link>
                )}
            </Panel>
            <div className={`link__back-to-login-1 ${styles['contact-us']}`} onClick={showMailTo}>
                {t('login.login21')}
            </div>
        </div>
    );
};

export default RecoveryPassword;
