import { ExAvatarConnections, ExButton } from '@commons/EXComponentsLibrary';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import stringAvatar from 'utils/avatar-name';
import { Status } from 'utils/hooks';
import { handleSwitch } from '../../NewCredentialCards.helpers';
import { credentialData } from '../NewFormConnectionV3/schema/formShemaModel';
import styles from './NewModalVendor.module.css';
import { useRouter } from 'next/router';
import { disabledVendors } from 'api/vendors/vendors';
import { mutateFromCache } from 'utils/swr';
import { useState } from 'react';
const NewModalVendor = () => {
    const router = useRouter();
    const { query } = router;
    const {
        drugConnection,
        getNameById,
        handlerAlertBeforeDelete,
        handlerDeleteCredential,
        orderByCredentialType,
        statusApiCredentialDeleted,
        getDrugInformationById,
        toggleModalVendor,
        mutateDrugManufacturers,
    } = useModalCredentialsContext();

    const { formatStatus, credentialTypes, speciality } = getDrugInformationById();

    const statusButton = statusApiCredentialDeleted === Status.Resolved ? 'initial' : 'success';
    const loadingButton = statusApiCredentialDeleted === Status.Pending;

    const [loading, setLoading] = useState(false);

    const handlerEnabledVendors = async () => {
        const payload = {
            drug_manufacturer_id: drugConnection?.id,
            point_of_sale_id: query?.posId,
        };
        setLoading(true);
        await disabledVendors(payload);

        toggleModalVendor.visibility && toggleModalVendor.toggle();
        setLoading(false);
        mutateFromCache('/api/api-credential');

        mutateDrugManufacturers();

        return;
    };

    // WIP until backend serve endpoint to connect vendor
    const handleButtonRender = () => {
        if (drugConnection?.id) {
            // find vendor connected by api_credential_id
            const isApproved = !!credentialData?.credentialId;
            if (isApproved) {
                return (
                    <ExButton
                        size="lg"
                        fullWidth
                        //loading={loadingButton}
                        //loadingText={'Desconectando...'}
                        //status={statusButton}
                        type="submit"
                        appearance="critical"
                        onClick={() =>
                            handlerAlertBeforeDelete(
                                credentialData?.credentialId,
                                handleSwitch(credentialData?.nameType, credentialTypes),
                                credentialData?.drugName,
                                drugConnection?.id,
                            )
                        }
                    >
                        {t('settings.pointOfSales.menuDisconnect')}
                    </ExButton>
                );
            } else {
                return (
                    <ExButton
                        fullWidth
                        size="lg"
                        onClick={handlerEnabledVendors}
                        loadingText={'Conectando...'}
                        loading={loading}
                    >
                        {t('settings.pointOfSales.menuConnect')}
                    </ExButton>
                );
            }
        }
    };
    return (
        <Modal open={toggleModalVendor?.visibility} className={styles['modal']} overflow={false}>
            <img
                src="/assets/images/close.svg"
                alt="close-lock"
                className={styles['close-icon']}
                onClick={() => toggleModalVendor.toggle()}
                style={{
                    cursor: 'pointer',
                }}
                draggable={false}
            />
            <Modal.Header closeButton={false} className={styles['modal-header']}>
                <img src="/assets/images/extendeal.png" className={styles['extendeal-image']} />
                <img src="/assets/images/swap_horiz.svg" />
                {drugConnection?.id && (
                    <ExAvatarConnections
                        initialsName={stringAvatar(getNameById(drugConnection.id), false, drugConnection.id)}
                        status={formatStatus}
                        style={{ width: 32, height: 32, fontSize: 12 }}
                    />
                )}
            </Modal.Header>
            <Modal.Body className={styles['modal-body']}>
                <div className={styles['vendor-info-container']}>
                    <span className={styles['vendor-info-name']}>{getNameById(drugConnection.id)}</span>
                    <span className={styles['vendor-info-description']}>{speciality}</span>
                </div>
                {handleButtonRender()}
            </Modal.Body>
        </Modal>
    );
};

export default NewModalVendor;
