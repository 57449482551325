import { usePointOfSale } from 'context';
import { useStatusContext } from 'context/control-state';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Joyride from 'react-joyride';
import { useUser } from 'utils/hooks';
import { isZettiFormat } from 'utils/pointOfSale';
import { Tooltip } from './Steps';
import { normalOnboarding } from './array';

const OnBoarding = () => {
    const router = useRouter();
    const { user } = useUser({});
    const EX5943 = user?.EX5943;
    const [domLoaded, setDomLoaded] = useState(false);
    const [stepIndex, setStepIndex] = useState(EX5943 ? 7 : 20);
    const joyrideRef = useRef(null);
    let firstLogin = '';
    let checkOnboarding = false;
    let pdv = '';
    if (typeof window !== 'undefined') {
        firstLogin = localStorage.getItem('firstLogin');
        checkOnboarding = localStorage.getItem('checkOnboarding') !== 'true';
        pdv = localStorage.getItem('pdvSelected');
    }

    const { pointOfSale } = usePointOfSale();

    const isZetti = isZettiFormat(pointOfSale);

    const {
        setCounterStep,
        isTourOpen,
        setIsTourOpen,
        setDisplayModalOnBoarding,
        hideSuggestionPDV,
    } = useStatusContext();

    useEffect(() => {
        setDomLoaded(true);

        if (firstLogin === 'true') {
            setStepIndex(7);
        }
        // turn off onboarding new header
        else if (!EX5943) {
            if (localStorage.getItem('pdvSelected') !== null && !isZetti && checkOnboarding) {
                setStepIndex(0);
                setIsTourOpen(true);
                setDisplayModalOnBoarding(true);
            }
        }
    }, [firstLogin]);

    const [{ run, steps }, setState] = useState({
        run: true,
        steps: normalOnboarding(),
    });

    const handleStepActions = (obj) => {
        if (obj.action === 'close' || obj.action === 'stop') {
            setCounterStep('body');
            setIsTourOpen(false);
            if (obj.step.target === '#step-6') {
                localStorage.setItem('checkOnboarding', 'true');
            }
        } else {
            setCounterStep(obj.step.target);
        }
    };

    const handleJoyrideCallback = (data) => {
        let finish = false;
        const { action, index, type } = data;

        if (action === 'close') {
            finish = true;
            setStepIndex(0);
        }
        if (action === 'prev' && type === 'step:after' && !finish) {
            setStepIndex(index - 1);
        }
        if (action === 'next' && type === 'step:after' && !finish) {
            setStepIndex(index + 1);
        }
    };

    const oldValidationHeader =
        ('/[clientId]/efficient-purchase/[posId]' === router.pathname ||
            '/[clientId]/efficient-purchase' === router.pathname) &&
        isTourOpen &&
        (checkOnboarding || pdv === null || '/[clientId]/efficient-purchase' === router.pathname);

    const newValitionHeader = '/[clientId]/efficient-purchase' === router.pathname && !hideSuggestionPDV;

    const runOnboarding = EX5943 ? newValitionHeader : oldValidationHeader;

    return !domLoaded && stepIndex !== 20 ? (
        <></>
    ) : (
        !isZetti && (
            <Joyride
                //@ts-ignore
                tooltipComponent={Tooltip}
                //@ts-ignore
                steps={steps}
                stepIndex={stepIndex}
                disableOverlayClose
                styles={{
                    options: {
                        overlayColor: 'transparent',
                        primaryColor: 'blue',
                        textColor: '#004a14',
                        width: 382,
                        zIndex: 1000,
                    },
                }}
                callback={(obj) => {
                    handleJoyrideCallback(obj);
                    handleStepActions(obj);
                }}
                disableScrollParentFix
                disableScrolling
                spotlightClicks={false}
                continuous
                run={runOnboarding}
                ref={joyrideRef}
            />
        )
    );
};

export default OnBoarding;
