import React from 'react';
// COMPONENTS
import { CellCdo } from './CellCdo';
import { CellPrice } from './CellPrice';
// UTILS
import { applyToleranceAccordingToService } from '../../../utils';
import { DrugManufacturersId } from 'utils/interfaces';
import { determineChipColor, getDepositName } from './utils';

const Cell = ({
    skeleton,
    product,
    productPVP = null,
    cdo_drugManufacturer,
    drugManufacturerId,
    drugManufacturerEnabled,
    drugManufacturerName,
    hasDrugManufacturerError,
    setOpenModalDiscounts,
    checked,
    handleOnCheck,
    lowest,
    isSuggested,
    productGroupHasServiceByPriority,
    applyToleranceInDistribution,
    isBestByToleranceInPriorityService,
    paymentDue,
    bonus,
    multiplierPerAppliedPromo,
    isAppliedIndex,
    user,
    withoutChecks,
    lastUnitPrice,
    unitPrice,
    isGenerics = false,
    quantity,
    parentGenericProduct = false,
    isProductGroupSplit = false,
    allowSplit = false,
    isDisabledVendors,
    isLowestSuggested=false
}) => {
    const isCellSplit = user?.EX5937 && isProductGroupSplit && isSuggested && allowSplit && checked;
    return (
        <>
            {drugManufacturerId === DrugManufacturersId.CentralDeOfertas ? (
                <CellCdo
                    cdo_status_id={cdo_drugManufacturer?.cdo_status_id}
                    product={product}
                    productPVP={productPVP}
                    skeleton={skeleton}
                    chipColor={determineChipColor({
                        product,
                        bonus,
                        isAppliedIndex,
                        checked,
                        isCellSplit: isCellSplit,
                    })}
                    applyToleranceAccordingToService={applyToleranceAccordingToService(
                        productGroupHasServiceByPriority,
                        applyToleranceInDistribution,
                        isBestByToleranceInPriorityService,
                        checked,
                        isSuggested,
                        lowest,
                    )}
                    isLowest={lowest}
                    isSuggested={isSuggested}
                    checked={checked}
                    onChecked={handleOnCheck}
                    drugManufacturerEnabled={drugManufacturerEnabled}
                    drugManufacturerName={cdo_drugManufacturer?.name}
                    setOpenModalDiscounts={setOpenModalDiscounts}
                    hasDrugManufacturerError={hasDrugManufacturerError}
                    bonus={bonus}
                    multiplierPerAppliedPromo={multiplierPerAppliedPromo}
                    isAppliedIndex={isAppliedIndex}
                    user={user}
                    withoutChecks={withoutChecks}
                    lastUnitPrice={lastUnitPrice}
                    unitPrice={unitPrice}
                    quantity={quantity}
                    isProductGroupSplit={isProductGroupSplit}
                    isCellSplit={isCellSplit}
                    isLowestSuggested={isLowestSuggested}
                />
            ) : (
                <CellPrice
                    drugManufacturerId={drugManufacturerId}
                    payment_due={paymentDue}
                    depositName={getDepositName({ drugManufacturerId, product })}
                    product={product}
                    productPVP={productPVP}
                    skeleton={skeleton}
                    chipColor={determineChipColor({
                        product,
                        bonus,
                        isAppliedIndex,
                        checked,
                        isCellSplit: isCellSplit,
                    })}
                    applyToleranceAccordingToService={applyToleranceAccordingToService(
                        productGroupHasServiceByPriority,
                        applyToleranceInDistribution,
                        isBestByToleranceInPriorityService,
                        checked,
                        isSuggested,
                        lowest,
                    )}
                    isLowest={lowest}
                    isSuggested={isSuggested}
                    checked={checked}
                    onChecked={handleOnCheck}
                    drugManufacturerEnabled={drugManufacturerEnabled}
                    drugManufacturerName={drugManufacturerName}
                    setOpenModalDiscounts={setOpenModalDiscounts}
                    hasDrugManufacturerError={hasDrugManufacturerError}
                    bonus={bonus}
                    multiplierPerAppliedPromo={multiplierPerAppliedPromo}
                    isAppliedIndex={isAppliedIndex}
                    user={user}
                    withoutChecks={withoutChecks}
                    lastUnitPrice={lastUnitPrice}
                    unitPrice={unitPrice}
                    // generics
                    isGenerics={isGenerics}
                    quantity={quantity}
                    parentGenericProduct={parentGenericProduct}
                    isProductGroupSplit={isProductGroupSplit}
                    isCellSplit={isCellSplit}
                    isDisabledVendors={isDisabledVendors}
                    isLowestSuggested={isLowestSuggested}
                />
            )}
        </>
    );
};

export default Cell;
