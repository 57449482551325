import { useUser } from 'utils/hooks';
import { FirstLogin } from './FirstLogin';
import { PresentationCard } from './PresentationCard';
import { StepsCard } from './StepsCard';
const Tooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => {
    const { user } = useUser({});
    const EX5943 = user?.EX5943;
    if (EX5943) {
        if (index === 7) {
            return <FirstLogin index={index} step={step} />;
        }
    } else {
        switch (true) {
            case index === 7:
                return <FirstLogin index={index} step={step} />;
            case index === 0:
                return (
                    <PresentationCard
                        continuous={continuous}
                        index={index}
                        step={step}
                        backProps={backProps}
                        closeProps={closeProps}
                        primaryProps={primaryProps}
                        tooltipProps={tooltipProps}
                    />
                );
            default:
                return (
                    <StepsCard
                        continuous={continuous}
                        index={index}
                        step={step}
                        backProps={backProps}
                        closeProps={closeProps}
                        primaryProps={primaryProps}
                        tooltipProps={tooltipProps}
                    />
                );
        }
    }
};

export default Tooltip;
