import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
// configs
import { EX4927, EX6780, EX6785 } from 'config/flags';
// apis
import { PostProcessRequest } from 'api';
// utils && hooks
import { DrugManufacturer, Product, ProductInterface } from 'common/types';
import { useStatesFilters } from 'utils/hooks/use-states-filters';
import { isEmptyObjectAtrribute } from 'utils/object';
import { useUser } from 'utils/hooks';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { useDrugManufacturersContext } from './productComparison/drugManufacturers-context';
import { useUnitsContext } from './productComparison/units-context';
import { useProductsContext } from './productComparison/products-context';

interface ProductComparisonParams {
    children: ReactNode;
    orderId: any;
    drugManufacturerStatusList: any[];
    remainingAttemptsInitValue: number;
    pointOfSale: any;
}

export type ItemCheckbox = {
    label: string;
    id: number;
    name: 'Sin stock' | 'Con descuento' | 'Con genéricos';
    isChecked: boolean;
};

export interface EstimatedSavingsProps {
    youCanSave: number;
    youAreSaving: number;
}

export type InsufficientCreditWarning = {
    id: number;
    isWarningOpen: boolean;
};

export interface ProductComparisonProps extends ProductComparisonParams {
    remainingAttempts: number;
    setRemainingAttempts(value: number): void;
    retryLoading: boolean;
    setRetryLoading(value: boolean): void;
    isAnyDrugManufacturerFailed(): boolean;
    getProductSelectedByDrugManufacturerId(id: Product['drug_manufacturer_id']): ProductInterface[];
    getWithStockDrugManfucaturersById(id: Product['drug_manufacturer_id']): ProductInterface[];
    getProductSelected(): PostProcessRequest[];
    productHasCheckedQuantity(): ProductInterface[];
    allProductHasChecked(): boolean;
    getProductQuantity(): number;
    getSubtotalById(id: DrugManufacturer['id']): number;
    drugManufacturersFailedList: any[];
    setDrugManufacturersFailedList(drugManufacturers: any[]): void;
    setColumnWidth(width: number): void;
    columnWidth: number;
    setColumnMargin(margin: number): void;
    columnMargin: number;
    aSingleDrugsHasStock: boolean;
    cleanProducts: () => void;
    setUndoTimeout(option: any);
    selectAllProductByDMId(drug_manufacturer_id: number): void;
    moreThanFiveDrugManufacturers: () => boolean;
    disabledButtonMakeOrderByDelete: boolean;
    showModalRecommended: () => boolean;
    setShowModalRecommended: () => void;
    hiddenModalRecommended: boolean;
    setHiddenModalRecommended: (v: boolean) => void;
    recommendations: any;
    setRecommendations: (recommendations: any) => void;
    recommendationsVisible: any;
    setRecommendationsVisible: (recommendations: any) => void;
    setIsFetchRehydrate: (val: boolean) => void;
    isFetchRehydrate: boolean;
    filtersByTerms?: () => any;
    getUnitsSelectedByDrugManufacturerId: (id: Product['drug_manufacturer_id']) => any;
    withoutSearchings?: any;
    setSearchTerms?: any;
    searchTerms?: any;
    setIsCheck?: any;
    isCheck?: any;
    handleClearFilters?: any;
    modalPriceWithOutSelection?: any;
    setOpenModalConfigDistribution?: (value: boolean) => void;
    openModalConfigDistribution?: boolean;
    setOpenModalConfigPriorities?: (value: boolean) => void;
    openModalConfigPriorities?: boolean;
    setShowModalPriceWithOutSelection?: (value: boolean) => void;
    showModalPriceWithOutSelection?: boolean;
    isCreditAvailable?: any;
    getCreditById?: any;
    getAllUncheckedProducts?: () => ProductInterface[];
    manuallySelectedProductIds?: number[];
    setManuallySelectedProductIds?: Dispatch<SetStateAction<number[]>>;
    recommendationLimit: boolean;
    setRecommendationLimit: (value: boolean) => void;
    filters: ItemCheckbox[];
    setFilters: Dispatch<SetStateAction<ItemCheckbox[]>>;
    isThereAnyGenericProductWithZero: () => boolean;
    addNewRecommendation: (item: ProductInterface) => void;
    setOpenModal: (v: boolean) => void;
    openModal: boolean;
    isFilteringByOutofStock: boolean;
    newSetterUnitsByDrugManufacturers: () => void;
    setDisabledButtonMakeOrderByDelete: (v: boolean) => void;
    originalProductGroups: any;
    setOriginalProductGroups: (v: any) => void;
}

const ProductComparisonContext = createContext<Omit<ProductComparisonProps, 'children'>>(null);

export function ProductComparisonProvider({ children, ...props }: ProductComparisonParams) {
    const { isFilteringByOutofStock, setIsFilteringByOutofStock } = useStatesFilters();
    const { units, setUnits } = useUnitsContext();
    const { drugManufacturer, enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();
    const [remainingAttempts, setRemainingAttempts] = useState<number>(props?.remainingAttemptsInitValue);
    const [columnWidth, setColumnWidth] = useState<number>(142);
    const [columnMargin, setColumnMargin] = useState<number>(31);
    const [retryLoading, setRetryLoading] = useState<boolean>(false);
    const [aSingleDrugsHasStock, setASingleDrugsHasStock] = useState<boolean>(false);
    const [drugManufacturersFailedList, setDrugManufacturersFailedList] = useState(() => {
        if (typeof window !== 'undefined') {
            return JSON.parse(localStorage?.getItem('drugManufacturerStatusList') || '[]');
        }
        return props?.drugManufacturerStatusList || [];
    });
    const [undoTimeout, setUndoTimeout] = useState(false);
    const [deleteItemTimeout, setDeleteItemTimeout] = useState(null);
    const [disabledButtonMakeOrderByDelete, setDisabledButtonMakeOrderByDelete] = useState<boolean>(false);
    const [hiddenModalRecommended, setHiddenModalRecommended] = useState<boolean>(false);
    const [manuallySelectedProductIds, setManuallySelectedProductIds] = useState<number[]>([]);
    const [searchTerms, setSearchTerms] = useState('');
    const [isCheck, setIsCheck] = useState({});
    const [filters, setFilters] = useState<ItemCheckbox[]>([]);
    const [originalProductGroups, setOriginalProductGroups] = useState<ProductInterface[]>([]);
    const [withoutSearchings, setWithoutSearchings] = useState(false);
    /* #region new state by recommendation limit in table v3 */
    const [recommendationLimit, setRecommendationLimit] = useState<boolean>(false);
    /* #endregion */
    const [openModal, setOpenModal] = useState(false);

    const { user } = useUser({});
    const EX5937 = user?.EX5937; // ff split purchase

    const [openModalConfigDistribution, setOpenModalConfigDistribution] = useState(false);
    const [openModalConfigPriorities, setOpenModalConfigPriorities] = useState(false);
    const [showModalPriceWithOutSelection, setShowModalPriceWithOutSelection] = useState(false);
    const [recommendations, setRecommendations] = useState<any[]>([]);
    const [recommendationsVisible, setRecommendationsVisible] = useState<any>([]);
    const [isFetchRehydrate, setIsFetchRehydrate] = useState(false);
    const modalPriceWithOutSelection = useRef();
    const { productGroups, products, setProductInfo, setProductGroups, setProducts } = useProductsContext();

    // GET LOCALSTORAGE PARA MOSTRAR MODAL DE RECOMENDDOS AL INICIAR COMP.
    const showModalRecommended = (): boolean => {
        const value = localStorage.getItem('show_recommended_alert');
        if (value === null) return true;
    };

    // SET LOCALSTORAGE PARA MOSTRAR MODAL DE RECOMENDADOS AL INICIAR COMP.
    const setShowModalRecommended = (): void => {
        localStorage.setItem('show_recommended_alert', JSON.stringify(true));
        setHiddenModalRecommended(true);
        return;
    };

    const addNewRecommendation = (item: ProductInterface) => {
        const newArray = [item, ...productGroups]
        setProductGroups(newArray);
        setProductInfo(item);
    };

    const newSetterUnitsByDrugManufacturers = useCallback(() => {
        const selectedUnits = [];
        products.forEach(({ drugManufacturerIdSelected, barcode, products, quantity }) => {
            if (Array.isArray(drugManufacturerIdSelected)) {
                drugManufacturerIdSelected?.forEach((id) => {
                    let quantitySelected = 0;
                    if (products[id]?.quantity_suggested) {
                        quantitySelected = products[id].quantity_suggested;
                    } else {
                        quantitySelected = quantity;
                    }
                    selectedUnits.push({
                        products: [{ barcode: barcode, quantity: quantitySelected }],
                        drugManufacturerId: id,
                    });
                });
            } else {
                selectedUnits.push({
                    products: [{ barcode: barcode, quantity: quantity }],
                    drugManufacturerId: drugManufacturerIdSelected,
                });
            }
        });

        setUnits(selectedUnits);
    }, [products]);

    const getProductSelected = useCallback((): PostProcessRequest[] => {
        if (EX5937) {
            const selectedProduct = [];
            products.forEach((product) => {
                const isArray = Array.isArray(product?.drugManufacturerIdSelected);
                const drugManufacturerId = product?.drugManufacturerIdSelected;
                if (!isArray) {
                    const barcode = product.barcode;
                    const api_product_id = product.products[drugManufacturerId]?.id;
                    const quantity = product?.quantity;
                    const max_quantity = product?.products[drugManufacturerId]?.maxcant;
                    if (!quantity || !api_product_id) {
                        return;
                    }

                    selectedProduct.push({
                        api_product_id,
                        quantity,
                        max_quantity,
                    });
                } else {
                    drugManufacturerId.forEach((id) => {
                        const barcode = product.barcode;
                        const quantity = product?.products[id]?.quantity_suggested || product?.quantity;
                        const max_quantity = product?.products[id]?.maxcant;
                        const api_product_id = product.products[id]?.id;

                        if (!quantity || !api_product_id) {
                            return;
                        }

                        selectedProduct.push({
                            api_product_id,
                            quantity,
                            max_quantity,
                        });
                    });
                }
            });

            return selectedProduct?.filter((product) => product);
        } else {
            return products
                .map((product) => {
                    const drugManufacturerId = product?.drugManufacturerIdSelected;
                    const quantity = product?.quantity;
                    const max_quantity = product?.products[drugManufacturerId]?.maxcant;
                    const api_product_id = product.products[drugManufacturerId]?.id;
                    if (!quantity || !api_product_id) {
                        return;
                    }
                    return {
                        api_product_id,
                        quantity,
                        max_quantity,
                    };
                })
                ?.filter((product) => product);
        }
    }, [products]);

    const getProductQuantity = useCallback(() => {
        if (EX5937) {
            return products?.reduce((count, product) => {
                if (product?.drugManufacturerIdSelected?.length > 0 && product?.quantity > 0) {
                    count++;
                }
                return count;
            }, 0);
        } else {
            return products?.reduce((count, product) => {
                if (product?.drugManufacturerIdSelected && product?.quantity > 0) {
                    count++;
                }
                return count;
            }, 0);
        }
    }, [products, drugManufacturer]);

    const getUnitsSelectedByDrugManufacturerId = useCallback(
        (id: DrugManufacturer['id']) => {
            if (EX5937) {
                return newGetUnitsSelectedByDrugManufacturerId(id);
            }
            // Buscar el objeto con el drugManufacturerId correspondiente
            const drugManufacturer = units?.find((unit) => {
                if (EX5937) {
                    return unit?.drugManufacturerId?.includes(id);
                } else {
                    return unit?.drugManufacturerId === id;
                }
            });

            // Si no se encuentra el fabricante, retornar 0
            if (!drugManufacturer) {
                return 0;
            }

            // Sumar las cantidades de todos los productos del fabricante
            const totalQuantity = drugManufacturer?.products?.reduce(
                (accumulatedQuantity, product) => accumulatedQuantity + product?.quantity,
                0,
            );

            return totalQuantity;
        },
        [products, units],
    );

    const newGetUnitsSelectedByDrugManufacturerId = (id: DrugManufacturer['id']) => {
        if (!Array.isArray(units) || units.length === 0) {
            return 0;
        }

        const drugManufacturersUnits = units.filter((unit) => unit.drugManufacturerId === id);

        if (!drugManufacturersUnits || drugManufacturersUnits.length === 0) {
            return 0;
        }

        const totalQuantity = drugManufacturersUnits.reduce((accumulatedQuantity, unit) => {
            if (!Array.isArray(unit.products)) {
                return accumulatedQuantity;
            }

            const unitTotal = unit.products.reduce(
                (productAccumulatedQuantity, product) => productAccumulatedQuantity + (product?.quantity || 0),
                0,
            );

            return accumulatedQuantity + unitTotal;
        }, 0);

        return totalQuantity;
    };

    const getProductSelectedByDrugManufacturerId = useCallback(
        (id: DrugManufacturer['id']) => {
            return (
                products?.filter(({ drugManufacturerIdSelected }) => {
                    if (EX5937) {
                        if (Array.isArray(drugManufacturerIdSelected)) {
                            return drugManufacturerIdSelected?.includes(id);
                        }
                        return drugManufacturerIdSelected === id;
                    } else {
                        return drugManufacturerIdSelected === id;
                    }
                }) || []
            );
        },
        [products],
    );

    const getSubtotalById = useCallback(
        (id: DrugManufacturer['id']): number => {
            if (EX5937) {
                // este cambio es implementado ya que el amountSelected es el monto selecciónado para un producto,
                // pero esta función necesita retornar el total para esa droguería, amountSelected antes era valido,
                // porque no existia la posibilidad de tener +1 celda seleccionada por producto.
                const subtotalId = getProductSelectedByDrugManufacturerId(id);
                const cellById = subtotalId?.map((item) => item?.products[id]?.total);
                const subtotal = cellById?.reduce((count, value) => (count += value), 0);
                return subtotal;
            } else {
                const subtotalId = getProductSelectedByDrugManufacturerId(id)?.reduce(
                    (count, item) => (count += item?.amountSelected),
                    0,
                );

                return subtotalId;
            }
        },
        [products],
    );

    const getCreditById = useCallback(
        (id: DrugManufacturer['id'], selectedItem) => {
            const limitCreditAvailable = drugManufacturer.find((drug) => {
                return drug.id === id;
            })?.credit_available;
            const totalItemSelected = getProductSelectedByDrugManufacturerId(id).find(
                (item) => selectedItem?.barcode === item?.barcode,
            );
            let subtotal = getProductSelectedByDrugManufacturerId(id)?.reduce((count, item) => {
                if (selectedItem?.barcode === item?.barcode) {
                    count += selectedItem?.amountSelected;
                } else {
                    count += item?.amountSelected;
                }
                return count;
            }, 0);

            if (totalItemSelected === undefined) {
                subtotal += selectedItem?.amountSelected;
            }

            return limitCreditAvailable >= subtotal || !limitCreditAvailable;
        },
        [products, drugManufacturer],
    );

    const getWithStockDrugManfucaturersById = useCallback(
        (id: DrugManufacturer['id']) => products?.filter((product) => !product?.products[id]),
        [products],
    );

    const getAllUncheckedProducts = useCallback((): ProductInterface[] => {
        if (enabledOnOff) {
            const uncheckedProducts = products?.filter(
                ({ areAllItemChecked, drugManufacturerIdSelected, is_product_in_stock }) => {
                    if (EX5937) {
                        if (Array.isArray(drugManufacturerIdSelected)) {
                            const isEnabled = drugManufacturerIdSelected
                                ?.map((d) => {
                                    return isEnabledDrugManufacturer(d);
                                })
                                ?.some((item) => !!item);
                            return areAllItemChecked && !isEnabled && is_product_in_stock;
                        }
                    } else {
                        const isEnabled = isEnabledDrugManufacturer(drugManufacturerIdSelected);
                        return areAllItemChecked && !isEnabled && is_product_in_stock;
                    }
                },
            );

            return uncheckedProducts;
        }
        const uncheckedProducts = products?.filter(({ areAllItemChecked, is_product_in_stock }) => {
            return areAllItemChecked && is_product_in_stock;
        });

        return uncheckedProducts;
    }, [products]);

    const productHasCheckedQuantity = useCallback((): ProductInterface[] => {
        if (enabledOnOff) {
            return products?.filter(
                ({ products, areAllItemChecked, drugManufacturerIdSelected, quantity, isFatherGeneric }) => {
                    const withoutStock = isEmptyObjectAtrribute(products);
                    if (Array.isArray(drugManufacturerIdSelected)) {
                        const isEnabled = EX5937
                            ? drugManufacturerIdSelected
                                  ?.map((d) => {
                                      return isEnabledDrugManufacturer(d);
                                  })
                                  ?.some((item) => !!item)
                            : isEnabledDrugManufacturer(drugManufacturerIdSelected);
                        // add validation for min and max quantity products to exclude them from the list of unchecked products
                        const hasMinMaxProducts = Object.values(products).some((product) => {
                            return product?.mincant > 0 || product?.maxcant > 0;
                        });

                        const isGenericQuantityZero = isFatherGeneric && quantity === 0;
                        if (isGenericQuantityZero) {
                            return false;
                        }

                        return !hasMinMaxProducts && areAllItemChecked && !withoutStock && !isEnabled;
                    }
                },
            );
        }

        return products?.filter(({ products, areAllItemChecked, quantity, isFatherGeneric }) => {
            const withoutStock = isEmptyObjectAtrribute(products);
            // add validation for min and max quantity products to exclude them from the list of unchecked products
            const hasMinMaxChecked = Object.values(products).some((product) => {
                return product?.mincant > 0 || product?.maxcant > 0;
            });
            const isGenericQuantityZero = EX6780
                ? isFatherGeneric
                    ? quantity === 0
                    : false
                : isFatherGeneric && quantity === 0;
            if (isGenericQuantityZero) {
                return false;
            }

            return !hasMinMaxChecked && areAllItemChecked && !withoutStock;
        });
    }, [products]);

    const allProductHasChecked = useCallback((): boolean => productHasCheckedQuantity()?.length === 0, [products]);

    const isCreditAvailable = useCallback(
        (drugManufacturerId): boolean => {
            const drug = drugManufacturer?.find(({ id }) => id === drugManufacturerId);
            return drug?.credit_available > 0 || drug?.credit_available === null;
        },
        [drugManufacturer],
    );

    const cleanProducts = useCallback(() => {
        setProducts([]);
    }, [products]);

    const isAnyDrugManufacturerFailed = useCallback(
        () => drugManufacturersFailedList?.some(({ responseStatus }) => responseStatus === 'error'),
        [drugManufacturersFailedList],
    );

    const handleClearFilters = () => {
        const updatedFilters = filters.map((filter) => {
            return {
                ...filter,
                isChecked: false,
            };
        });
        setFilters(updatedFilters);
        if (searchTerms.length > 0) {
            setSearchTerms('');
        }
    };

    const filtersByTerms = () => {
        if (!!searchTerms && searchTerms.trim().length > 0) {
            const filteredByTerms = productGroups.filter((group) => {
                const filterText = searchTerms.toLowerCase();
                if (!!searchTerms && searchTerms.trim().length > 0) {
                    return (
                        (group?.barcode && group?.barcode.includes(searchTerms)) ||
                        (group?.description && group?.description.toLowerCase().includes(filterText)) ||
                        (group?.laboratory && group?.laboratory.toLowerCase().includes(filterText)) ||
                        (group?.drug && group?.drug.toLowerCase().includes(filterText))
                    );
                }
                return true;
            });

            if (filteredByTerms.length === 0) {
                setWithoutSearchings(true);
            }
            return filteredByTerms;
        } else {
            setWithoutSearchings(false);
            return productGroups;
        }
    };

    const isThereAnyGenericProductWithZero = useCallback(() => {
        const getProductGenerics = products?.some(({ quantity, generics }) => {
            return generics?.length > 0 && quantity === 0;
        });

        return getProductGenerics;
    }, [products]);

    const selectAllProductByDMId = useCallback(
        (drug_manufacturer_id: number) => {
            const productsSelected = products
                ?.map((product, index) => {
                    if (product?.quantity !== 0) {
                        if (EX6785) {
                            const drugLowest = drugManufacturer?.find((d) => +d?.id === product?.lowest[0]);
                            const isVendorActive = !!drugLowest?.vendor_accepted;
                            const drugVendor = drugLowest?.type_id === EDrugManufacturerTypeId.vendor;
                            if (drugVendor && product?.products[drug_manufacturer_id] === null) {
                                // Si la droguería a checkear es vendor + esta activa + tiene stock
                                if (isVendorActive) {
                                    const currentProductSelected =
                                        product.products[drug_manufacturer_id] !== null
                                            ? drug_manufacturer_id
                                            : product.lowest[0];
                                    return {
                                        ...product,
                                        drugManufacturerIdSelected: [currentProductSelected],
                                    };
                                } else {
                                    // Si la droguería a checkear es vendor y no esta activa, mantenemos selección
                                    return { ...product };
                                }
                            } else {
                                const currentProductSelected =
                                    product.products[drug_manufacturer_id] !== null
                                        ? drug_manufacturer_id
                                        : product.lowest[0];
                                return {
                                    ...product,
                                    drugManufacturerIdSelected: [currentProductSelected],
                                };
                            }
                        } else {
                            const currentProductSelected =
                                product.products[drug_manufacturer_id] !== null
                                    ? drug_manufacturer_id
                                    : product.lowest[0];
                            return {
                                ...product,
                                drugManufacturerIdSelected: currentProductSelected,
                            };
                        }
                    }
                })
                .filter((x) => x !== undefined);
            setProducts([...productsSelected]);
        },
        [products, productGroups],
    );

    const moreThanFiveDrugManufacturers = useCallback(() => {
        return drugManufacturer?.length > 5;
    }, [drugManufacturer]);

    useEffect(() => {
        // initialize once and save the original product groups
        if (EX4927 && originalProductGroups.length === 0 && productGroups.length > 0) {
            setOriginalProductGroups(productGroups);
        } else if (!EX4927 && originalProductGroups.length === 0 && productGroups.length > 0) {
            setOriginalProductGroups(productGroups);
        }
    }, [productGroups]);

    useEffect(() => {
        if (filters.length === 0) {
            return;
        }

        const isDiscountChecked = filters.find((filter) => filter.name === 'Con descuento')?.isChecked;
        const isOutOfStockChecked = filters.find((filter) => filter.name === 'Sin stock')?.isChecked;
        const isGenericChecked = filters.find((filter) => filter.name === 'Con genéricos')?.isChecked;

        if (isDiscountChecked || isOutOfStockChecked || isGenericChecked) {
            const filteredProducts = products.filter((product) => {
                let matches = true;

                if (isDiscountChecked) {
                    matches =
                        matches &&
                        Object.values(product.products).some((product) => {
                            return (
                                (product && product.agreement) ||
                                (product?.discounts && product?.discounts?.length > 0) ||
                                (product &&
                                    product.agreement &&
                                    product?.discounts &&
                                    product?.discounts?.length > 0 &&
                                    product?.discount &&
                                    //@ts-ignore
                                    product?.discount?.length > 0)
                            );
                        });
                }
                if (isOutOfStockChecked) {
                    matches = matches && !product.is_product_in_stock;
                }
                if (isGenericChecked) {
                    matches = matches && product?.generics?.length > 0;
                }
                return matches;
            });

            setIsFilteringByOutofStock(isOutOfStockChecked);
            setProductGroups(filteredProducts);
            const checkedCount = filters.filter((filter) => filter.isChecked)?.length;
            setWithoutSearchings(checkedCount > 0 && filteredProducts.length === 0);
        } else if (EX4927) {
            // if you applied any filters and then you remove them, the original product groups are restored
            setIsFilteringByOutofStock(false);
            setWithoutSearchings(false);
            // if add a new recommended product update the product groups and the original product groups state
            if (productGroups?.length > originalProductGroups?.length) {
                setOriginalProductGroups(productGroups);
            } else {
                setProductGroups(originalProductGroups);
            }
        } else if (!EX4927) {
            // if you applied any filters and then you remove them, the original product groups are restored
            setIsFilteringByOutofStock(false);
            setWithoutSearchings(false);
            // if add a new recommended product update the product groups and the original product groups state
            if (productGroups?.length > originalProductGroups?.length) {
                // setProducts(productGroups);
                setOriginalProductGroups(productGroups);
            } else {
                setProductGroups(originalProductGroups);
            }
        }
    }, [filters]);

    useEffect(() => {
        if (!products) {
            return;
        }

        // if (!PERFOMANCE_01) {
        //     const drugManufacturers = drugManufacturer
        //         ?.map(({ id }) => productGroups.map((product) => Boolean(product?.products[id])).some((i) => i))
        //         ?.reduce((accoumulator, currentValue) => {
        //             return currentValue ? accoumulator + 1 : accoumulator;
        //         }, 0);

        //     setASingleDrugsHasStock(!(drugManufacturers > 1 || drugManufacturers === 0));
        // }

        // if (!EX6809) {
        //     setSubtotal(products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0));

        //     setEstimatedSavings({
        //         youAreSaving: getEstimatedSavings('youAreSaving'),
        //         youCanSave: getEstimatedSavings('youCanSave'),
        //     });
        // }

        // if (EX5937) {
        //     newSetterUnitsByDrugManufacturers();
        // }
    }, [products]);

    useEffect(() => {
        if (undoTimeout) {
            clearTimeout(deleteItemTimeout);
            setUndoTimeout(false);
            setDisabledButtonMakeOrderByDelete(false);
        }
    }, [undoTimeout]);

    return (
        <ProductComparisonContext.Provider
            value={{
                getCreditById,
                isCreditAvailable,
                aSingleDrugsHasStock,
                drugManufacturersFailedList,
                setDrugManufacturersFailedList,
                getSubtotalById,
                getProductQuantity,
                productHasCheckedQuantity,
                allProductHasChecked,
                getProductSelected,
                getProductSelectedByDrugManufacturerId,
                getWithStockDrugManfucaturersById,
                retryLoading,
                setRetryLoading,
                remainingAttempts,
                setRemainingAttempts,
                isAnyDrugManufacturerFailed,
                setColumnMargin,
                columnMargin,
                setColumnWidth,
                columnWidth,
                setDisabledButtonMakeOrderByDelete,
                cleanProducts,
                setUndoTimeout,
                selectAllProductByDMId,
                moreThanFiveDrugManufacturers,
                disabledButtonMakeOrderByDelete,
                showModalRecommended,
                setShowModalRecommended,
                hiddenModalRecommended,
                setHiddenModalRecommended,
                addNewRecommendation,
                recommendations,
                setRecommendations,
                recommendationsVisible,
                setRecommendationsVisible,
                setIsFetchRehydrate,
                isFetchRehydrate,
                filtersByTerms,
                getUnitsSelectedByDrugManufacturerId,
                withoutSearchings,
                setSearchTerms,
                searchTerms,
                setIsCheck,
                isCheck,
                handleClearFilters,
                modalPriceWithOutSelection,
                openModalConfigDistribution,
                setOpenModalConfigDistribution,
                openModalConfigPriorities,
                setOpenModalConfigPriorities,
                showModalPriceWithOutSelection,
                setShowModalPriceWithOutSelection,
                getAllUncheckedProducts,
                manuallySelectedProductIds,
                setManuallySelectedProductIds,
                recommendationLimit,
                setRecommendationLimit,
                filters,
                setFilters,
                isThereAnyGenericProductWithZero,
                setOpenModal,
                openModal,
                isFilteringByOutofStock,
                newSetterUnitsByDrugManufacturers,
                originalProductGroups,
                setOriginalProductGroups,
                ...props,
            }}
        >
            {children}
        </ProductComparisonContext.Provider>
    );
}

export function useProductComparison() {
    const context = useContext(ProductComparisonContext);
    if (!context) {
        throw new Error('useProductComparison must be used within a ProducComparisonProvider');
    }

    return context;
}
