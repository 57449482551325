// react
import { memo, useEffect, useRef, useState } from 'react';
// components
import {
    CredentialError,
    CreditError,
    DefaultWithoutError,
    ExpiredPasswordError,
    MissingAccountError,
    UnknownError,
    MissingDispatchAddressError,
    MissingFormatError,
} from '../TableHeaderErrors/';
import { TableHeadDropdownMenu } from '../TableHeadDropdownMenu';

// context
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
// hooks

// utils
import { addEllipsis } from 'utils/strings/ellipsis.utils';
import { handlerWidthColumns, checkErrors } from '../utils';
import { switchMessageErrors } from '../utils/errorMessages';

// styles
import styles from './CellDrugManufacturerHeader.module.css';
import useErrorsObjects from 'utils/hooks/useErrorsObjects/useErrorsObjects';
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';
import { EX5250, EX6381 } from 'config/flags';
import InsufficientCreditWarning from '../TableHeaderWarnings/InsufficientCreditWarning/InsufficientCreditWarning';
import { useUser } from 'utils/hooks';
import { useCDOStatus } from '@commons/modules/TableProductComparisonV2/hooks';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { useDrugManufacturersContext, useFetchingContext, useInsuficientCreditContext } from 'context';
import { cellEvents } from 'utils/cellEvents';
import { useRouter } from 'next/router';

const CellDrugManufacturerHeader = ({ item, idx, parentHeaderRef, fetchProductGroup }) => {
    const { user } = useUser({});
    const EX5506 = user?.EX5506; //FF epic insufficientCredit V2
    const EX6063 = user?.EX6063;
    // ref
    const refCell = useRef(null);
    const EX6017 = user?.EX6017;

    const { CDO_FF, IS_ARG } = useCDOStatus();

    const isVendor = EX6381
        ? !item?.vendor_accepted && item?.type_id === EDrugManufacturerTypeId.vendor
        : EX6017 && item?.type_id === EDrugManufacturerTypeId.vendor;

    // context
    const { extractedDataError, isFetchingUpdate } = useCredentialErrors();

    // const { 
    //     // insufficientCreditIDs, 
    //     loading } = useProductComparison();
    const { loading } = useFetchingContext();

    const { insufficientCreditIDs } = useInsuficientCreditContext();

    const { drugManufacturer, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const isEnabled = isEnabledDrugManufacturer(item.id);

    const [selectAll, setSelectAll] = useState(false);

    const { query } = useRouter();

    const event = cellEvents("rest", item, selectAll, setSelectAll, isEnabled, item.id, query, fetchProductGroup);

    const hasInsufficientCredit = EX5506 && insufficientCreditIDs.some((creditID) => creditID.id === item.id);
    // hook
    const errors = useErrorsObjects(extractedDataError);
    // states
    const [errorsTypes, setErrorsTypes] = useState({
        credentials: false,
        credit: false,
        unknown: false,
        expiredPassword: false,
        missingAccount: false,
        missingDispatchAddress: false,
        missingFormat: false,
    });
    // logic
    const setterWidthInDocument = () => {
        return handlerWidthColumns(
            CDO_FF,
            IS_ARG,
            drugManufacturer,
            `${refCell?.current?.getBoundingClientRect()?.width}px`,
        );
    };
    const messageOfErrors = switchMessageErrors(extractedDataError, extractedDataError?.length);
    const truncateText: any = addEllipsis(item.name);

    // init
    useEffect(() => {
        const handleResize = () => {
            setterWidthInDocument();
        };

        // Llamar a la función inicialmente
        handleResize();

        // Agregar el evento resize
        window.addEventListener('resize', handleResize);

        return () => {
            // Limpiar el evento resize
            window.removeEventListener('resize', handleResize);
        };
    }, [refCell?.current?.getBoundingClientRect()?.width]);
    // END LOGIC WIDTH CDO, CELLS AND FIRST COLUMN

    useEffect(() => {
        const errors = checkErrors(extractedDataError, item.id);

        setErrorsTypes({
            credentials: errors.hasErrorCredential,
            credit: errors.hasErrorCredit,
            unknown: errors.hasUnknownError,
            expiredPassword: errors.hasErrorExpiredPassword,
            missingAccount: errors.hasErrorMissingAccount,
            missingDispatchAddress: errors.hasErrorMissingDispatchAddress,
            missingFormat: errors.hasIncorrectFormatFile,
        });
    }, [extractedDataError, item.id, isFetchingUpdate]);

    const creditError = errors[EErrorCode.CREDIT_LIMIT_REACHED];
    const wrongPassError = errors[EErrorCode.WRONG_USERNAME_PASSWORD];
    const expiredPassError = errors[EErrorCode.EXPIRED_PASSWORD];
    const missingAccountError = errors[EErrorCode.MISSING_ACCOUNT];
    const missingDispatchAddressError = errors[EErrorCode.MISSING_DISPATCH_ADDRESS];
    const missingFormat = errors[EErrorCode.MISSING_FORMAT];

    const disabledButtonWithError =
        !!errorsTypes.credentials ||
        !!errorsTypes.credit ||
        !!errorsTypes.expiredPassword ||
        !!errorsTypes.missingAccount ||
        !!errorsTypes.missingDispatchAddress ||
        !!errorsTypes.missingFormat ||
        !!errorsTypes.unknown ||
        isVendor;

    const isTheLast = idx === drugManufacturer?.length;

    const manufacturerInfo = {
        id: item.id,
        credit: item.credit_available,
        name: item.name,
    };
    
    
    return (
        <div
            className={styles['cell-price']}
            id={`drugManufacturer-${idx}`}
            ref={refCell}
            style={{ backgroundColor: !isEnabled ? '#F5F6FA' : '' }}
        >
            {/* case default */}
            {!errorsTypes.credentials &&
                !errorsTypes.credit &&
                !hasInsufficientCredit &&
                !errorsTypes.unknown &&
                !errorsTypes.expiredPassword &&
                !errorsTypes.missingAccount &&
                !errorsTypes.missingDispatchAddress && !errorsTypes.missingFormat && (
                    <DefaultWithoutError
                        disabled={!truncateText.hasEllipsis}
                        name={truncateText.texts}
                        textTooltip={truncateText.texts}
                        flag="rest"
                        isEnabled={isEnabled}
                        delivery={item?.delivery}
                        offline={item?.offline}
                    />
                )}
            {/* case credential error */}
            {errorsTypes.credentials && (
                <CredentialError
                    truncateText={truncateText}
                    title={
                        !!EX5250
                            ? wrongPassError.tooltip.title
                            : messageOfErrors.wrongUserPass.errorAlertWrongUserPassword.titleTooltip
                    }
                    subtitle={
                        !!EX5250
                            ? wrongPassError.tooltip.subtitle
                            : messageOfErrors?.wrongUserPass?.errorAlertWrongUserPassword?.subtextTooltip
                    }
                    haveErrorCredentials={errorsTypes.credentials}
                    placement={isTheLast ? 'bottomEnd' : 'bottom'}
                />
            )}
            {/* case warning insufficient credit v2 */}
            {hasInsufficientCredit && (
                <InsufficientCreditWarning
                    truncateText={truncateText}
                    manufacturerInfo={manufacturerInfo}
                    placement={isTheLast ? 'bottomEnd' : 'bottomStart'}
                    isEnabled={isEnabled}
                    fetchProductGroup={fetchProductGroup}
                />
            )}
            {/* case credit */}
            {errorsTypes.credit && (
                <CreditError
                    truncateText={truncateText}
                    title={
                        !!EX5250
                            ? creditError?.tooltip?.title
                            : messageOfErrors?.creditLimit?.errorAlertCreditLimit?.titleTooltip
                    }
                    subtitle={
                        !!EX5250
                            ? creditError?.tooltip?.subtitle
                            : messageOfErrors?.creditLimit?.errorAlertCreditLimit?.subtextTooltip
                    }
                    haveErrorCredit={errorsTypes.credit}
                    placement={isTheLast ? 'bottomEnd' : 'bottom'}
                />
            )}
            {/* case expired password */}
            {errorsTypes.expiredPassword && (
                <ExpiredPasswordError
                    truncateText={truncateText}
                    haveExpiredPasswordError={errorsTypes.expiredPassword}
                    title={!!EX5250 && expiredPassError?.tooltip.title}
                    subtitle={!!EX5250 && expiredPassError?.tooltip.subtitle}
                    placement={isTheLast ? 'bottomEnd' : 'bottom'}
                />
            )}
            {/* case missing account */}
            {errorsTypes.missingAccount && (
                <MissingAccountError
                    haveMissingAccountError={errorsTypes.missingAccount}
                    title={!!EX5250 && missingAccountError?.tooltip.title}
                    subtitle={!!EX5250 && missingAccountError?.tooltip.subtitle}
                    truncateText={truncateText}
                    placement={isTheLast ? 'bottomEnd' : 'bottom'}
                />
            )}
            {/* case missing dispatch address */}
            {errorsTypes.missingDispatchAddress && (
                <MissingDispatchAddressError
                    haveMissingDispatchAddress={errorsTypes.missingDispatchAddress}
                    title={!!EX5250 && missingDispatchAddressError?.tooltip.title}
                    subtitle={!!EX5250 && missingDispatchAddressError?.tooltip.subtitle}
                    truncateText={truncateText}
                    placement={isTheLast ? 'bottomEnd' : 'bottom'}
                />
            )}
            {/*case missing format file*/}
            {EX6063 && errorsTypes.missingFormat && (
                <MissingFormatError
                    truncateText={truncateText}
                    title={missingFormat.tooltip.title}
                    subtitle={missingFormat.tooltip.subtitle}
                    haveIncorrectFormat={true}
                    placement={isTheLast ? 'bottomEnd' : 'bottom'}
                />
            )}
            {/* case unknown error */}
            {errorsTypes.unknown && <UnknownError truncateText={truncateText} />}

            <TableHeadDropdownMenu
                isEnabled={isEnabled}
                item={item}
                flag="rest"
                isActive={null}
                isDefault={disabledButtonWithError || !item.has_stock || !!item.last_available}
                parentHeaderRef={parentHeaderRef}
                loading={loading}
                event={event}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                fetchProductGroup={fetchProductGroup}
            />
            {/* dropdown menu */}
        </div>
    );
};

export default memo(CellDrugManufacturerHeader);
