import Image from 'next/image';
// intefaces
import { ISuccessView } from './SuccessView.interface';
// styles
import styles from './SuccessView.module.css';
import animationStyles from '../animations.module.css';

const SuccessView = ({ email }: ISuccessView) => {
    return (
        <div className={`${styles['container']} ${animationStyles['fade-in-right']}`}>
            <Image
                src={'/assets/images/checked_success.svg'}
                priority={true}
                width={98.75}
                height={91.95}
                alt="icon success"
            />
            <div className={styles['title']}>
                <p>¡Registro exitoso!</p>
            </div>
            <div className={styles['footer']}>
                <p>
                    Enviamos un correo a {email} para que actives tu usuario.
                    <br />
                    Revisa tu bandeja de entrada. Si no lo encuentras, revisa la carpeta de spam.
                </p>
            </div>
        </div>
    );
};

export default SuccessView;
