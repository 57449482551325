import { useEffect, useMemo, useState } from 'react';
// CONTEXTS
import {
    useContainerRefContext,
    useDrugManufacturersContext,
    useFetchingContext,
    useModalDiscountsContext,
    useProductsContext,
    useRecommendationsContext,
    useScrollFooterContext,
} from 'context';
import { useProductComparison } from 'context/product-comparison.context';
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
// APIS
import { addRecommended } from 'api';
// UTILS && HOOKS
import { useSkeletonRecommended } from '../../TableProducts/hooks';
import { useCDOStatus } from '@commons/modules/TableProductComparisonV2/hooks';
import { useUser } from 'utils/hooks';
import { DrugManufacturersId } from 'utils/interfaces';
import { recommendedIdxByDrugManufacturerId } from '../../utils';

export const useRecommended = () => {
    const { user } = useUser({});

    /* #region FF */
    const EX5223 = user?.EX5223;
    /* #endregion */

    /* #region hook skeleton by recommended */
    const { skeleton, setSkeleton } = useSkeletonRecommended({ initialState: true });
    /* #endregion */

    //show or not show recommendations
    const { showRecommendations, setShowRecommendations } = useRecommendationsContext();

    const { CDO_IS_VISIBLE } = useCDOStatus();

    const { containerRef } = useContainerRefContext();

    const { recommendedRef, handleRecommendedScroll } = useScrollFooterContext();
    // modal discounts context
    const { setOpenModalDiscounts } = useModalDiscountsContext();
    /* #region states */
    // is prev last recommendations visible
    const [isLastPrevRecommendation, setIsPrevLastRecommendation] = useState<boolean>(false);
    // is last recommendations visible
    const [isLastRecommendation, setIsLastRecommendation] = useState<boolean>(false);
    // click on next recommended
    const [nextRecommendationClicked, setNextRecommendationClicked] = useState<boolean>(false);
    // the fade out animation add product is activated
    const [fadeOutRecommended, setFadeOutRecommended] = useState<boolean>(false);
    // current idx recommendation
    const [currentIndexRecommendation, setCurrentIndexRecommendation] = useState<number>(0);
    //equal time animation in css
    const timeout = 500;
    /* #endregion */

    /* #region contexts */
    const {
        recommendationLimit,
        setRecommendationLimit,
        // drugManufacturer,
        // productGroups,
        // loading,
        // isEnabledDrugManufacturer,
        orderId,
        recommendationsVisible,
        setRecommendationsVisible,
        recommendations,
        withoutSearchings,
        addNewRecommendation
    } = useProductComparison();
    /* #endregion */
    const { productGroups } = useProductsContext();
    const { loading } = useFetchingContext();

    const { drugManufacturer, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const SKELETON = skeleton || loading;

    const DRUGMANUFACTURERS = useMemo(() => drugManufacturer, [drugManufacturer]);

    const WITHOUT_RECOMMENDATIONS = useMemo(() => {
        return !showRecommendations || recommendations?.length === 0;
    }, [showRecommendations, recommendations]);

    const RENDER_TABLE = useMemo(() => {
        return DRUGMANUFACTURERS?.length > 0 && productGroups?.length > 0;
    }, [DRUGMANUFACTURERS, productGroups]);

    const CDO_DRUGMANUFACTURER: any = drugManufacturer?.find(
        (d: any) => d?.id === DrugManufacturersId.CentralDeOfertas,
    );

    /* #region next recommendation */
    const setterNextRecommendationState = (v): void => setNextRecommendationClicked(v);

    const nextRecommendation = (): void => setterNextRecommendationState(true);

    useEffect(() => {
        if (nextRecommendationClicked) handleNextRecommendation();
    }, [nextRecommendationClicked]);

    const handleNextRecommendation = () => {
        // activate skeleton in cells
        activateSkeleton();
        // set localstorage
        handleRemoveRecommendedArray();
        // set recommendation visible
        handleSetRecommendationVisible();
        // fadeout row
        desactivateFadeOut();
    };

    // visible recommendations set
    const handleSetRecommendationVisible = () => {
        if (currentIndexRecommendation < recommendations.length - 1) {
            setRecommendationsVisible([recommendations[currentIndexRecommendation + 1]]);
            if (currentIndexRecommendation === recommendations?.length - 2) {
                // validation last recommendation visible
                setIsLastRecommendation(true);
            } else if (currentIndexRecommendation === recommendations?.length - 3) {
                // validation pre last recommendation visible
                setIsPrevLastRecommendation(true);
            }
            setCurrentIndexRecommendation(currentIndexRecommendation + 1);
        } else {
            setRecommendationLimit(true);
            setTimeout(() => {
                desactivateSkeleton();
            }, timeout);
        }
    };
    /* #endregion */

    /* #region fade out */
    const activateFadeOut = () => {
        setFadeOutRecommended(true);
    };

    const desactivateFadeOut = () => {
        setFadeOutRecommended(false);
    };
    /* #endregion */

    /* #region skeleton */
    const activateSkeleton = () => {
        setSkeleton(true);
    };

    const desactivateSkeleton = () => {
        setSkeleton(false);
    };

    useEffect(() => {
        setTimeout(() => {
            desactivateSkeleton();
            setterNextRecommendationState(false);
        }, timeout);
    }, [currentIndexRecommendation]);
    /* #endregion */

    /* #region remove product recommended in array because not show in ce when add in order */
    const handleRemoveRecommendedArray = () => {
        const getValuesLocalStorage = JSON.parse(localStorage.getItem('recommended_products_viewed'));
        const { order_product_id } = recommendationsVisible[0];
        const newValues =
            getValuesLocalStorage && getValuesLocalStorage.length
                ? [...getValuesLocalStorage, order_product_id]
                : [order_product_id];
        localStorage.setItem('recommended_products_viewed', JSON.stringify(newValues));
    };
    /* #endregion */

    /* #region add recommendation in product-groups */
    const handleAddRecommendation = async (item: any) => {
        // FETCH ADD RECOMMENDATION
        // FADEOUT ROW
        const result = await addRecommended(orderId, item.order_product_id, {
            barcode: item.barcode,
            is_included: true,
        });
        if (result.status === 200) {
            addNewRecommendation(result?.data?.data);
            activateFadeOut();
            setTimeout(() => {
                // next and remove array and add localstorage
                handleNextRecommendation();
            }, 500);
        }
    };
    /* #endregion */

    /* #region credentials errors */
    const { extractedDataError } = useCredentialErrors();

    const extractedDataErrorIds = extractedDataError?.map((item: any) => item.id);

    const hasDrugManufacturerError = (id: number) => {
        return extractedDataErrorIds?.includes(id);
    };
    /* #endregion */

    const lastUnitPrice = (id) => {
        if (EX5223) {
            if (recommendationsVisible?.length > 0 && recommendationsVisible[0]?.pvp) {
                return recommendationsVisible[0]?.pvp;
            } else {
                return recommendedIdxByDrugManufacturerId(recommendations, currentIndexRecommendation, id)?.price;
            }
        } else {
            return recommendedIdxByDrugManufacturerId(recommendations, currentIndexRecommendation, id)?.price;
        }
    };

    const unitPrice = (id) => {
        const { quantityDiscountApplies, discount, price } =
            recommendedIdxByDrugManufacturerId(recommendations, currentIndexRecommendation, id) || {};
        if (quantityDiscountApplies) {
            return discount?.price;
        }
        return price;
    };

    return {
        nextRecommendationClicked,
        fadeOutRecommended,
        SKELETON,
        RENDER_TABLE,
        WITHOUT_RECOMMENDATIONS,
        withoutSearchings,
        isLastRecommendation,
        recommendationsVisible,
        recommendationLimit,
        handleAddRecommendation,
        drugManufacturer,
        containerRef,
        CDO_IS_VISIBLE,
        recommendedRef,
        handleRecommendedScroll,
        showRecommendations,
        recommendations,
        currentIndexRecommendation,
        setShowRecommendations,
        isEnabledDrugManufacturer,
        CDO_DRUGMANUFACTURER,
        setOpenModalDiscounts,
        hasDrugManufacturerError,
        lastUnitPrice,
        unitPrice,
        user,
        nextRecommendation,
    };
};
