import Link from 'next/link';
import { t } from 'i18next';
// interface
import { IHeaderProps } from './Header.interface';
// styles
import styles from './Header.module.css';

const Header = ({ toggleAccordion, item, openSections, index, href, content, hasSomeError }: IHeaderProps) => {
    return (
        <div className={`${styles['accordion-header']}`}>
            <div style={{ gap: item.gap }} onClick={() => toggleAccordion(index)}>
                <img
                    src={'/assets/images/expand_more_new.svg'}
                    alt="dropdown arrow"
                    width={24}
                    height={24}
                    draggable={false}
                    className={`${styles['arrow']} ${!openSections.includes(index) ? styles['up'] : styles['down']}`}
                />
                <p>
                    {item.title} {index === 2 && <span className={styles['quantity']}>({content?.length})</span>}
                </p>
            </div>
            <div className={`${styles['label']} ${index === 2 ? styles['custom-label'] : ''}`}>
                {index === 2 && hasSomeError && (
                    <img
                        src={'/assets/images/error_FILL.svg'}
                        alt="error icon"
                        width={16}
                        height={16}
                        draggable={false}
                    />
                )}
                {index === 1 ? (
                    <Link
                        className={`${styles['view-all']} button__see-all`}
                        href={href}
                        type="link"
                        onClick={() => localStorage.setItem('redirectTo', 'home')}
                    >
                        {t('newWidgetHome.linkViewAll')}
                    </Link>
                ) : (
                    hasSomeError && t('newWidgetHome.review')
                )}
            </div>
        </div>
    );
};

export default Header;
