import React from 'react';
// COMPONENTS
import { Chip } from '../../../../Chip';
import { Badges } from '../../../../Badges';
import { Radio } from '../Radio';
import { Price } from '../Price';
import { CellWrapper } from '../CellWrapper';
// UTILS
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
import { shouldDisplayChip, shouldDisplayPriceDiscount } from '../utils';
import { shouldDisplayBadge } from './utils';
import { PriceDiscount } from '../PriceDiscount';
// STYLES
import cellStyles from '../../../../CellStyles.module.css';

const CellPrice = ({
    product,
    productPVP,
    skeleton,
    chipColor,
    checked,
    onChecked,
    drugManufacturerId,
    isSuggested,
    isLowest,
    applyToleranceAccordingToService,
    drugManufacturerEnabled,
    drugManufacturerName,
    setOpenModalDiscounts,
    payment_due,
    depositName,
    hasDrugManufacturerError,
    bonus,
    multiplierPerAppliedPromo,
    isAppliedIndex,
    user,
    withoutChecks,
    lastUnitPrice,
    unitPrice,
    isGenerics,
    quantity,
    parentGenericProduct,
    isProductGroupSplit = false,
    isCellSplit,
    isDisabledVendors,
    isLowestSuggested
}) => {
    const EX6157 = process.env.NEXT_PUBLIC_EX6157 === 'true';

    return (
        <CellWrapper
            // CDO
            isCdo={false}
            cdo_status_id={null}
            // PRODUCT
            product={product}
            // BACKGROUND COLOR
            isSuggested={isSuggested}
            isLowest={isLowest}
            applyToleranceAccordingToService={applyToleranceAccordingToService}
            checked={checked}
            withoutChecks={withoutChecks}
            isGenerics={isGenerics}
            isProductGroupSplit={isProductGroupSplit}
            isLowestSuggested={isLowestSuggested}
        >
            <div>
                <div className={`${cellStyles['top']}`}>
                    {shouldDisplayBadge({ skeleton, product, hasDrugManufacturerError }) && (
                        <Badges
                            drugManufacturerId={drugManufacturerId}
                            product={product}
                            depositName={!!depositName ? depositName : null}
                            paymentDue={!!payment_due ? payment_due : null}
                            applyToleranceAccordingToService={applyToleranceAccordingToService}
                            drugManufacturerEnabled={drugManufacturerEnabled}
                        />
                    )}
                    <div className={cellStyles['price-section']}>
                        <Radio
                            skeleton={skeleton}
                            hasDrugManufacturerError={hasDrugManufacturerError}
                            product={product}
                            checked={checked}
                            withoutChecks={withoutChecks}
                            isLowest={isLowest}
                            handleClick={(e) => !!product && !checked && onChecked(drugManufacturerId)}
                            quantity={quantity}
                            parentGenericProduct={parentGenericProduct}
                            isDisabledVendors = {isDisabledVendors}
                            EX6157={EX6157}
                        />
                        <Price
                            drugManufacturerEnabled={drugManufacturerEnabled}
                            skeleton={skeleton}
                            hasDrugManufacturerError={hasDrugManufacturerError}
                            product={product}
                            checked={checked}
                            isLowest={isLowest}
                            chipColor={chipColor}
                            userLocale={user?.client?.country_alfa_2}
                            unitPrice={unitPrice}
                        />
                        {shouldDisplayPriceDiscount({ skeleton, product, hasDrugManufacturerError }) && (
                            <PriceDiscount lastUnitPrice={lastUnitPrice} />
                        )}
                    </div>
                </div>
                {shouldDisplayChip({
                    skeleton,
                    product,
                    bonus,
                    hasDrugManufacturerError,
                    isCellSplit: isCellSplit,
                }) && (
                    <Chip
                        chipColor={!drugManufacturerEnabled && !skeleton && !isCellSplit ? CHIP_DISCOUNT_COLOR.DISABLED : chipColor}
                        skeleton={skeleton}
                        product={product}
                        productPVP={productPVP}
                        drugManufacturerName={drugManufacturerName}
                        setOpenModalDiscounts={setOpenModalDiscounts}
                        drugManufacturerId={drugManufacturerId}
                        bonus={bonus}
                        quantityForBonus={product?.quantity}
                        multiplierPerAppliedPromo={multiplierPerAppliedPromo}
                        isAppliedIndex={isAppliedIndex}
                        checked={checked}
                        user={user}
                    />
                )}
            </div>
        </CellWrapper>
    );
};

export default CellPrice;
