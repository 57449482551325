import { EX5057, EX5232 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

export const normalOnboarding = () => {
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    useChangeLanguage(userLocaleCountryCode);

    return [
        {
            title: !!EX5057 ? t('walkthrough.welcomeEfficientPurchase') : 'Bienvenido a la compra inteligente',
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Six'),
            placement: 'center',
            target: 'body',
            continuous: true,
            styles: {
                options: {
                    overlayColor: 'rgba(64, 67, 91, 0.90)',
                },
            },
        },
        {
            title: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Three'),
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Seven'),
            placement: 'left',
            target: '#step-1',
            continuous: true,
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
        {
            title: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Thirteen'),
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Five'),
            placement: 'right',
            target: '#step-2',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
        {
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Fourteen'),
            placement: 'top-center',
            target: '#step-3',
            title: EX5232 ? t('shoppingCart.walkthrough.shoppingCart_walkthrough_Twenty') : 'Recomendaciones para tu punto de venta',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },

        {
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Two'),
            placement: 'right',
            target: '#step-4',
            title: EX5232 ? t('shoppingCart.walkthrough.shoppingCart_walkthrough_TwentyOne') : 'Tu carrito te sigue a donde vayas.',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
        {
            title: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Ten'),
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_One'),
            placement: 'bottom-center',
            target: '#step-5',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
        {
            title: EX5057 ? t('walkthrough.labelAssistClient') : 'Estamos disponibles para asistirte en todo momento',
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Eight'),
            placement: 'right',
            target: '#step-6',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
        {
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Twelve'),
            placement: 'bottom-center',
            target: '#step-7',
            disableOverlayClose: false,
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
    ];
};
