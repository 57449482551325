import { useEffect, useState } from 'react';
// configs
import { EX4529 } from 'config/flags';
// contexts
// import { useProductComparison } from 'context/product-comparison.context';
// apis
import { startProductGroupsJob } from 'api';
// utils
import { Status, useAsync } from 'utils/hooks';
import { useRouter } from 'next/router';
import { useIsManualCheckedContext } from 'context/productComparison/isManualChecked-context';
import { useFetchingContext } from 'context';

export const useProductGroups = () => {
    // context
    // const { orderId, setLoading, isManualChecked, setIsManualChecked } = useProductComparison();
    const { query } = useRouter();
    const { isManualChecked, setIsManualChecked } = useIsManualCheckedContext();
    const { setLoading } = useFetchingContext();
    // hooks
    const { run: runProductGroup, status: statusProductGroup, data: dataProductGroup } = useAsync({});

    const [productGroupsResponse, setProductGroupsResponse] = useState<any[]>([]);
    const [productGroupsFooterResponse, setProductGroupsFooterResponse] = useState<any>({});

    const fetchProductGroup = async () => {
        try {
            setLoading(true);
            if (EX4529 && isManualChecked) setIsManualChecked(false);
            return await runProductGroup(startProductGroupsJob(query.orderId));
        } catch (ex) {
            console.log(ex.message);
        }
    };

    useEffect(() => {
        if (!query.orderId) return;
        fetchProductGroup();
    }, [query.orderId]);

    useEffect(() => {
        if (statusProductGroup === Status.Resolved) {
            const { data } = dataProductGroup;
            setProductGroupsFooterResponse(data?.totals);
            setProductGroupsResponse(data?.data);
        }
    }, [statusProductGroup]);

    return {
        productGroupsResponse,
        productGroupsFooterResponse,
        fetchProductGroup,
        setProductGroupsFooterResponse,
        setProductGroupsResponse,
    };
};

export default useProductGroups;
