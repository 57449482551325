// modules
import { useEffect, useRef, useState } from 'react';
// components
import { FormInput } from '@commons/components';
import { Form } from 'rsuite';

// internal component
import { TypeConnection } from 'utils/enums/InitialConfig';

import {
    formSchema,
    validationSchemaCoopidrogas,
    validationSchemaFarmaMX,
    validationSchemaNadroV2,
    validationSchemaPaymentMethod,
    validationSchemaWithClientIdAndCatalog,
    validationSchemaWithCommonAndClientId,
    validationSchemeAvailableBranchesToDispatchAddress,
    validationSchemeDispatchAddress,
    validationSchemeOnlyWithClientId,
} from './schema/formShemaModel';
// hooks
import { urlWithoutProtocol } from 'utils/formattersUrl';
import { Status, useUser } from 'utils/hooks';
import usePasswordToggle from 'utils/hooks/usePasswordToggle';

// style
import style from './NewFormConnectionV3.module.css';

// api
// utils
import { EX4882, EX5511, EX6405 } from 'config/flags';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { t } from 'i18next';
import {
    drugManufacturersThatNeedClientIdAndCatalog,
    drugManufacturersThatNeedCommonAndClientId,
    drugManufacturersThatOnlyNeedClientId,
    drugManufacturersWithAvailableBranches,
    drugManufacturersWithDispatchAddresses,
    drugManufacturersWithoutUserAndPass,
    drugManufacturersWithPaymentMethod,
} from 'utils/drugManufacturers/specialDrugs';
import { DrugManufacturersId } from 'utils/interfaces';
import { mutateFromCache } from 'utils/swr';
import FormatStatusUploaderV2 from '../FormatStatusUploaderV2/FormatStatusUploaderV2';
import NewFormConnectionActionsV4 from './NewFormConnectionActionsV3/NewFormConnectionActionsV4';
import NewSpecialFormManufacturedV4 from './NewSpecialFormManufacturedV3/NewSpecialFormManufacturedV4';
import { useRouter } from 'next/router';

const NewFormConnectionV4 = ({
    orderByCredentialType,
    drugConnections,
    typeConnectionName,
    mutateClientPos,
    toggleCard,
    modalUser,
}) => {
    let timeoutId;
    const [isLargeViewport, setIsLargeViewport] = useState(false);
    // translation by Country
    const { user, hasAuthorization } = useUser({});
    const router = useRouter();
    const { posId } = router.query;
    const pointOfSaleId = +posId;

    // custom hook
    const { showPassword, togglePassword } = usePasswordToggle();
    const formRef = useRef();

    const [availableBranches, setAvailableBranches] = useState([]);

    const [dispatchAddresses, setDispatchAddresses] = useState([]);

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [disableUserAndPasswordInput, setDisableUserAndPasswordInput] = useState(false);

    const {
        areAllDrugsFromAR,
        addressSelected,
        catalogs,
        clientTypes,
        formValue,
        setFormValue,
        formError,
        setFormError,
        sendValues,
        setCredentialId,
        isEditing,
        setSendValues,
        setIsSaveSuccess,
        setFinishConnection,
        errorMessage,
        setErrorMessage,
        stopLoading,
        handlerFormSubmit,
        drugManufacturerUrl,
        statusApiCreateCredential,
        statusSaveCatalogForDrugmanufacturer,
        statusUpdateCatalogForDrugmanufacturer,
        dataCreateCredential,
        errorCreate,
        clearStatus,
        clearSaveCatalogForDrugmanufacturer,
        clearUpdateCatalogForDrugmanufacturer,
        mutateDrugManufacturers,
        handlerOnCloseModal,
        getDrugInformationById
    } = useModalCredentialsContext();

    useEffect(() => {
        if (sendValues) {
            handlerFormSubmit();
        }
    }, [sendValues]);

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }, []);

    function controlCredential(texto) {
        // Expresión regular para buscar la frase
        let regularExpression = /La credencial ingresada ya ha sido utilizada.*otros puntos de ventas/g;

        // Usar el método test() para verificar si la frase está presente en el texto
        return regularExpression.test(texto);
    }

    const findDrugNameById = (drugId) => {
        const getDataById = orderByCredentialType?.find((elem) => elem.id === drugId);
        return getDataById?.name;
    };

    const statusApiCredential = () => {
        switch (statusApiCreateCredential) {
            case Status.Resolved: {
                mutateFromCache('/api/api-credential');
                mutateDrugManufacturers();
                stopLoading();
                setSendValues(false);

                if (mutateClientPos) {
                    mutateClientPos();
                }

                if (dataCreateCredential?.data?.data?.id) {
                    setCredentialId(dataCreateCredential?.data?.data?.id);
                }

                toggleCard(drugConnections?.id);

                // Si es AR y se subió el archivo, hay que analizar la respuesta de backend
                if (!!formValue?.file && areAllDrugsFromAR) {
                    // Si el archivo se subió pero creó un ticket en Jira significa que no hubo matcheo en los formatos con lo existente en la base de datos
                    if (!!dataCreateCredential?.data?.data?.tickets_jira) {
                        setFinishConnection(true);
                    } else {
                        setIsSaveSuccess(true);
                    }
                } else {
                    setIsSaveSuccess(true);
                }

                setTimeout(() => {
                    handlerOnCloseModal();
                }, 2000);

                setFormError({});
                setErrorMessage({ status: null, msgError: '' });
                clearStatus();
                setFirstInit(2);
                break;
            }

            case Status.Rejected: {
                stopLoading();
                setSendValues(false);

                // status code 400 means invalid credentials from backend
                if (errorCreate?.response?.data?.error?.code === 400) {
                    setFormError({
                        ...formError,
                        username: true,
                        password: true,
                    });
                }
                if (
                    !!EX4882 &&
                    controlCredential(errorCreate?.response?.data?.data?.validation?.username) &&
                    firstInit !== 1
                ) {
                    modalUser.toggle();
                } else {
                    setIsSaveSuccess(false);
                }
                setFirstInit(2);
                setErrorMessage({
                    status: errorCreate?.response?.data?.error?.code,
                    msgError: errorCreate?.response?.data?.data?.validation?.username,
                });
                setDisableUserAndPasswordInput(false);
            }
            default:
                setFirstInit(2);
                null;
                break;
        }
    };

    const statusApiSaveCatalogDrugmanufacturer = () => {
        switch (statusSaveCatalogForDrugmanufacturer) {
            case Status.Resolved: {
                mutateFromCache('/api/api-credential');
                mutateDrugManufacturers();
                stopLoading();
                if (mutateClientPos) {
                    mutateClientPos();
                }
                toggleCard(drugConnections?.id);

                setIsSaveSuccess(true);
                setTimeout(() => {
                    handlerOnCloseModal();
                }, 2000);
                setErrorMessage({ status: null, msgError: '' });
                clearSaveCatalogForDrugmanufacturer();
                break;
            }
            case Status.Rejected: {
                stopLoading();
                setIsSaveSuccess(false);
                setErrorMessage({
                    status: errorCreate?.response?.data?.error?.code,
                    msgError: errorCreate?.response?.data?.data?.validation?.client_identifier[0],
                });
            }
            default:
                null;
                break;
        }
    };

    const statusApiUpdateCatalogDrugmanufacturer = () => {
        switch (statusUpdateCatalogForDrugmanufacturer) {
            case Status.Resolved: {
                mutateFromCache('/api/api-credential');
                mutateDrugManufacturers();
                stopLoading();
                if (mutateClientPos) {
                    mutateClientPos();
                }
                toggleCard(drugConnections?.id);

                setIsSaveSuccess(true);
                setTimeout(() => {
                    handlerOnCloseModal();
                }, 2000);
                setErrorMessage({ status: null, msgError: '' });
                clearUpdateCatalogForDrugmanufacturer();
                break;
            }
            case Status.Rejected: {
                stopLoading();
                setIsSaveSuccess(false);
                setErrorMessage({
                    status: errorCreate?.response?.data?.error?.code,
                    msgError: errorCreate?.response?.data?.data?.validation?.client_identifier[0],
                });
            }
            default:
                null;
                break;
        }
    };

    useEffect(() => {
        statusApiCredential();
    }, [statusApiCreateCredential]);

    useEffect(() => {
        statusApiSaveCatalogDrugmanufacturer();
    }, [statusSaveCatalogForDrugmanufacturer]);

    useEffect(() => {
        statusApiUpdateCatalogDrugmanufacturer();
    }, [statusUpdateCatalogForDrugmanufacturer]);

    const findAddress = orderByCredentialType?.find((credential) => credential.id === drugConnections?.id);

    const getAllAddresses = findAddress?.credential_types[1]?.dispatch_addresses?.find(
        (address) => address?.selected === false,
    );

    const depositsByDrugManufacturer = orderByCredentialType?.find(
        (credential) => credential.id === drugConnections?.id,
    )?.deposits;

    // init
    const [firstInit, setFirstInit] = useState(1);

    const getAddressInUse = (getAllAddresses, addressSelected) => {
        if (getAllAddresses && Array.isArray(getAllAddresses)) {
            return getAllAddresses?.some((address) => {
                const iterateInUse = address?.in_use?.filter((addressInUse) => {
                    return addressInUse?.dispatch_address_id === addressSelected?.id;
                });
                return iterateInUse && iterateInUse.length > 0;
            });
        }
    };

    const getAddressInUsePOSName = (getAllAddresses, addressSelected) => {
        let getPOSName = '';
        if (getAllAddresses && Array.isArray(getAllAddresses)) {
            getAllAddresses?.filter((address) => {
                const iterateInUse = address?.in_use?.filter((addressInUse) => {
                    return addressInUse?.dispatch_address_id === addressSelected?.id;
                });
                if (iterateInUse && iterateInUse.length > 0) {
                    getPOSName = address?.in_use[0]?.point_of_sale_name;
                }
            });
        }
        return getPOSName;
    };

    // validation to avoid username and password fields and only show special fields
    const drugManufacturerWithoutUserAndPass = (): boolean => {
        return drugManufacturersWithoutUserAndPass.includes(drugConnections?.id);
    };

    // validation schema for drug manufacturers
    const validationSchema = () => {
        if (drugManufacturersWithDispatchAddresses?.includes(drugConnections?.id)) {
            return validationSchemeDispatchAddress;
        } else if (drugManufacturersWithAvailableBranches?.includes(drugConnections?.id)) {
            return validationSchemeAvailableBranchesToDispatchAddress;
        } else if (drugManufacturersWithPaymentMethod?.includes(drugConnections?.id)) {
            return validationSchemaPaymentMethod;
        } else if (drugConnections?.id === DrugManufacturersId.FarmaMX) {
            return validationSchemaFarmaMX;
        } else if (drugManufacturersThatOnlyNeedClientId.includes(drugConnections?.id)) {
            return validationSchemeOnlyWithClientId;
        } else if (drugManufacturersThatNeedClientIdAndCatalog.includes(drugConnections?.id)) {
            return validationSchemaWithClientIdAndCatalog;
        } else if (drugManufacturersThatNeedCommonAndClientId.includes(drugConnections?.id)) {
            if (drugConnections?.id === DrugManufacturersId.Nadro) {
                return validationSchemaNadroV2;
            } else if (drugConnections?.id === DrugManufacturersId.Coopidrogas) {
                return validationSchemaCoopidrogas;
            } else {
                return validationSchemaWithCommonAndClientId;
            }
        } else {
            return formSchema;
        }
    };

    let hostnameUrl;
    const validUrl = URL.canParse(drugManufacturerUrl());

    if (validUrl) {
        if (drugConnections?.id === DrugManufacturersId.DrogueriasDelSud) {
            const manufacturerUrl = new URL(
                typeConnectionName?.typeOfConnection === TypeConnection.WEB
                    ? drugManufacturerUrl()
                    : 'https://pedidos.delsud.com.ar',
            );
            hostnameUrl = manufacturerUrl?.hostname?.toLowerCase() || '';
        } else if (drugConnections?.id === DrugManufacturersId.Cofaloza) {
            const manufacturerUrl = new URL(
                typeConnectionName?.typeOfConnection === TypeConnection.WEB_NEW
                    ? drugManufacturerUrl()
                    : 'http://carrito.cofaloza.com.ar/intranet/login.php?class=user',
            );
            hostnameUrl = manufacturerUrl?.hostname?.toLowerCase() || '';
        } else {
            hostnameUrl = drugManufacturerUrl();
        }
    } else {
        hostnameUrl = drugManufacturerUrl();
    }

    const formatedUrl = urlWithoutProtocol(hostnameUrl);

    const isWebConnection =
        typeConnectionName?.typeOfConnection === TypeConnection.WEB ||
        typeConnectionName?.typeOfConnection === TypeConnection.WEB_ACTUAL ||
        typeConnectionName?.typeOfConnection === TypeConnection.WEB_NEW;

    const LABEL_WEB = t('efficientPurchasing.credentialsError.credentialTypeScrapper').toLowerCase();
    const LABEL_NEW_WEB = t('settings.editPointsOfSale.sett_newWebTextPointsOfSale').toLowerCase();

    const handleLabelTypeConnection = (type) => {
        switch (type) {
            case TypeConnection.WEB:
                return LABEL_WEB;
            case TypeConnection.WEB_NEW:
                return LABEL_NEW_WEB;
            case TypeConnection.API:
                return 'Api';
            default:
                return '';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            // small devices have a height less than 768px
            setIsLargeViewport(window.innerHeight > 768);
        };

        // Ejecutar la función al cargar la página y al redimensionar la ventana
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { missing_format } = getDrugInformationById();

    const showUploadFormatStatus: boolean = missing_format && !hasAuthorization;

    const validation = areAllDrugsFromAR && showUploadFormatStatus;

    return (
        <>
            <div className={style['form-title-container']}>
                <span className={style['form-title']}>
                    {t(`${isEditing ? 'credentialplaceholder_seven' : 'credentialplaceholder_four'}`, {
                        typeOfConnection: handleLabelTypeConnection(typeConnectionName?.typeOfConnection),
                    })}
                </span>
                <div className={style['form-subtitle']}>
                    {isWebConnection && (
                        <div className={style['form-web-credential']}>
                            <img src="/assets/images/lockFill.svg" alt="icon-lock" />
                            <span className={style['form-subtitle-text']}>{formatedUrl}</span>
                        </div>
                    )}
                </div>
            </div>

            <Form
                fluid
                ref={formRef}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
                model={validationSchema()}
                className={style['form-container']}
            >
                {errorMessage?.status === 400 && (
                    <div className={style['form-error']}>
                        <p className={style['form-error-text']}>
                            {t('efficientPurchasing.credentialsError.panelErrorUserOrPassword')}
                        </p>
                    </div>
                )}

                <div
                    className={`${
                        isLargeViewport
                            ? style['form-interactive-area-large']
                            : EX6405 && !hasAuthorization
                            ? style['form-interactive-area-client']
                            : style['form-interactive-area-admin']
                    }`}
                >
                    {!drugManufacturerWithoutUserAndPass() && (
                        <>
                            <FormInput
                                type="text"
                                name="username"
                                label={EX5511 ? t('credentialplaceholder_one') : 'Usuario'}
                                autoComplete="nope"
                                isRequired={true}
                                error={formError['username']}
                                disabled={disableUserAndPasswordInput}
                            />
                            <FormInput
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                label={EX5511 ? t('credentialplaceholder_two') : 'Contraseña'}
                                autoComplete="new-password"
                                isRequired={true}
                                onEyeClick={() => togglePassword()}
                                error={formError['password']}
                                disabled={disableUserAndPasswordInput}
                            />
                        </>
                    )}
                    <NewSpecialFormManufacturedV4
                        idDrugManufactered={drugConnections?.id}
                        typeConnectionName={typeConnectionName}
                        selectedAddressInOtherPos={getAddressInUse(getAllAddresses, addressSelected)}
                        pointOfSaleAddressName={getAddressInUsePOSName(getAllAddresses, addressSelected)}
                        catalogs={catalogs}
                        formValue={formValue}
                        formError={formError}
                        depositsByDrugManufacturer={depositsByDrugManufacturer}
                        clientTypes={clientTypes}
                        availableBranches={availableBranches}
                        setAvailableBranches={setAvailableBranches}
                        setDisableUserAndPasswordInput={setDisableUserAndPasswordInput}
                        pointOfSaleId={pointOfSaleId}
                        dispatchAddresses={dispatchAddresses}
                        setDispatchAddresses={setDispatchAddresses}
                        paymentMethods={paymentMethods}
                        setPaymentMethods={setPaymentMethods}
                        setErrorMessage={setErrorMessage}
                        disableUserAndPasswordInput={disableUserAndPasswordInput}
                    />
                    {validation && (
                        <FormatStatusUploaderV2
                            statusApiCreateCredential={statusApiCreateCredential}
                            setFormValue={setFormValue}
                        />
                    )}
                </div>
                <NewFormConnectionActionsV4 />
            </Form>
        </>
    );
};

export default NewFormConnectionV4;
