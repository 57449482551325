import React from 'react';
import styles from './Footer.module.css';
const Footer = () => {
    const _siteMap = [
        {
            label: 'Calcular ahorro',
            href: 'https://extendeal.com/calcular-ahorro',
        },
        {
            label: 'Distribuidores',
            href: 'https://extendeal.com/distribuidores',
        },
        {
            label: 'Empleos',
            href: 'https://www.careers-page.com/extendeal',
        },
        {
            label: 'Blog',
            href: 'https://extendeal.com/blog-extendeal-pharma',
        },
        {
            label: 'Contacto',
            href: 'https://extendeal.com/contacto',
        },
    ];

    const _socialNetwork = [
        {
            src: '/assets/images/linkedin.svg',
            href: 'https://www.linkedin.com/company/extendeal',
            alt: 'linkedin icon',
        },
        {
            src: '/assets/images/insta.svg',
            href: 'https://www.instagram.com/extendealok/',
            alt: 'instagram icon',
        },
        {
            src: '/assets/images/faceb.svg',
            href: 'https://www.facebook.com/extendeal/',
            alt: 'facebook icon',
        },
        {
            src: '/assets/images/yout.svg',
            href: 'https://www.youtube.com/@extendeal',
            alt: 'youtube icon',
        },
    ];

    return (
        <div className={styles.footerParent}>
            <div className={styles.footer}>
                <div className={styles.footerInner}>
                    <div className={styles.frameWrapper}>
                        <div className={styles.frameParent}>
                            <div className={styles.logoMonocromaticoBlanco1Parent}>
                                <img
                                    className={styles.logoMonocromaticoBlanco1Icon}
                                    alt="Logo"
                                    src="/assets/images/Logo-monocromatico-blanco 1.png"
                                />
                                <div className={styles.laPlataformaDe}>
                                    La plataforma de compra inteligente para farmacias
                                </div>
                            </div>
                            <div className={styles.frameGroup}>
                                <div className={styles.frameContainer}>
                                    <div className={styles.mapaDeSitioWrapper}>
                                        <div className={styles.mapaDeSitio}>MAPA DE SITIO</div>
                                    </div>
                                    {_siteMap.map(({ label, href }) => {
                                        return (
                                            <a className={styles.calcularAhorro} href={href}>
                                                {label}
                                            </a>
                                        );
                                    })}
                                </div>
                                <div className={styles.frameDiv}>
                                    <div className={styles.calcularAhorroWrapper}>
                                        <div className={styles.mapaDeSitio}>PLATAFORMA</div>
                                    </div>
                                    <div className={styles.iniciarSesinWrapper}>
                                        <a className={styles.calcularAhorro} href="https://pharma.extendeal.com/login">
                                            Iniciar sesión
                                        </a>
                                    </div>
                                    <div className={styles.frameChild} />
                                </div>
                                <div className={styles.miscelaneasWrapper}>
                                    <div className={styles.miscelaneas}>
                                        <div className={styles.sguenos}>SÍGUENOS</div>
                                        <div className={styles.vectorParent}>
                                            {_socialNetwork.map(({ href, alt, src }) => {
                                                return (
                                                    <a href={href}>
                                                        <img className={styles.vectorIcon} alt={alt} src={src} />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.frameWrapper1}>
                <div className={styles.extendealTodosLosDerechosRWrapper}>
                    <div className={styles.sguenos}>
                        © 2025 Extendeal Todos los derechos reservados. Av. Libertador 4980 Piso 7, CP: 1426, CABA,
                        Argentina. (+5411) 3137-5638
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
