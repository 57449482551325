import { useEffect, useRef, useState } from 'react';
// configs
import { EX6809 } from 'config/flags';
// contexts
import { useFetchingContext, useFooterContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
// utils && hooks
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { EVENT_TYPES } from 'utils/enums/worker/eventTypes';
import { useCalculate } from '../../../hooks';

export const useCanSaving = () => {
    const { footerValues, youAreSavings } = useFooterContext();
    const { loading } = useFetchingContext();
    const { estimatedSavings, products } = useCalculate();

    const [init, setInit] = useState<boolean>(true);
    const [displayValue, setDisplayValue] = useState<string>('0');
    const [displayValueNumber, setDisplayValueNumber] = useState<string>('0');

    const { formatCurrency } = useCurrencyFormatter();
    const { fullValue: footerValuesCanSaving } = formatCurrency({
        value: footerValues?.can_still_save || 0,
        digitsValidation: 7,
    });
    const { fullValue: estimatedSavingsCanSaving } = formatCurrency({
        value: estimatedSavings?.youCanSave || 0,
        digitsValidation: 7,
    });
    const workerRef = useRef(null);

    // Inicializa el worker solo una vez
    useEffect(() => {
        if (EX6809) {
            if (!workerRef.current) {
                workerRef.current = new Worker(new URL('/public/worker-table.js', import.meta.url), { type: 'module' });
            }

            // Escucha la respuesta del worker
            workerRef.current.onmessage = (event) => {
                const { type, result } = event.data;
                if (type === EVENT_TYPES.GET_ESTIMATED_SAVINGS_BY_TYPE) {
                    const { fullValue: estimatedSavingsCanSaving } = formatCurrency({
                        value: result,
                        digitsValidation: 7,
                    });
                    setDisplayValueNumber(result);
                    setDisplayValue(estimatedSavingsCanSaving);
                }
            };

            return () => {
                workerRef.current.terminate();
            };
        }
    }, []);

    const DEPENDENCIES = EX6809 ? [products, init] : [estimatedSavings];

    const sendMessageToWorker = (type, additionalData) => {
        workerRef.current.postMessage({
            type,
            ...additionalData,
        });
    };

    useEffect(() => {
        if (EX6809) {
            if (loading) {
                setInit(true);
            }
        }
    }, [loading]);

    useEffect(() => {
        if (init) {
            setDisplayValue(footerValuesCanSaving);
            setTimeout(() => {
                setInit(false);
            }, 1500);
        } else {
            if (EX6809) {
                if (!init) {
                    // Enviar mensaje al worker
                    sendMessageToWorker(EVENT_TYPES.GET_ESTIMATED_SAVINGS_BY_TYPE, {
                        products,
                        estimatedSavingsType: 'youCanSave',
                    });
                }
            } else {
                setDisplayValue(estimatedSavingsCanSaving);
            }
        }
    }, DEPENDENCIES);

    return {
        displayValue,
        displayValueNumber: displayValueNumber,
        footerValuesCanSaving: footerValues?.are_saving,
        estimatedSavingsCanSaving: estimatedSavings?.youCanSave,
        youAreSavings,
    };
};
