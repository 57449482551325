// react
import { useEffect, useState } from 'react';
// next
import { useRouter } from 'next/router';
import { t } from 'i18next';
// component
import { PanelCredentialCardDrawer, SuccessLabelCredentialCards } from '.';
// rsuite v5
import { Button, ButtonToolbar, Drawer, IconButton } from 'rsuite';
// mui
import CloseIcon from '@mui/icons-material/Close';

// style
import { apiCredentialRetry, saveAddress, startProductGroupsJob, updateApiCredential } from 'api';
import { EX3918, EX4514, EX5057 } from 'config/flags';
import { useFetchingContext, useProductsContext, useSnackbar } from 'context';
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
import { useDrawer } from 'context/DrawerCredentialComparison/DrawerCredentialComparison';
import { useModal } from 'context/modalContext/modalContext';
import { useProductComparison } from 'context/product-comparison.context';
import { credentialTypesInOrder } from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCards.helpers';
import { Status, useAsync, useDrugManufacturersWithoutPagination, useUser } from 'utils/hooks';
import { DrugManufacturersId } from 'utils/interfaces';
import Pusher from 'utils/pusher';
import { mutateFromCache } from 'utils/swr';
import style from './CredentialsDrawer.module.css';
//import { formAttributes } from './utils/formAttributes';

const CredentialsDrawer = ({ onlyTrueSuccess, isSuccessCredential, setIsSuccessCredential }) => {
    // next
    const router = useRouter();
    // context
    const { closeDrawer, isDrawerOpen } = useDrawer();
    const { openModal } = useModal();
    const {
        extractedDataError,
        quantityCredentialsWithErrors,
        setIsFetchingUpdate,
        errors,
        extractedCredentialErrors,
        isFetchingUpdate,
        fetchDrugManufacturers,
        setUpdatedCredentials,
        closeDrawerAndReload,
        setCloseDrawerAndReload,
        mutateDrugManufacturers: mutateDrugManufacturersCE
    } = useCredentialErrors();
    const { run: runUpdateApiCredential, status: statusApiUpdateCredential, error: errorUpdate } = useAsync({});
    const { run: runProductGroup, status: statusProductGroup, data: dataProductGroup } = useAsync({});
    const { run: runSaveAddress, status: statusSaveAddress, error: errorSaveAddress } = useAsync({});
    const { run: runRetry, status: statusRetry, error: errorRetry } = useAsync({});

    const { drugManufacturers, mutateDrugManufacturers } = useDrugManufacturersWithoutPagination(
        extractedCredentialErrors === undefined || extractedCredentialErrors === null || extractedCredentialErrors?.length === 0 ? null : router.query.posId,
        false,
    );
    const { setProductGroups, 
        // setLoading
    } = useProductsContext();
    const { setLoading } = useFetchingContext();

    const snackbar = useSnackbar();
    const { user } = useUser({});
    const EX5990 = user?.EX5990;

    // state
    const [hasMoreOfTwoCredentials, setHasMoreOfTwoCredentials] = useState(false);
    const [isExpanded, setIsExpanded] = useState(null);
    const [firstPanelId, setFirstPanelId] = useState(null);
    const [form, setForm] = useState(null);
    const [persistForm, setPersistForm] = useState({
        username: '',
        password: '',
        apiCredentialId: '',
        id: '',
        nameType: '',
        client_identifier: '',
        function:null
    });
    const [pusherSuccess, setPusherSuccess] = useState([]);
    const [trulyQuantity, setTrulyQuantity] = useState(0);
    const [hasErrors, setHasErrors] = useState({
        id: null,
        bool: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const [selector, setSelector] = useState({
        type: null,
        value: null,
    });
    const [isOk, setIsOk] = useState(false);
    const [hasSpecificField, setHasSpecificField] = useState(false);
    const [step, setStep] = useState(1);
    const [addresses, setAddresses] = useState({});
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [apiCredentialId, setApiCredentialId] = useState(null);
    const [hasAddressesSelected, setHasAddressesSelected] = useState(null);
    const [useAddress, setUseAddress] = useState(false);
    const drugManufacturersWithAddress = [10, 18, 24, 34, 32];
    const itUsesAddress =
        drugManufacturersWithAddress.some((drugId) => drugId === isExpanded) && persistForm.nameType === 'scraper';

    const drugManufacturersWithClientIdentifier = {
        [DrugManufacturersId.CofarmenMendoza]: {
            credentialType: 'scraper',
            name: 'client_identifier',
            placeholder: 'Número de Cliente',
        },
        [DrugManufacturersId.CofarmenSanJuan]: {
            credentialType: 'scraper',
            name: 'client_identifier',
            placeholder: 'Número de Cliente',
        },
        [DrugManufacturersId.DrogueriasDelSud]: {
            credentialType: 'scraper_np',
            placeholder: 'Número de Cliente',
            name: 'client_identifier',
        },
    };

    const itUsesClientIdentifier =
        Object.keys(drugManufacturersWithClientIdentifier)?.some((drugId) => +drugId === isExpanded) &&
        (persistForm.nameType === 'scraper' ||
            (isExpanded === DrugManufacturersId.DrogueriasDelSud && persistForm.nameType === 'scraper_np'));

    useEffect(() => {
        // RESET VARIABLES
        setIsFetchingUpdate(false);
    }, []);

    useEffect(() => {
        if (isFetchingUpdate) {
            // Se tiene que levantar el nuevo alert warning
            if (!closeDrawerAndReload && EX4514) setCloseDrawerAndReload(true);

            const channel = Pusher.subscribe(`order.${router.query.orderId}`);

            channel.bind(`App\\Events\\TaskComplete`, (data) => {
                if (data) {
                    const { drugManufacturer, responseStatus } = data || {};
                    const { id, name } = drugManufacturer || {};

                    // Actualizar el estado pusherSuccess con los nuevos datos
                    setPusherSuccess((prevData) => [...prevData, { id, responseStatus, name }]);

                    fetchProductGroup();
                    // UPDATE STATE
                    setIsFetchingUpdate(false);
                    if (EX4514) {
                        setTimeout(() => {
                            setUpdatedCredentials([]);
                            setCloseDrawerAndReload(false);
                        }, 1500);
                    }
                }
            });

            return () => {
                void channel.unbind_all();
            };
        }
    }, [isFetchingUpdate]);

    useEffect(() => {
        if (quantityCredentialsWithErrors >= 2) {
            setHasMoreOfTwoCredentials(true);
            setFirstPanelId(extractedCredentialErrors[0]?.id || null);
        } else {
            setHasMoreOfTwoCredentials(false);
            setFirstPanelId(null);
        }
    }, [quantityCredentialsWithErrors, extractedCredentialErrors]);

    const handlerUpdateEfficientPurchase = () => {
        setTimeout(() => {
            closeDrawer();
            setIsSuccessCredential({
                credId: [],
                bool: [],
            });
            setIsFetchingUpdate(true);
        }, 1000);
    };

    useEffect(() => {
        if (trulyQuantity > 1 && onlyTrueSuccess > 0 && trulyQuantity === pusherSuccess?.length) {
            handlerUpdateEfficientPurchase();
        }
    }, [trulyQuantity, isSuccessCredential, pusherSuccess]);

    useEffect(() => {
        if (!trulyQuantity) {
            setTrulyQuantity(quantityCredentialsWithErrors);
        }
    }, [quantityCredentialsWithErrors]);

    useEffect(() => {
        switch (statusApiUpdateCredential) {
            case Status.Pending: {
                setIsLoading(true);

                break;
            }
            case Status.Resolved:
                mutateFromCache('/api/api-credential');

                if (!!itUsesAddress) {
                    setStep(2);
                    setIsLoading(false);
                    return;
                }

                setIsSuccessCredential((prev) => {
                    const newCredIds = [...prev.credId, isExpanded];
                    const newBools = [...prev.bool, true];
                    return { credId: newCredIds, bool: newBools };
                });

                setIsExpanded(false);

                if (quantityCredentialsWithErrors === 1) {
                }

                setIsLoading(false);
                break;
            case Status.Rejected:
                setIsLoading(false);
                setIsOk(false);
                setIsSuccessCredential((prev) => ({
                    credId: prev.credId,
                    bool: [...prev.bool, false],
                }));
                setStep(1);
                setHasErrors({
                    id: isExpanded,
                    bool: true,
                });
                break;
        }
    }, [statusApiUpdateCredential]);

    const statusApiSaveAddress = (statusSaveAddress) => {
        switch (statusSaveAddress) {
            case Status.Pending: {
                setIsLoading(true);

                break;
            }
            case Status.Resolved: {
                mutateFromCache('/api/api-credential');
                mutateDrugManufacturers();

                setIsSuccessCredential((prev) => {
                    const newCredIds = [...prev.credId, isExpanded];
                    const newBools = [...prev.bool, true];
                    return { credId: newCredIds, bool: newBools };
                });

                setIsExpanded(false);

                if (quantityCredentialsWithErrors === 1) {
                }
                setIsLoading(false);
                break;
            }
            case Status.Rejected: {
                setIsLoading(false);
            }
            default:
                null;
                break;
        }
    };

    useEffect(() => {
        statusApiSaveAddress(statusSaveAddress);
    }, [statusSaveAddress, pusherSuccess]);

    useEffect(() => {
        setTrulyQuantity(extractedCredentialErrors?.length);
        setStep(1);
    }, []);

    const fetchProductGroup = async () => {
        try {
            return await runProductGroup(startProductGroupsJob(router?.query?.orderId));
        } catch (ex) {
            console.log(ex.message);
        }
    };

    useEffect(() => {
        switch (statusProductGroup) {
            case Status.Pending: {
                setLoading(true);
                break;
            }
            case Status.Resolved: {
                setTimeout(() => {
                    setLoading(false);
                }, 1800);
                const { data } = dataProductGroup;

                setProductGroups(data?.data);

                break;
            }
            case Status.Rejected: {
                snackbar.show({
                    error: true,
                    message: 'Lo sentimos. No se pudo realizar la conexión.',
                });
                break;
            }
        }
    }, [statusProductGroup]);

    const handlerForm = (evt) => {
        const { username, password, client_identifier, funtion } = evt;
        setForm((prevItem) => ({
            ...prevItem,
            username: username || persistForm?.username,
            password: password || persistForm?.password,
            client_identifier: client_identifier || persistForm?.client_identifier,
            function:funtion || persistForm?.function
        }));
    };

    const handlerKeyPress = () => {
        setHasErrors({
            id: null,
            bool: false,
        });
    };

    // Luego de que se actualiza una credencial se ejecuta un retry
    useEffect(() => {
        if (true) {
            // isSuccessCredential.credId es un array de credId que se van cerrando/actualizando

            if (isSuccessCredential.credId.length) {
                if (EX4514) {
                    // ID de la drugManufacturer para sumar al alerta warning de busqueda de precios
                    setUpdatedCredentials((prevState) => [...prevState, persistForm?.id]);
                }

                // RETRY
                runRetry(apiCredentialRetry(router?.query?.orderId, persistForm?.apiCredentialId));
            }
        }
    }, [isSuccessCredential.credId.length]);

    const handlerSubmitForm = async () => {
        setIsLoading(true);
        try {
            if (step === 2 && !!selectedAddress) {
                // primero tiene que guardar el address para ejecutar el retry
                await runSaveAddress(saveAddress(apiCredentialId, selectedAddress));

                // es necesario resetear esto porque sino piensa que piense address luego y ejecuta el set-dispatch-address
                if (true) {
                    handlerOnChangeAddress(null);
                    setStep(1);
                }

                return;
            }

            if (persistForm?.id && step === 1 && isExpanded === persistForm.id) {
                // UPDATE CREDENTIAL
                await runUpdateApiCredential(
                    updateApiCredential(persistForm?.apiCredentialId, {
                        ...form,
                        drug_manufacturer_id: persistForm.id,
                        type_id: selector?.value,
                        confirmed: true,
                    }),
                );
            }

            setIsLoading(false);
        } catch (ex) {
            setIsLoading(false);
            console.log(ex.message);
        }
    };

    // drawer
    const handlerCloseDrawer = () => {
        if (onlyTrueSuccess >= 1) {
            openModal();
            closeDrawer();
            setIsLoading(false);
        } else {
            closeDrawer();
            setIsLoading(false);
        }
        setIsSuccessCredential({
            credId: [],
            bool: [],
        });
    };

    // address
    const hasAddresses = (drugmanufacturers) => {
        const apiCredentialFinded = drugmanufacturers?.filter((elem) => elem.id === isExpanded);
        const findAddress = apiCredentialFinded[0]?.credential_types[1].dispatch_addresses?.find(
            (address) => address?.selected === true,
        );
        const itHasAddressesSelected = apiCredentialFinded[0]?.credential_types[1].dispatch_addresses
            ? findAddress?.dispatch_address?.description
            : '';

        const apiCredentialSelected = apiCredentialFinded[0]?.credential_types[1].api_credential_id;

        const addressMapping = apiCredentialFinded[0]?.credential_types[1].dispatch_addresses?.map((address) => {
            return {
                id: address?.dispatch_address?.id,
                label: address?.dispatch_address?.description,
                api_credential_id: apiCredentialSelected,
            };
        });
        setHasAddressesSelected(itHasAddressesSelected);
        setApiCredentialId(apiCredentialSelected);
        setAddresses(addressMapping);
    };

    useEffect(() => {
        if (step === 1 && drugManufacturers) {
            hasAddresses(drugManufacturers?.data);
        }
        if (step === 2 && drugManufacturers) {
            hasAddresses(drugManufacturers?.data);
        }
    }, [step, drugManufacturers]);

    const handlerOnChangeAddress = (evt) => {
        setSelectedAddress(evt);
    };

    // validación para mostrar boton de actualizar comparativa
    const validation = () => {
        if (true) {
            return isSuccessCredential.credId.length > 0;
        } else {
            return trulyQuantity > 1 && onlyTrueSuccess > 0 && trulyQuantity === onlyTrueSuccess;
        }
    };
    return (
        <Drawer open={isDrawerOpen} onClose={() => false} className={style['container-drawer']}>
            <Drawer.Header className={style['header-drawer']} closeButton={false}></Drawer.Header>
            <Drawer.Body
                className={
                    trulyQuantity > 1 && onlyTrueSuccess > 0 && trulyQuantity === onlyTrueSuccess
                        ? style['container-body-success']
                        : style['container-body']
                }
            >
                <>
                    {trulyQuantity > 1 && onlyTrueSuccess > 0 && trulyQuantity === onlyTrueSuccess ? (
                        <SuccessLabelCredentialCards
                            handlerUpdateEfficientPurchase={() => {
                                handlerUpdateEfficientPurchase();
                            }}
                        />
                    ) : (
                        <>
                            <div className={style['wrapper-body']}>
                                <p className={style['title-body']}>
                                    {EX5057
                                        ? t('efficientPurchasing.credentialsError.updateCredentials')
                                        : 'Actualizar credenciales'}
                                </p>
                                <IconButton
                                    size="sm"
                                    icon={<CloseIcon />}
                                    onClick={() => handlerCloseDrawer()}
                                    className={style['close-button-action']}
                                />
                            </div>
                            <p>
                                {!EX3918 ? (
                                    <p>
                                        Para que todas las droguerías sean parte de la <br />
                                        comparativa, actualizá tus credenciales.
                                    </p>
                                ) : (
                                    <p>
                                        {t(
                                            'efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorFive',
                                        )}
                                    </p>
                                )}
                            </p>
                            {extractedCredentialErrors?.map(({ name, id, credential_types, url }, index) => {
                                const credentialTypes = credentialTypesInOrder(credential_types);

                                return (
                                    <div className={style['container-body-panel']}>
                                        <PanelCredentialCardDrawer
                                            hasMoreOfTwoCredentials={hasMoreOfTwoCredentials}
                                            credentialTypes={credentialTypes}
                                            drugManufacturerName={name}
                                            setIsExpanded={(value) => {
                                                setIsExpanded(value ? id : null);
                                            }}
                                            isExpanded={isExpanded === id}
                                            haveId={id}
                                            defaultExpanded={index === 0 && firstPanelId === id}
                                            eventKey={id}
                                            handlerForm={handlerForm}
                                            handlerSubmitForm={handlerSubmitForm}
                                            isSuccessCredential={isSuccessCredential}
                                            form={form}
                                            setForm={setForm}
                                            selector={selector}
                                            setSelector={setSelector}
                                            hasErrors={hasErrors}
                                            setHasErrors={setHasErrors}
                                            handlerKeyPress={handlerKeyPress}
                                            isLoading={isLoading}
                                            setPersistForm={setPersistForm}
                                            persistForm={persistForm}
                                            extractedDataError={extractedDataError}
                                            extractedCredentialErrors={extractedCredentialErrors}
                                            //formulationAttributes={formulationAttributes}
                                            step={step}
                                            addresses={addresses}
                                            handlerOnChangeAddress={handlerOnChangeAddress}
                                            hasAddressesSelected={hasAddressesSelected}
                                            useAddress={itUsesAddress}
                                            useClientIdentifier={itUsesClientIdentifier}
                                            url={url}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )}
                </>
            </Drawer.Body>

            <div className={style['container-footer']}>
                {quantityCredentialsWithErrors === 1 ? (
                    validation() ? (
                        <ButtonToolbar>
                            <Button
                                className={`button__update-comparison-1 ${style['button-action']}`}
                                onClick={handlerUpdateEfficientPurchase}
                            >
                                <span className="button__update-comparison-1">
                                    {EX5990
                                        ? t('efficientPurchasing.credentialsError.reloadComparative')
                                        : 'Actualizar comparativa'}
                                </span>
                            </Button>
                        </ButtonToolbar>
                    ) : (
                        <ButtonToolbar>
                            <Button
                                className={style['button-omit-action']}
                                onClick={() => {
                                    closeDrawer();
                                    setHasErrors({ id: null, bool: false });
                                }}
                            >
                                Omitir
                            </Button>
                            <Button
                                className={`button__save-credential-2 ${style['button-action']}`}
                                onClick={() => handlerSubmitForm()}
                                type="submit"
                                loading={isLoading}
                            >
                                <span className="button__save-credential-2">
                                    {hasSpecificField && step !== 2
                                        ? 'Continuar'
                                        : EX5057
                                        ? t(
                                              'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFifteen',
                                          )
                                        : 'Guardar'}
                                </span>
                            </Button>
                        </ButtonToolbar>
                    )
                ) : validation() ? (
                    <ButtonToolbar className="button__update-comparison-1">
                        <Button
                            className={`button__update-comparison-1 ${style['button-action']}`}
                            onClick={handlerUpdateEfficientPurchase}
                        >
                            <span className="button__update-comparison-1">
                                {EX5990
                                    ? t('efficientPurchasing.credentialsError.reloadComparative')
                                    : 'Actualizar comparativa'}
                            </span>
                        </Button>
                    </ButtonToolbar>
                ) : null}

                {/* {!!hasMoreOfTwoCredentials ? (
          (trulyQuantity > 1 &&
            onlyTrueSuccess > 0 &&
            trulyQuantity === onlyTrueSuccess) && (
            <ButtonToolbar>
              <Button
                className={style['button-action']}
                onClick={handlerUpdateEfficientPurchase}
              >
                <span className='button__update-comparison-1'>
                  Actualizar comparativa
                </span>
              </Button>
            </ButtonToolbar>
          )
        ) : (
          <ButtonToolbar>
            <Button
              className={style['button-omit-action']}
              onClick={() => {
                closeDrawer();
                setHasErrors({ id: null, bool: false });
              }}
            >
              Omitir
            </Button>
            <Button
              className={style['button-action']}
              onClick={() => handlerSubmitForm()}
              type='submit'
              loading={isLoading}
            >
              <span className='button__save-credential-{droguería}_2'>
                {hasSpecificField && step !== 2 ? 'Continuar' : 'Guardar'}
              </span>
            </Button>
          </ButtonToolbar>
        )} */}
            </div>
        </Drawer>
    );
};

export default CredentialsDrawer;
