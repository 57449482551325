import { useEffect, useRef, useState } from 'react';
// configs
import { EX6551, EX6809 } from 'config/flags';
// contexts
import { useDrugManufacturersContext, useFetchingContext, useFooterContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
// utils && hooks
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { EVENT_TYPES } from 'utils/enums/worker/eventTypes';
import { useCalculate } from '../../../hooks';

export const useAreSaving = () => {
    const { formatCurrency } = useCurrencyFormatter();
    const { footerValues, setYouAreSavings } = useFooterContext();

    const { estimatedSavings, products } = useCalculate();

    const { loading } = useFetchingContext();

    const { drugManufacturer } = useDrugManufacturersContext();
    const workerRef = useRef(null);

    const { fullValue: footerValuesAreSaving, originalValue: originalValueFromBackend } = formatCurrency({
        value: footerValues?.are_saving,
    });

    const { fullValue: estimatedSavingsAreSaving, originalValue: originalValueEstimatedSavings } = formatCurrency({
        value: estimatedSavings?.youAreSaving,
    });

    const [init, setInit] = useState<boolean>(true);
    const [displayValue, setDisplayValue] = useState<string>('0');
    const DEPENDENCIES = EX6809
        ? [footerValuesAreSaving, loading, drugManufacturer, products]
        : EX6551
        ? [footerValuesAreSaving, estimatedSavingsAreSaving, loading, drugManufacturer]
        : [estimatedSavingsAreSaving];

    // Inicializa el worker solo una vez
    useEffect(() => {
        if (EX6809) {
            if (!workerRef.current) {
                workerRef.current = new Worker(new URL('/public/worker-table.js', import.meta.url), { type: 'module' });
            }

            // Escucha la respuesta del worker
            workerRef.current.onmessage = (event) => {
                const { type, result } = event.data;
                if (type === EVENT_TYPES.GET_ESTIMATED_SAVINGS_BY_TYPE) {
                    const { fullValue: estimatedSavingsAreSaving, originalValue: originalValue } = formatCurrency({
                        value: result,
                    });
                    setYouAreSavings(`${originalValue}`);
                    setDisplayValue(estimatedSavingsAreSaving);
                }
            };

            return () => {
                workerRef.current.terminate();
            };
        }
    }, []);

    const sendMessageToWorker = (type, additionalData) => {
        workerRef.current.postMessage({
            type,
            ...additionalData,
        });
    };

    // useEffect(() => {
    //     if(EX6809) {
    //         if(loading) {
    //             setInit(true);
    //         };
    //     }
    // },[loading]);

    useEffect(() => {
        if (EX6551) {
            if (init && !loading && !footerValuesAreSaving?.includes('NAN') && drugManufacturer?.length > 0) {
                setYouAreSavings(`${originalValueFromBackend}`);
                setDisplayValue(footerValuesAreSaving);
                setTimeout(() => {
                    setInit(false);
                }, 1500);
            } else {
                if (!init && !loading && !footerValuesAreSaving?.includes('NAN') && drugManufacturer?.length > 0) {
                    if (EX6809) {
                        setYouAreSavings(`${originalValueEstimatedSavings}`);
                        // Enviar mensaje al worker
                        sendMessageToWorker(EVENT_TYPES.GET_ESTIMATED_SAVINGS_BY_TYPE, {
                            products,
                            estimatedSavingsType: 'youAreSaving',
                        });
                    } else {
                        setYouAreSavings(`${originalValueEstimatedSavings}`);
                        setDisplayValue(estimatedSavingsAreSaving);
                    }
                }
            }
        } else {
            if (init) {
                setDisplayValue(footerValuesAreSaving);
                setInit(false);
            } else {
                setDisplayValue(estimatedSavingsAreSaving);
            }
        }
    }, DEPENDENCIES);

    return {
        displayValue,
    };
};
