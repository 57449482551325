import React from "react";
import Slider from "@material-ui/core/Slider";
import { useStyles } from "./SliderComponent.styles";

export default function SliderComponent({ handleChange, id, percentages }) {
  const classes = useStyles();
  const [percentage, setPercentage] = React.useState<number>(0);

  const handleChangePercentValue = (event: any, value: number | number[]) => {
    setPercentage(value as number);
    handleChange(id, value);
  };

  function valuetext(value: number) {
    return `${value}`;
  }
  React.useEffect(() => {
    setPercentage(
      percentages[id]?.percentage ? percentages[id]?.percentage : 0
    );
  }, [percentages]);
  const marks = Array.from({ length: 11 }, (_, index) => ({ value: index * 10 }));

  return (
    <>
      <div className={classes.container}>
        <Slider
          classes={{
            root: classes.root,
            rail: classes.rail,
            track: classes.track,
            mark: classes.mark,
            markActive: classes.markActive,
            thumb: classes.thumb,
            valueLabel: classes.valueLabel,
          }}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          defaultValue={0}
          value={percentage}
          onChange={handleChangePercentValue}
          aria-labelledby="discrete-slider-small-steps"
          step={1}
          marks={marks}
          min={0}
          max={100}
        />
        <div className={classes.containerValuePercentage}>
          {percentage}
          <div className={classes.iconPercentage}>
            <img
              src="/assets/images/percent.svg"
              width={13.33}
              height={13.33}
            />
          </div>
        </div>
      </div>
    </>
  );
}
