import { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
// configs
import { EX6493 } from 'config/flags';
// contexts
import { useProductComparison } from 'context/product-comparison.context';
// components
import { Placeholder } from 'rsuite';
// utils && hooks
import { hasDrugManufacturerIdSelected } from 'utils/tableComparison/drugManufacturers';
// styles
import styles from './CellProductHeader.module.css';
import { useFetchingContext, useProductsContext } from 'context';

const CellProductHeader = () => {
    // const { productGroups, products, 
    //     // loading
    // } = useProductComparison();
    const { productGroups, products } = useProductsContext();

    const { loading } = useFetchingContext();
    const [quantity, setQuantity] = useState<number>(0);

    useEffect(() => {
        if (products && products.length > 0) {
            const value = EX6493
                ? products.reduce(
                      (count, product) =>
                          product.is_product_in_stock &&
                          hasDrugManufacturerIdSelected(product.drugManufacturerIdSelected)
                              ? count + 1
                              : count,
                      0
                  )
                : 0;
            setQuantity(value);
        }
    }, [products]);

    return (
        <div className={styles['cell']}>
            <p>
                {t('efficientPurchasing.comparative.efficientPurchasing_comparativeTwo')}{' '}
                <span className={styles['label']}>
                    {loading ? (
                            <Placeholder.Paragraph
                                className={styles['skeleton']}
                                active
                                rows={1}
                                rowHeight={16}
                                rowMargin={0}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            />
                        ) : (
                            `(${quantity} de ${productGroups?.length})`
                        )
                    }
                </span>
            </p>
        </div>
    );
};

export default memo(CellProductHeader);
