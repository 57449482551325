import { useEffect, useRef, useState } from 'react';
// configs
import { EX6809 } from 'config/flags';
// contexts
import { useFetchingContext, useFooterContext, useUnitsContext } from 'context';
// utils && hooks
import { useUser } from 'utils/hooks';
import { EVENT_TYPES } from 'utils/enums/worker/eventTypes';
import { useCalculate } from '../../../../hooks';

export const useTotalCell = ({ drugManufacturerId }) => {
    const {
        subtotal,
        getSubtotalById,
        getProductSelectedByDrugManufacturerId,
        getUnitsSelectedByDrugManufacturerId,
        products,
    } = useCalculate();
    const { loading } = useFetchingContext();
    const { units } = useUnitsContext();
    const [init, setIinit] = useState<boolean>(true);
    // ------------ subtotal ---------------
    const { footerValues } = useFooterContext();
    const [displayTotal, setDisplayTotal] = useState<string | number>('0');
    // ------------ total cell ---------------
    const [displayTotalCell, setDisplayTotalCell] = useState<string | number>('0');
    const [displayQuantityCell, setDisplayQuantityCell] = useState<number>(0);
    const [displayProductsCell, setDisplayProductsCell] = useState<number>(0);
    // worker calculate footer cell
    const workerRef = useRef(null);
    const { user } = useUser({});
    const EX5937 = user?.EX5937;

    // first load
    useEffect(() => {
        if (footerValues !== null && footerValues !== undefined) {
            if (init && Object.keys(footerValues)?.length > 0) {
                setDisplayTotal(footerValues?.total);
                setDisplayTotalCell(footerValues?.subtotals?.[drugManufacturerId] ?? 0);
                setDisplayQuantityCell(footerValues?.q_units?.[drugManufacturerId] ?? 0);
                setDisplayProductsCell(footerValues?.q_products?.[drugManufacturerId] ?? 0);
                setTimeout(() => {
                    setIinit(false);
                }, 1500);
            }
        }
    }, [footerValues]);

    useEffect(() => {
        if (loading) {
            setIinit(true);
        }
    }, [loading]);

    // Inicializa el worker solo una vez
    useEffect(() => {
        if (EX6809) {
            if (!workerRef.current) {
                workerRef.current = new Worker(new URL('/public/worker-table.js', import.meta.url), { type: 'module' });
            }

            // Escucha la respuesta del worker
            workerRef.current.onmessage = (event) => {
                const { type, result } = event.data;
                if (type === EVENT_TYPES.GET_SUBTOTAL_BY_ID) {
                    setDisplayTotalCell(result);
                }
                if (type === EVENT_TYPES.GET_UNITS_BY_ID) {
                    setDisplayQuantityCell(result);
                }
                if (type === EVENT_TYPES.GET_PRODUCT_SELECTED_BY_ID) {
                    setDisplayProductsCell(result?.length);
                }
            };

            return () => {
                workerRef.current.terminate();
            };
        }
    }, []);

    const sendMessageToWorker = (type, additionalData) => {
        workerRef.current.postMessage({
            type,
            id: drugManufacturerId,
            EX5937: EX5937,
            ...additionalData,
        });
    };

    const DEPENDENCIES = EX6809 ? [products, init, units] : [subtotal, getSubtotalById(drugManufacturerId)];

    // after first load
    useEffect(() => {
        if (!init) {
            if (EX6809) {
                setDisplayTotal(products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0));
                // Envía los mensajes al worker
                sendMessageToWorker(EVENT_TYPES.GET_SUBTOTAL_BY_ID, { products });
                sendMessageToWorker(EVENT_TYPES.GET_UNITS_BY_ID, { units });
                sendMessageToWorker(EVENT_TYPES.GET_PRODUCT_SELECTED_BY_ID, { products });
            } else {
                setDisplayTotal(subtotal);
                setDisplayTotalCell(getSubtotalById(drugManufacturerId));
                setDisplayQuantityCell(getUnitsSelectedByDrugManufacturerId(drugManufacturerId));
                setDisplayProductsCell(getProductSelectedByDrugManufacturerId(drugManufacturerId)?.length);
            }
        }
    }, DEPENDENCIES);

    return {
        displayTotal,
        displayTotalCell,
        displayQuantityCell,
        displayProductsCell,
    };
};
