import { useEffect, useState } from 'react';
// components
import { Form } from './Form';
import { SuccessView } from '../../SuccessView';

const Content = () => {
    const [step, setStep] = useState<number>(0);
    const [formValues, setFormValues] = useState<any>({});

    useEffect(() => {
        setFormValues({});
        setStep(0);
    }, []);

    return (
        <>
            {step === 0 ? (
                <Form setStep={setStep} formValues={formValues} setFormValues={setFormValues} />
            ) : (
                <SuccessView email={formValues.email} />
            )}
             
        </>
    );
};

export default Content;
