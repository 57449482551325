import React, { useEffect, useRef, useState } from 'react';
// configs
import { EX6809 } from 'config/flags';
// contexts
import { useExpandedFooterContext } from 'context/productComparison/expanded-footer';
import { useProductComparison } from 'context/product-comparison.context';
import { useFetchingContext, useFooterContext } from 'context';
// COMPONENTS
import { ExpandIcon } from './ExpandIcon';
import { SubTotalValue } from './SubTotalValue';
// UTILS && HOOKS
import { EVENT_TYPES } from 'utils/enums/worker/eventTypes';
// INTERFACES
import { TotalCellProps } from './TotalCell.interface';
import { useCalculate } from '../../hooks';

const TotalCell = ({ skeleton, userLocaleCountryCode }: TotalCellProps): JSX.Element => {
    const { expandedFooter, setExpandedFooter } = useExpandedFooterContext();
    const { footerValues } = useFooterContext();
    const { subtotal, products } = useCalculate();
    // const { loading } = useFetchingContext();
    const [init, setInit] = useState<boolean>(true);
    const [displayValue, setDisplayValue] = useState<string | number>('0');
    const workerRef = useRef<Worker | null>(null);

    // Inicializa el worker solo una vez
    useEffect(() => {
        if (EX6809) {
            if (!workerRef.current) {
                workerRef.current = new Worker(new URL('/public/worker-table.js', import.meta.url), { type: 'module' });
            }

            // Escucha la respuesta del worker
            workerRef.current.onmessage = (event) => {
                const { type, result } = event.data;
                if (type === EVENT_TYPES.GET_TOTAL_PURCHASE) {
                    setDisplayValue(result);
                }
            };

            return () => {
                workerRef.current.terminate();
            };
        }
    }, []);

    // useEffect(() => {
    //     if(EX6809) {
    //         if(loading) {
    //             setInit(true);
    //         }
    //     }
    // },[loading]);

    const DEPENDENCIES = EX6809 ? [products, footerValues] : [subtotal];

    const sendMessageToWorker = (type, additionalData) => {
        workerRef.current.postMessage({
            type,
            ...additionalData,
        });
    };

    const updateDisplayValue = () => {
        if (init && footerValues?.total) {
            setDisplayValue(footerValues?.total);
            setTimeout(() => {
                setInit(false);
            }, 1500);
        } else {
            if (EX6809) {
                sendMessageToWorker(EVENT_TYPES.GET_TOTAL_PURCHASE, { products });
            } else {
                setDisplayValue(subtotal);
            }
        }
    };

    useEffect(() => {
        updateDisplayValue();
    }, DEPENDENCIES);

    return (
        <div style={{ display: 'grid', gridColumn: '1 / 2' }}>
            <ExpandIcon skeleton={skeleton} setExpandedFooter={setExpandedFooter} expandedFooter={expandedFooter} />
            <SubTotalValue skeleton={skeleton} subtotal={displayValue} userLocaleCountryCode={userLocaleCountryCode} />
        </div>
    );
};

export default TotalCell;
