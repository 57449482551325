import useSWR from "swr";
import { OrderDelivery, ResponseType } from "common/types";

export function useOrderDeliveries(orderId : string) {
  const { data, error, isValidating } = useSWR<ResponseType<OrderDelivery[]>>(
    `/api/orders/${orderId}/deliveries`
  );

  return {
    orderDeliveries: data,
    isLoading: !error && !data,
    error,
    isValidating: isValidating
  };
}
