import React, { memo } from 'react';
// COMPONENTS
import { TotalCell } from './TotalCell';
import { CellDrugManufacturers } from './CellDrugManufacturers';
// STYLES
import grid from '../../Grid.module.css';
import styles from './TableTotals.module.css';

const TableTotals = ({
    drugManufacturer,
    cdo_is_visible,
    withoutRecommendations,
    skeleton,
    userLocaleCountryCode,
    cdo_is_enabled,
    cdoStatusByPOS,
    isEnabledDrugManufacturer,
    user,
    addMinimunPurchase,
    removeMinimunPurchase,
}) => {
    const EX6144 = user?.EX6144;
    const GRID_ROW_FOOTER = EX6144 ? withoutRecommendations ? 3 : 4 : withoutRecommendations ? 4 : 5;
    return (
        <div className={`${grid['grid-system']} ${styles['footer']}`} style={{ gridRow: GRID_ROW_FOOTER }}>
            {/* left cell */}
            <TotalCell
                skeleton={skeleton}
                userLocaleCountryCode={userLocaleCountryCode}
            />
            {/* drugmanufacturers cell */}
            <CellDrugManufacturers
                drugManufacturer={drugManufacturer}
                userLocaleCountryCode={userLocaleCountryCode}
                skeleton={skeleton}
                drugManufacturerEnabled={isEnabledDrugManufacturer}
                cdo_is_visible={cdo_is_visible}
                cdo_is_enabled={cdo_is_enabled}
                cdo_status_id={cdoStatusByPOS}
                user={user}
                addMinimunPurchase={addMinimunPurchase}
                removeMinimunPurchase={removeMinimunPurchase}
            />
        </div>
    );
};

export default TableTotals;
