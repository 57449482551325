//react
import { useRef } from 'react';
import { t } from 'i18next';
// context
import { useDrawer } from 'context/DrawerCredentialComparison/DrawerCredentialComparison';
// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { RsuitePopover } from '@commons/components/RsuitePopover';
import { CardTypesError } from '../CardTypesError';
// styles
import styles from './MissingFormatError.module.css';
import { useRouter } from 'next/router';

const MissingFormatError = ({ truncateText, title, subtitle, haveIncorrectFormat, placement }) => {
    // context
    const { openDrawer } = useDrawer();
    // ref
    const triggerRef = useRef(null);
    const closeTootip = () => triggerRef?.current?.close();
    const router = useRouter();
    const { clientId, posId } = router.query;

    const openUpdateCredentials = () => {
        closeTootip();
        router.push(`/${clientId}/settings/POS/${posId}/posDetails`);
    };    

    return (
        <div className={styles['container-name']}>
            <div className={styles['container-text-image']}>
                <img src="/assets/images/error_FILL.svg" className={styles['image']} />
                <RsuiteTooltip
                    placement="top"
                    text={truncateText.texts}
                    trigger="hover"
                    disabled={!truncateText.hasEllipsis}
                >
                    <div className={styles['name']}>{truncateText.texts}</div>
                </RsuiteTooltip>
            </div>
            <RsuitePopover
                //open={true} // testing put in true
                ref={triggerRef}
                placement={placement}
                trigger="hover"
                popoverClassName={styles['popover-container']}
                childOne={
                    <CardTypesError
                        title={title}
                        subtitle={subtitle}
                        openModalCredentials={openUpdateCredentials}
                        buttonText={'Subir formato'}
                    />
                }
                disabled={!!haveIncorrectFormat ? false : true}
            >
                <p className={styles['error-text']}>{t('efficientPurchasing.comparative.showError')}</p>
            </RsuitePopover>
        </div>
    );
};

export default MissingFormatError;
