// COMPONENTS
import { GenericsRow } from './GenericsRow';
// utils && hooks
import { useTableRowGeneric } from 'utils/hooks/TableComparativeRowGeneric/useTableRowGeneric';
// STYLES
import grid from '../../../Grid.module.css';
import styles from '../Row.module.css';

const Generics = ({
    drugManufacturer,
    cdo_drugManufacturer,
    idx,
    skeleton,
    removeEvent,
    showChildren,
    setShowChildren,
    generics,
    setOpenModalDiscounts,
    isEnabledDrugManufacturer,
    user,
    isGenerics,
    itemGeneric,
    orderProductNonGenericId,
    getCreditById,
    setProductInfo,
    setGenericsIdsAdd
}): JSX.Element => {
    const itemsGenerics = (generic, id) => {
        return generic.products[id];
    };

    const {
        handleCheckboxOnChange,
        hasDrugManufacturerError,
        productGroup,
        bonus,
        multiplierPerAppliedPromo,
        isAppliedIndex,
        unitPrice,
        lastUnitPrice,
        applyToleranceInDistribution,
        isBestByToleranceInPriorityService,
        productGroupHasServiceByPriority,
        handleOnCheck,
        isChecked,
        areAllItemChecked,
        lowest,
        isSuggested,
        paymentDue,
        uncheckAllProducts,
        setIsManualSelected
    } = useTableRowGeneric({ item: itemGeneric, drugManufacturer, orderProductNonGenericId, getCreditById, setProductInfo });

    return (
        <div
            className={`${grid['grid-system']} 
                ${styles['row-generics-container']} ${styles['fade-in']} ${styles['visible']}`}
            style={{ marginTop: 0, marginBottom: 0 }}
            id={`productIndex-${productGroup?.order_product_id}`}
        >
            {generics && showChildren && productGroup && (
                <GenericsRow
                    idx={idx}
                    itemGeneric={itemGeneric}
                    skeleton={skeleton}
                    productGroup={productGroup}
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    areAllItemChecked={areAllItemChecked}
                    removeEvent={removeEvent}
                    showChildren={showChildren}
                    setShowChildren={setShowChildren}
                    generics={generics}
                    isGenerics={isGenerics}
                    uncheckAllProducts={uncheckAllProducts}
                    cdo_drugManufacturer={cdo_drugManufacturer}
                    styles={styles}
                    grid={grid}
                    drugManufacturer={drugManufacturer}
                    isEnabledDrugManufacturer={isEnabledDrugManufacturer}
                    itemsGenerics={itemsGenerics}
                    hasDrugManufacturerError={hasDrugManufacturerError}
                    isChecked={isChecked}
                    productGroupHasServiceByPriority={productGroupHasServiceByPriority}
                    bonus={bonus}
                    lastUnitPrice={lastUnitPrice}
                    setOpenModalDiscounts={setOpenModalDiscounts}
                    handleOnCheck={handleOnCheck}
                    lowest={lowest}
                    isSuggested={isSuggested}
                    applyToleranceInDistribution={applyToleranceInDistribution}
                    isBestByToleranceInPriorityService={isBestByToleranceInPriorityService}
                    multiplierPerAppliedPromo={multiplierPerAppliedPromo}
                    paymentDue={paymentDue}
                    isAppliedIndex={isAppliedIndex}
                    user={user}
                    unitPrice={unitPrice}  
                    setIsManualSelected={setIsManualSelected}
                    setGenericsIdsAdd={setGenericsIdsAdd}
                />
            )}
        </div>
    );
};

export default Generics;
