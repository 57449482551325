import { EX6723, EX6809 } from 'config/flags';
import { useDrugManufacturersContext, useProductsContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
import { useEffect, useState } from 'react';
import { COUNTRY } from 'utils/country';
import { useUser } from 'utils/hooks';
import { DrugManufacturersId, StatusCdo } from 'utils/interfaces';

export interface EstimatedSavingsProps {
    youCanSave: number;
    youAreSaving: number;
}

export const useCalculate = () => {
    const {
        // products,
        newSetterUnitsByDrugManufacturers,
        getSubtotalById,
        getProductSelectedByDrugManufacturerId,
        getUnitsSelectedByDrugManufacturerId,
    } = useProductComparison();
    const { products } = useProductsContext();

    const { drugManufacturer } = useDrugManufacturersContext();
    const { userLocaleCountryCode, user } = useUser({});
    const EX4562 = user?.EX4562;
    const EX5937 = user?.EX5937; // ff split purchase
    const [subtotal, setSubtotal] = useState<number>(0);
    const [estimatedSavings, setEstimatedSavings] = useState<EstimatedSavingsProps>({
        youAreSaving: 0,
        youCanSave: 0,
    });

    const getDrugManufacturerCdo = () => drugManufacturer.filter((d) => d.id === DrugManufacturersId.CentralDeOfertas);

    const filterCdoInProducts = (product) =>
        Object.fromEntries(
            Object.entries(product?.products).filter(([key, value]) => +key !== DrugManufacturersId.CentralDeOfertas),
        );

    const getTempPrice = (product: any, type = 'youAreSaving') => {
        // EX4562 epic cdo
        if (EX4562) {
            let products;
            // just in case
            if (userLocaleCountryCode === COUNTRY.AR) {
                // Get drugManufacturer cdo
                const offersCenter = getDrugManufacturerCdo();
                // It has cdo and is active and is Argentina
                const isActiveCdo = offersCenter?.length > 0 && offersCenter?.[0]?.cdo_status_id === StatusCdo.ENABLED;
                // object cdo filter
                const productsWithoutCdo = filterCdoInProducts(product);
                // set value by return
                products = isActiveCdo ? product?.products : productsWithoutCdo;
            } else {
                products = product?.products;
            }
            return Object.keys(products)
                .map((key) => {
                    const productSelected = products[key];
                    const subtotal = productSelected?.total;
                    if (subtotal) {
                        return subtotal / product?.quantity;
                    }
                })
                ?.filter((item) => item);
        } else {
            const products = product?.products;

            return Object.keys(products)
                .map((key) => {
                    const productSelected: any = products[key];
                    const subtotal =
                        EX6723 && type === 'youCanSave' && !!productSelected
                            ? productSelected.unit_price_ep * product?.quantity
                            : !!productSelected && !!product?.is_split
                            ? productSelected?.unit_price_ep * product?.quantity
                            : productSelected?.total;

                    if (subtotal) {
                        if (EX6723) {
                            if (!!product.is_split && type === 'youCanSave') {
                                return 0;
                            }
                            if (
                                type === 'youCanSave' &&
                                product?.drugManufacturerIdSelected?.length > 0 &&
                                product?.lowest_without_cdo?.some((id) =>
                                    product?.drugManufacturerIdSelected?.includes(id),
                                )
                            ) {
                                return 0;
                            } else if (product?.quantity > 0) {
                                return subtotal / product?.quantity;
                            }
                        } else {
                            if (product?.quantity > 0) {
                                return subtotal / product?.quantity;
                            }
                        }
                    }
                })
                ?.filter((item) => item);
        }
    };

    const getEstimatedSavings = (type: 'youCanSave' | 'youAreSaving') => {
        return Math.abs(
            products
                .map((product) => {
                    const quantity = product?.quantity;
                    const valueSelected = product?.amountSelected;
                    const tmpPrice = getTempPrice(product, type);
                    let priceRange = 0;

                    if (tmpPrice.length == 0 || valueSelected === 0) {
                        return 0;
                    }
                    if (type == 'youAreSaving') {
                        priceRange = Math.max(...tmpPrice);
                    } else {
                        priceRange = Math.min(...tmpPrice);
                    }
                    return (priceRange - valueSelected / quantity) * quantity;
                })
                ?.reduce((count, currentValue) => count + currentValue, 0) || 0,
        );
    };

    useEffect(() => {
        if (!products) {
            return;
        }

        if (!EX6809) {
            setSubtotal(products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0));

            setEstimatedSavings({
                youAreSaving: getEstimatedSavings('youAreSaving'),
                youCanSave: getEstimatedSavings('youCanSave'),
            });
        }

        if (EX5937) {
            newSetterUnitsByDrugManufacturers();
        }
    }, [products]);

    return {
        products,
        newSetterUnitsByDrugManufacturers,
        getSubtotalById,
        getProductSelectedByDrugManufacturerId,
        getUnitsSelectedByDrugManufacturerId,
        subtotal,
        estimatedSavings
    };
};
