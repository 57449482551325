import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { t } from 'i18next';
import { useRouter } from 'next/router';
// COMPONENTS
import { ButtonSolid } from '@commons/components/Button';
import { Placeholder } from 'rsuite';
// APIS
import { deliveryFill, fill } from 'api';
// CONTEXTS
import { useProductComparison } from 'context/product-comparison.context';
import { NewUseCart, useFooterContext, usePointOfSale, useProductsContext } from 'context';
// UTILS
import { Status, useAsync, useUser } from 'utils/hooks';
import { textButtonMakeOrder } from '../../utils';
import { handleFinishOrder } from './utils';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { COUNTRY } from 'utils/country';
// STYLES
import cellStyles from '../../../CellStyles.module.css';
import styles from '../TableSaving.module.css';
import { useCanSaving } from './hooks';
import { EX6551 } from 'config/flags';

const YouCanSave = ({
    skeleton,
    orderId,
    getProductQuantity,
    getProductSelected,
    minimunPurchase,
    handlerModalMinimunPurchase,
    allProductHasChecked,
    unselectedProductNotificationVisibility,
    uncheckedProductsByDrugNamesMinOrMax,
    handlerModalMinMaxQuantity,
    loadingProduct,
}): JSX.Element => {
    const { user, userLocaleCountryCode } = useUser({});
    const { setShowModalPriceWithOutSelection, setOpenModal } = useProductComparison();
    const { products } = useProductsContext();
    /* #region use async */
    const { run, status } = useAsync({});
    /* #endregion */
    const { displayValue, footerValuesCanSaving, estimatedSavingsCanSaving, youAreSavings, displayValueNumber } = useCanSaving();
    
    /* #region router */
    const { push, query } = useRouter();
    /* #endregion */

    /* #region contexts */
    const { handleResetVisibility } = NewUseCart();
    const { pointOfSale } = usePointOfSale();
    /* #endregion */

    // Estados para controlar la ejecución de fill y cuándo ha finalizado
    const [deliveryFillFinished, setDeliveryFillFinished] = useState(false);

    const handlerSendDeliveryFill = async () => {
        await deliveryFill(orderId);
        setDeliveryFillFinished(true);
    };

    /* #region listener by redirect to confirm after press button */
    useEffect(() => {
        switch (status) {
            case Status.Pending:
                break;
            case Status.Resolved:
                if (!!user?.EX5243 && userLocaleCountryCode === COUNTRY.CO) {
                    handlerSendDeliveryFill();
                } else {
                    push({
                        pathname: `/${query?.clientId}/efficient-purchase/${query?.posId}/confirmation`,
                        query: {
                            orderId,
                            estimatedSavings: EX6551 ? youAreSavings : footerValuesCanSaving,
                        },
                    });
                    break;
                }
        }
    }, [status]);
    /* #endregion */

    useEffect(() => {
        if (!!deliveryFillFinished && !!user?.EX5243) {
            push({
                pathname: `/${query?.clientId}/efficient-purchase/${
                    !!pointOfSale?.id ? pointOfSale?.id : query.posId
                }/confirmation`,
                query: {
                    orderId,
                    estimatedSavings: EX6551 ? youAreSavings : footerValuesCanSaving,
                },
            });
        }
    }, [deliveryFillFinished]);

    const newValidationDisabledButton =
        products?.length === 0 || skeleton || [Status.Pending, Status.Resolved].includes(status) || loadingProduct;

    const disabledButtonMakeOrder = newValidationDisabledButton;

    const validation = displayValueNumber;

    return (
        <div>
            <div
                className={styles['youCanSave']}
                style={{
                    color: validation ? '#0171E6' : '#ACAEC4',
                    fontSize: 14,
                    fontWeight: 600,
                }}
            >
                <p>{t('efficientPurchasing.comparative.labelStillSavings')}</p>
                <p>
                    {skeleton ? (
                        <Placeholder.Paragraph
                            active
                            rows={1}
                            rowHeight={16}
                            rowMargin={0}
                            className={cellStyles['placeholder-price']}
                        />
                    ) : (
                        displayValue
                    )}
                </p>
            </div>
            <div className={styles['box-button']}>
                <ButtonSolid
                    onClick={() => {
                        if(!allProductHasChecked){
                            setShowModalPriceWithOutSelection(true)
                            setOpenModal(true)
                        } else {

                            return handleFinishOrder({
                                fill,
                                orderId,
                                getProductSelected,
                                minimunPurchase,
                                handlerModalMinimunPurchase,
                                handleResetVisibility,
                                getProductQuantity,
                                allProductHasChecked,
                                skeleton,
                                unselectedProductNotificationVisibility,
                                run,
                                uncheckedProductsByDrugNamesMinOrMax,
                                handlerModalMinMaxQuantity,
                            });
                        }
                    }}
                    disabled={disabledButtonMakeOrder}
                    width={'100%'}
                    height={48}
                    icon={''}
                    text={
                        <span className="button__make-order">
                            {textButtonMakeOrder(t)}
                            <Image
                                className={`${styles['arrow']}`}
                                alt="arrow"
                                src={'/assets/images/arrow_forward.svg'}
                                width={24}
                                height={24}
                            />
                        </span>
                    }
                    gtagClass="button__make-order"
                />
            </div>
        </div>
    );
};

export default YouCanSave;
