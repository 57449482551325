import { EX6791 } from 'config/flags';
import { EDrugManufacturerTypeId } from './enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { DrugManufacturersId, StatusCdo } from './interfaces';

export function getCredentialsByDrugManufacturer(id: number, credentials: any) {
    return credentials?.filter((c) => c.id === id);
}

export function haveCredential(credential: any) {
    let res = credential?.filter((c) => c.username !== null);
    return res.length > 0;
}

export function haveCredentialCatalog(credential: any) {
    let res = credential?.filter((c) => c.catalog_id !== null && c.client_identifier !== null);
    return res.length > 0;
}

export function haveCredentialClientIdentifier(credential: any) {
    let res = credential?.filter((c) => c.client_identifier !== null);
    return res.length > 0;
}

export function haveEnabledCdOConnection(drugmanufacturersData: any) {
    let res =
        drugmanufacturersData?.find((drugmanufacturer) => drugmanufacturer?.id === DrugManufacturersId.CentralDeOfertas)
            ?.cdo_status_id === StatusCdo.ENABLED;
    return res;
}

export function isDisabledEfficientPurchase(credentials: any) {
    return hasMinimumRequiredCredentials(credentials) >= 2;
}

export function TotalCredentials(credentials: any) {
    return hasMinimumRequiredCredentials(credentials);
}

export const hasMinimumRequiredCredentials = (credentials: any) => {
    let result = 0;
    credentials?.map((item) => {
        let res = item.credential_types.find((credential) => credential.username !== null);
        if (res || (!!item?.is_offline && item?.offline?.price_request_enabled === true)) {
            result = result + 1;
        }
    });
    return result;
};

export const hasMinimumRequiredCredentialsValidated = (credentials: any) => {
    let res = false;
    res = credentials?.find((item) => item?.format_status?.name === 'VALIDATED');
    if (res) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const hasCredentials = (credential: any) => {
    let res = false;
    EX6791 && credential?.type?.id === EDrugManufacturerTypeId.vendor
        ? credential?.credential_types?.map((item) => {
              if (item.api_credential_id !== null) {
                  res = true;
              }
          })
        : credential.id !== DrugManufacturersId.FarmaciasABC && credential.id !== DrugManufacturersId.AlmacenDeDrogas
        ? credential?.credential_types?.map((item) => {
              if (item.username !== null) {
                  res = true;
              }
          })
        : credential?.credential_types?.map((item) => {
              if (item.client_identifier !== null) {
                  res = true;
              }
          });

    return res;
};

// function to handle if a Drug Manufacturer has authorization to request prices
export const isRequestPriceEnabled = (credential: any) => {
    let res = false;
    credential?.credential_types?.map((item) => {
        if (item.price_request_enabled === true) {
            res = true;
        }
    });

    return res;
};

export const hasAvailableDrugManufacturers = (credentials: any, pointOfSale: any): boolean => {
    if (!!pointOfSale) {
        let credential = credentials?.filter((c: any) => c.id === pointOfSale.id)[0];
        return credential.availableDrugManufacturers >= 2;
    }
};

export const availableDrugManufacturers = (credentials: any, pointOfSale: any): number => {
    if (!!pointOfSale) {
        let credential = credentials?.filter((c: any) => c.id === pointOfSale.id)[0];
        return credential.availableDrugManufacturers;
    }
};
