import React, { useEffect, useState } from 'react';
// COMPONENTS
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// UTILS
import { textHasDiscount } from '../../utils';
// STYLES
import styles from '../DiscountChip.module.css';

const MultipleDiscount = ({
    its_apply,
    is_suiza_tucuman,
    product,
    drugManufacturerId,
    setOpenModalDiscounts,
    drugManufacturerName,
    t,
    isDisabled
}): JSX.Element => {
    const [isTooltipDisabled, setIsTooltipDisabled] = useState<boolean>(true);
    const textId = `${product?.id}${textHasDiscount(its_apply, is_suiza_tucuman, product, drugManufacturerId, t)}`;

    useEffect(() => {
        const checkOverflow = () => {
            const element: any = document.getElementById(textId);
            if (element?.children[0]) {
                setIsTooltipDisabled(element.children[0].offsetWidth >= element.children[0].scrollWidth);
            }
        };

        // Timeout to ensure the DOM is fully updated
        const timeoutId = setTimeout(checkOverflow, 100);

        window.addEventListener('resize', checkOverflow);
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', checkOverflow);
        };
    }, [product, is_suiza_tucuman, drugManufacturerId, its_apply, drugManufacturerName]);

    return (
        <RsuiteTooltip
            placement="bottom"
            trigger="hover"
            text={`${textHasDiscount(its_apply, is_suiza_tucuman, product, drugManufacturerId, t)}`}
            disabled={isTooltipDisabled}
            marginTop={4}
            fontSize={14}
        >
            <p
                id={textId}
                className={`link__view-more-discount ${styles['multiple-discount']}`}
                onClick={() =>
                    setOpenModalDiscounts &&
                    setOpenModalDiscounts({
                        state: true,
                        discounts: product?.discounts,
                        drugManufacturerName: drugManufacturerName,
                    })
                }
            >
                <div className={`link__view-more-discount ${styles['discount-text']}`}>
                    {textHasDiscount(its_apply, is_suiza_tucuman, product, drugManufacturerId, t)}
                </div>
                <img
                    className={`link__view-more-discount ${styles['add_icon']}`}
                    src={`/assets/images/${isDisabled ? 'add_circle_disabled' : 'add_circle'}.svg`}
                    alt="icon add product?.discounts"
                />
            </p>
        </RsuiteTooltip>
    );
};

export default MultipleDiscount;
