import {useEffect } from 'react';
import style from './RenderWithoutRecordsetV2.module.css';
import { ExButton } from '@commons/EXComponentsLibrary';
import Image from 'next/image';
import { useNewSearchProduct } from 'context';
import { t } from 'i18next'
const RenderWithoutRecordsetV2 = ({ onClickButtonByCode }) => {

    const { currentSearch, setCurrentSearch } = useNewSearchProduct();
    
   

    return (
        <div className={style['container']}>
            <div className={style['container-images']}>
                <div className={style['image-vector']}>
                    <img src="/assets/images/codigo_barras.svg" className={style['vector-img']} />
                </div>
               
            </div>
            <div className={style['container-without-search']}>
                <div className={style['without-search-text']}>
                    <p className={style['title-without-searching']}>{t('search.one',{value:`"${currentSearch}"`})}</p>
                    <span className={style['subtitle-without-searching']}>
                        {t('search.two')}
                    </span>
                    <div className={`textlink__search-code ${style['container-button']}`}>
                        <ExButton
                            IconComponent={
                                <Image
                                    src={'/assets/images/outline.svg'}
                                    width={24}
                                    height={24}
                                    alt="icon search"
                                    draggable={false}
                                />
                            }
                            appearance="link"
                            onClick={(evt) => {evt.stopPropagation(), onClickButtonByCode()}}
                            className='textlink__search-code'
                        >
                            {t('search.three')}
                        </ExButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RenderWithoutRecordsetV2;
