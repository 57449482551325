import { OrderDelivery, PaymentMethodPerDelivery, DeliveryTime, ConfirmationDrugManufacturer } from 'common/types';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import useFetchEnabledDrugManufacturers from '@commons/modules/Confirmation/hooks/useFetchEnabledDrugManufacturers';
import { Status, useAsync, useUser } from 'utils/hooks';
import { useOrderDeliveries } from 'utils/hooks/use-order-deliveries';

export interface ConfirmationProviderProps {
    children: React.ReactNode;
    run: any;
    status: Status;
    error?: any;
    orderId: string;
    orderDeliverySendStatus: 'success' | 'warning' | 'error';
    paymentMethodsPerDelivery?: PaymentMethodPerDelivery[];
    setPaymentMethodsPerDelivery?: Dispatch<SetStateAction<PaymentMethodPerDelivery[]>>;
    orderDeliveries?: OrderDelivery[];
    times: DeliveryTime[];
    setTimes: Dispatch<SetStateAction<DeliveryTime[]>>;
    drugManufacturers: ConfirmationDrugManufacturer[];
    isLoadingDeliveries: boolean;
    isValidating: boolean;
    showProgressBar: boolean;
    setShowProgressBar: Dispatch<SetStateAction<boolean>>;
    enabledPusherConnection: boolean;
    setEnabledPusherConnection: Dispatch<SetStateAction<boolean>>;
}

const defaultValues: Omit<ConfirmationProviderProps, 'children'> = {
    drugManufacturers: [],
    orderDeliveries: [],
    orderDeliverySendStatus: 'success',
    orderId: '',
    run: () => {},
    setTimes: () => {},
    status: Status.Idle,
    times: [],
    isLoadingDeliveries: false,
    isValidating: false,
    showProgressBar: false,
    setShowProgressBar: () => {},
    enabledPusherConnection: false,
    setEnabledPusherConnection: () => {},
};

enum OrderDeliveryStatus {
    IN_PROGRESS = 1,
    SENT = 2,
    NOT_SENT = 3,
    MANUAL_SENT = 4,
}

const ConfirmationContext = createContext<Omit<ConfirmationProviderProps, 'children'>>(defaultValues);

export function ConfirmationProvider({ children }: { children: React.ReactNode }) {
    const [paymentMethodsPerDelivery, setPaymentMethodsPerDelivery] = useState<PaymentMethodPerDelivery[]>([]);
    const [times, setTimes] = useState<DeliveryTime[]>([]);
    const { run, status, data: processResult, error } = useAsync({});
    const { query } = useRouter();
    const orderId = (Array.isArray(query.orderId) ? query.orderId.join() : query.orderId) || defaultValues.orderId;
    const { orderDeliveries, isLoading: isLoadingDeliveries, isValidating } = useOrderDeliveries(orderId);
    const [drugManufacturers, setDrugManufacturers] = useState([]);
    const { fetchEnabledDrugManufacturers } = useFetchEnabledDrugManufacturers();
    const { user } = useUser({});
    const EX5863 = user?.EX5863; //FF paralelizar compras
    const [showProgressBar, setShowProgressBar] = useState<boolean>(defaultValues.showProgressBar);
    const [enabledPusherConnection, setEnabledPusherConnection] = useState<boolean>(
        defaultValues.enabledPusherConnection,
    );

    useEffect(() => {
        fetchEnabledDrugManufacturers(orderId, setDrugManufacturers);
    }, [orderId]);

    const [orderDeliverySendStatus, setOrderDeliverySendStatus] = useState<'success' | 'warning' | 'error'>('error');

    const DEPENDENCIES = EX5863
        ? [processResult?.data?.data?.order?.deliveries]
        : [processResult?.data?.data?.deliveries];

    useEffect(() => {
        if (EX5863) {
            const orders: OrderDelivery[] = processResult?.data?.data?.order?.deliveries;
            if (!orders) {
                return;
            }

            const allSent = orders?.every((order) => order?.status?.id === OrderDeliveryStatus.SENT);

            if (allSent) {
                setOrderDeliverySendStatus('success');
            } else {
                setOrderDeliverySendStatus('warning');
            }
        } else {
            const orders: OrderDelivery[] = processResult?.data?.data?.deliveries;
            if (!orders) {
                return;
            }
            const isErrorSent = orders?.every((order) => order?.status?.message === 'error');
            const isPartiallySent = !orders?.every(
                (order) => order?.status?.message === 'ok' && order?.status?.name !== 'manual_sent',
            );

            if (isErrorSent) {
                setOrderDeliverySendStatus('error');
            } else if (isPartiallySent) {
                setOrderDeliverySendStatus('warning');
            } else {
                setOrderDeliverySendStatus('success');
            }
        }
    }, DEPENDENCIES);

    const value: Omit<ConfirmationProviderProps, 'children'> = {
        run,
        status,
        error,
        orderDeliverySendStatus,
        paymentMethodsPerDelivery,
        setPaymentMethodsPerDelivery,
        times,
        setTimes,
        orderDeliveries: orderDeliveries?.data,
        orderId,
        drugManufacturers,
        isLoadingDeliveries,
        isValidating,
        showProgressBar,
        setShowProgressBar,
        enabledPusherConnection,
        setEnabledPusherConnection,
    };

    return <ConfirmationContext.Provider value={value}>{children}</ConfirmationContext.Provider>;
}

export function useConfirmationContext() {
    const context = React.useContext(ConfirmationContext);

    if (context === undefined) {
        throw new Error('useConfirmationContext must be used within a ConfirmationProvider');
    }

    return context;
}
