import { t } from 'i18next';
// configs
import { EX4380, EX5539, EX6213, EX6229, EX6230, EX6243 } from 'config/flags';
// components
import { DragDropWithErrors, LoaderDragDropFile, MessagesDragDropUploader } from '.';
import CloseIcon from '@mui/icons-material/Close';
import { CartHaveProductsAlert } from './MessagesDragDropUploader/CartHaveProductsAlert';
import { Cart } from '@commons/modules';
// rsuite
import { IconButton, Panel, Uploader } from 'rsuite';
// hooks
import { useDragDropUploader } from './hooks';
// utils
import { getBackgroundColor, getBorderColor } from './utils';
import { useUser } from 'utils/hooks';
import { allowedRegex } from 'utils/constants';
// style
import style from './DragDropUploader.module.css';

// Este es el nuevo DragDropUploader usado en la home para lanzar archivo a partir de rediseño home tiene incorporado el carrito
const NewDragDropUploader = ({ showPanel }) => {
    const { user } = useUser({});
    const EX6141 = user?.EX6141;
    const EX6708 = user?.EX6708;
    /* #region component logic hook */
    const {
        visibleDragDrop,
        handleUpload,
        blockerFormat,
        errors,
        dataWithError,
        isFetching,
        formatFile,
        handlerUploadWithErrors,
        isLoadingFileWithErrors,
        isDragging,
        setDisplayModalOnBoarding,
        displayModalOnBoarding,
        files,
        setFiles,
        handleCloseIcon,
        isZetti,
        setBlockerFormat,
        resetErrorsWhenHaveFormatError,
        isOpen,
        quantityCart,
        visibility,
        isExpanded,
        isGestorERP,
    } = useDragDropUploader();
    /* #endregion */

    // este parseo se da porque el context fue declarado como un any[]
    const error: any = Array.isArray(dataWithError) ? {} : dataWithError;

    const validationWithFF = EX6229
        ? error?.hasOwnProperty('invalid_lines') &&
          error?.hasOwnProperty('requested_products') &&
          error.invalid_lines !== error.requested_products
        : error?.hasOwnProperty('invalid_lines') &&
          error?.hasOwnProperty('requested_products') &&
          error.invalid_lines === error.requested_products;

    const blockButton = validationWithFF;

    const validateERP = EX5539 ? !isZetti && !isGestorERP : !isZetti;

    const getStyles = () => {
        if (EX6708 && !validateERP) {
            return { top: 24, padding: `0px 0px 24px ${!showPanel ? 8 : 0}px`, left: 0 };
        } else {
            return { top: 56, padding: '30px 50px 64px' };
        }
    };

    return (
        <>
            {EX6243 ? (
                <div className={style['wrapper']} style={getStyles()}>
                    <div
                        className={style['container-panel-uploader-v2']}
                        style={{
                            // transition effect
                            transition: 'opacity 0.18s ease, visibility 0.18s ease',
                            opacity: visibleDragDrop ? 1 : 0,
                            visibility: visibleDragDrop ? 'visible' : 'hidden',
                            width: visibility ? (isExpanded ? '52vw' : '64vw') : '100%',
                        }}
                    >
                        <Panel
                            style={{
                                cursor: 'pointer',
                                borderRadius: 8,
                                border: '1px dashed var(--primary, #0171E6)',
                                borderColor: getBorderColor(isFetching, blockerFormat, blockButton, errors),
                            }}
                        >
                            <div className={style['cancel-button']}>
                                {!isZetti && (
                                    <IconButton
                                        size="xs"
                                        className={style['icon-button-close']}
                                        icon={<CloseIcon />}
                                        onClick={handleCloseIcon}
                                        disabled={isFetching}
                                    />
                                )}
                            </div>
                            <Uploader
                                name="file"
                                accept={!!EX4380 ? 'text/plain/*' : '.res, .fal_, .fal, .xls, .xlsx, .txt, text/plain/'}
                                multiple={false}
                                autoUpload={true}
                                fileListVisible={false}
                                action=""
                                draggable
                                onUpload={(file) => {
                                    if (EX6230) {
                                        if (allowedRegex.test(file.blobFile.type)) {
                                            handleUpload(file.blobFile), setFiles(file);
                                        } else {
                                            resetErrorsWhenHaveFormatError();
                                            setBlockerFormat(true);
                                        }
                                    } else {
                                        handleUpload(file.blobFile), setFiles(file);
                                    }
                                }}
                                disabled={isFetching}
                                className='drag-and-drop__file-comparative'
                            >
                                <div
                                    style={{
                                        border: 'none',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: getBackgroundColor(
                                            isFetching,
                                            blockerFormat,
                                            blockButton,
                                            isDragging,
                                            errors,
                                        ),
                                    }}
                                >
                                    {isFetching ? (
                                        <LoaderDragDropFile filename={formatFile} />
                                    ) : blockerFormat ? (
                                        <MessagesDragDropUploader
                                            text={t('shoppingCart.shoppingCart_TwentyFive')}
                                            subtitle={t('uploadFile.labelErrorExtensionAllowed')}
                                            src="/assets/images/cancel.svg"
                                            blockerFormat={blockerFormat}
                                            flag={false}
                                        />
                                    ) : errors ? (
                                        <DragDropWithErrors
                                            isLoadingFileWithErrors={isLoadingFileWithErrors}
                                            errors={dataWithError}
                                            onHandlerClick={(evt: any) => {
                                                evt.stopPropagation(), handlerUploadWithErrors(files.blobFile);
                                            }}
                                        />
                                    ) : isDragging ? (
                                        <>
                                            {displayModalOnBoarding &&
                                                setDisplayModalOnBoarding &&
                                                setDisplayModalOnBoarding(false)}
                                            <MessagesDragDropUploader
                                                text={t(
                                                    'efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyTwo',
                                                )}
                                                blockerFormat={blockerFormat}
                                                flag={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {displayModalOnBoarding &&
                                                setDisplayModalOnBoarding &&
                                                setDisplayModalOnBoarding(false)}
                                            {EX6141 && quantityCart() ? <CartHaveProductsAlert /> : null}
                                            <MessagesDragDropUploader
                                                text={t('shoppingCart.shoppingCart_TwentySix')}
                                                src="/assets/images/cloud2.svg"
                                                blockerFormat={blockerFormat}
                                                flag={true}
                                                EX6141={EX6141}
                                            />
                                        </>
                                    )}
                                </div>
                            </Uploader>
                        </Panel>
                    </div>
                    {visibleDragDrop && visibility && (
                        <div
                            className={style['container-cart']}
                            style={{
                                zIndex: isOpen ? 1100 : 0,
                                width: visibility ? (isExpanded ? '38vw' : '26vw') : 0,
                            }}
                        >
                            <Cart />
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className={style['container-panel-uploader']}
                    style={{
                        cursor: 'pointer',
                        borderRadius: 8,
                        border: '1px dashed var(--primary, #0171E6)',
                        borderColor: getBorderColor(isFetching, blockerFormat, blockButton, errors),
                        // transition effect
                        transition: 'opacity 0.18s ease, visibility 0.18s ease',
                        opacity: visibleDragDrop ? 1 : 0,
                        visibility: visibleDragDrop ? 'visible' : 'hidden',
                        width: EX6213 ? (visibility ? (isExpanded ? '50vw' : '60vw') : 'auto') : 'auto',
                        top: EX6213 ? 88 : 80,
                    }}
                    // onDragLeave={handleDragLeave}
                >
                    <Panel>
                        <div className={style['cancel-button']}>
                            {!isZetti && (
                                <IconButton
                                    size="xs"
                                    className={style['icon-button-close']}
                                    icon={<CloseIcon />}
                                    onClick={handleCloseIcon}
                                    disabled={isFetching}
                                />
                            )}
                        </div>
                        <Uploader
                            name="file"
                            accept={!!EX4380 ? 'text/plain/*' : '.res, .fal_, .fal, .xls, .xlsx, .txt, text/plain/'}
                            multiple={false}
                            autoUpload={true}
                            fileListVisible={false}
                            action=""
                            draggable
                            onUpload={(file) => {
                                if (EX6230) {
                                    if (allowedRegex.test(file.blobFile.type)) {
                                        handleUpload(file.blobFile), setFiles(file);
                                    } else {
                                        resetErrorsWhenHaveFormatError();
                                        setBlockerFormat(true);
                                    }
                                } else {
                                    handleUpload(file.blobFile), setFiles(file);
                                }
                            }}
                            disabled={isFetching}
                        >
                            <div
                                style={{
                                    border: 'none',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: getBackgroundColor(
                                        isFetching,
                                        blockerFormat,
                                        blockButton,
                                        isDragging,
                                        errors,
                                    ),
                                }}
                            >
                                {isFetching ? (
                                    <LoaderDragDropFile filename={formatFile} />
                                ) : blockerFormat ? (
                                    <MessagesDragDropUploader
                                        text={t('shoppingCart.shoppingCart_TwentyFive')}
                                        subtitle={t('uploadFile.labelErrorExtensionAllowed')}
                                        src="/assets/images/cancel.svg"
                                        blockerFormat={blockerFormat}
                                        flag={false}
                                    />
                                ) : errors ? (
                                    <DragDropWithErrors
                                        isLoadingFileWithErrors={isLoadingFileWithErrors}
                                        errors={dataWithError}
                                        onHandlerClick={(evt: any) => {
                                            evt.stopPropagation(), handlerUploadWithErrors(files.blobFile);
                                        }}
                                    />
                                ) : isDragging ? (
                                    <>
                                        {displayModalOnBoarding &&
                                            setDisplayModalOnBoarding &&
                                            setDisplayModalOnBoarding(false)}
                                        <MessagesDragDropUploader
                                            text={t(
                                                'efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyTwo',
                                            )}
                                            blockerFormat={blockerFormat}
                                            flag={true}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {displayModalOnBoarding &&
                                            setDisplayModalOnBoarding &&
                                            setDisplayModalOnBoarding(false)}
                                        {EX6141 && quantityCart() ? <CartHaveProductsAlert /> : null}
                                        <MessagesDragDropUploader
                                            text={t('shoppingCart.shoppingCart_TwentySix')}
                                            src="/assets/images/cloud2.svg"
                                            blockerFormat={blockerFormat}
                                            flag={true}
                                            EX6141={EX6141}
                                        />
                                    </>
                                )}
                            </div>
                        </Uploader>
                    </Panel>
                </div>
            )}
        </>
    );
};

export default NewDragDropUploader;
