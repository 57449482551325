import { useState } from "react";
// rsuite
import { InputGroup, Input } from "rsuite";
// interface
import { ISelectInput } from "./SelectInput.interface";
// style
import style from "./SelectInput.module.css";
//gtag
import * as gtag from "utils/gtag";
import Loop from "./icons/icons";

const SelectInput = ({
  placeholder,
  setSearchTerms,
  currentSearch,
  setCurrentSearch,
  timerSkeleton,
  gtagClass,
}: ISelectInput) => {
  const [debounceTimer, setDebounceTimer] = useState<any>(null);

  const handlerCurrentInputSearch = (evt: any) => {
    const value = evt;

    // Actualizar el estado del input inmediatamente
    setCurrentSearch(value);

    // Si ya hay un temporizador en marcha, lo limpiamos
    if (debounceTimer) clearTimeout(debounceTimer);

    // Iniciar un nuevo temporizador
    const newTimer = setTimeout(() => {
      if (value.length >= 3) {
        setSearchTerms(value);
      } else {
        setSearchTerms("");
      }
    }, 500);

    setDebounceTimer(newTimer);
  };

  const handlerCurrentSearch = () => setSearchTerms(currentSearch);

  const handleInputFocus = () => {
    gtag.event({
      action: "click",
      category: "my-orders",
      label: "input__search-orders",
      value: 0,
    });
  };

  return (
    <div className={`${gtagClass} ${style["container-input"]}`}>
      <InputGroup
        size="lg"
        className={`${gtagClass} ${style["input-group"]}`}
        disabled={timerSkeleton}
      >
        <Input
          disabled={timerSkeleton}
          placeholder={placeholder}
          className={`${gtagClass} ${style["input-search"]}`}
          onChange={(evt) => handlerCurrentInputSearch(evt)}
          onFocus={handleInputFocus}
          value={currentSearch}
        />
        <InputGroup.Button
          className={style["search-button"]}
          onClick={handlerCurrentSearch}
        >
          {timerSkeleton ? (
            <Loop color={"#ACAEC4"} />
          ) : (
            <Loop color={"#0171e6"} />
          )}
        </InputGroup.Button>
      </InputGroup>
    </div>
  );
};
export default SelectInput;
