import { ExAvatarConnections } from '@commons/EXComponentsLibrary';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { Modal } from 'rsuite';
import stringAvatar from 'utils/avatar-name';
import { requiresCatalog } from 'utils/drugManufacturers/specialDrugs';
import { Status } from 'utils/hooks';
import NewFormConnectionV4 from '../NewFormConnectionV3/NewFormConnectionV4';
import { initialValue } from '../NewFormConnectionV3/schema/formShemaModel';
import { NewTypeConnectionsV3 } from '../NewTypeConnectionsV3';
import styles from './NewModalCredentials.module.css';

const NewModalCredentials = ({ modalUser, mutateClientPos, toggleCard, handlerOnCloseModal }) => {
    const {
        drugConnection,
        drugManufacturerUrl,
        getDrugInformationById,
        getNameById,
        handlerDrugFormConnection,
        isEditing,
        orderByCredentialType,
        statusApiCreateCredential,
        toggleModal,
        typeConnectionName,
        visibilityForm,
    } = useModalCredentialsContext();

    const { formatStatus } = getDrugInformationById();

    return (
        <Modal open={toggleModal?.visibility} className={styles['modal']} overflow={false}>
            <img
                src="/assets/images/close.svg"
                alt="close-lock"
                className={`${styles['close-icon']} ${
                    statusApiCreateCredential === Status.Pending ? styles['disabled'] : ''
                }`}
                onClick={() => statusApiCreateCredential !== Status.Pending && handlerOnCloseModal()}
                style={{
                    cursor: statusApiCreateCredential === Status.Pending ? 'not-allowed' : 'pointer',
                }}
                draggable={false}
            />
            <Modal.Header closeButton={false} className={styles['modal-header']}>
                <img src="/assets/images/extendeal.png" className={styles['extendeal-image']} />
                <img src="/assets/images/swap_horiz.svg" />
                {drugConnection?.id && (
                    <ExAvatarConnections
                        initialsName={stringAvatar(getNameById(drugConnection?.id), false, drugConnection?.id)}
                        status={formatStatus}
                        style={{ width: 32, height: 32, fontSize: 12 }}
                    />
                )}
            </Modal.Header>
            <Modal.Body className={styles['modal-body']}>
                <div style={{ display: 'block' }}>
                    {!!visibilityForm || initialValue.username !== '' || requiresCatalog(drugConnection?.id) ? (
                        <NewFormConnectionV4
                            modalUser={modalUser}
                            orderByCredentialType={orderByCredentialType}
                            drugConnections={drugConnection}
                            typeConnectionName={typeConnectionName}
                            mutateClientPos={mutateClientPos}
                            toggleCard={toggleCard}
                        />
                    ) : (
                        <NewTypeConnectionsV3
                            credentialTypes={drugConnection?.type}
                            handlerDrugFormConnection={handlerDrugFormConnection}
                            drugManufacturerUrl={drugManufacturerUrl()}
                            drugmanufacturerId={drugConnection?.id}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default NewModalCredentials;
