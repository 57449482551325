import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
// configs
import { EX6742, EX7160 } from 'config/flags';
// contexts
import { usePointOfSale } from 'context';
// components
import MinusIcon from '@rsuite/icons/Minus';
import PlusIcon from '@rsuite/icons/Plus';
import { InputGroup, InputNumber } from 'rsuite';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// utils
import { handleMinus, handlePlus, handleValueChange } from './utils';
import { useDebounce } from 'utils/hooks'; // Debounce hook
import { isZettiFormat } from 'utils/pointOfSale';
import { COUNTRY } from 'utils/country';
import { handlerInputValidation } from '@commons/components/RsuiteCounter/handlers/CounterOperations';
// interfaces
import { CounterBoxProps } from './CounterBox.interface';
// styles
import style from './CounterBox.module.css';

const CounterBox = ({
    quantity,
    handleCheckboxOnChange,
    error,
    disabled,
    setIsZero,
    user,
    maxQuantitySplit,
    setMessage,
    showTooltip,
    productMaxMin,
    setLoadingProduct,
    updateQuantityRequired,
    drugmanufacturerIdSelected,
    uncheckProducts,
    addedItems,
    disabledMinus,
    disabledPlus,
    childrenGenerics,
    parentGenericProduct,
    isGenerics = false,
    orderProductId,
    genericsIdsAdd,
    setGenericsIdsAdd
}: CounterBoxProps): JSX.Element => {
    const [value, setValue] = useState(quantity);

    useEffect(() => {
        if (EX6742) {
            if (quantity !== value) {
                setValue(quantity);
            }
        }
    }, [quantity]);

    const inputRef = useRef(null);

    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);

    const EX4800 =  user?.EX4800 && !isZetti && !isGenerics ;
    const EX5937 = user?.EX5937 && user?.client?.country_alfa_2 !== COUNTRY.AR && !isGenerics 
    const EX6100 =  user?.EX6100 && !isGenerics ;

    const debouncedValue = useDebounce(value, 1000);

    const validateValuePlus = (newValue: number) => {
        if (EX5937 && productMaxMin?.maxcant && newValue > productMaxMin.maxcant) {
            return productMaxMin.maxcant;
        }
        if (maxQuantitySplit && newValue > maxQuantitySplit) {
            showTooltip();
            setMessage(3, newValue);
            return maxQuantitySplit;
        }
        return newValue;
    };

    const handlePlusOnClick = () => {
        if (EX6100) {
            setLoadingProduct(true);
            handleCheckboxOnChange(validateValuePlus(value + 1));
            setValue((prev) => validateValuePlus(prev + 1));
        } else {
            if (EX5937) {
                // add validation max limit 999
                if (typeof +value === 'number') {
                    if (+value > productMaxMin?.maxcant) {
                        return handlePlus({
                            value,
                            setValue,
                            handleCheckboxOnChange,
                            updateQuantityRequired,
                            drugmanufacturerIdSelected,
                        });
                    }

                    handlePlus({
                        value,
                        setValue,
                        handleCheckboxOnChange,
                        updateQuantityRequired,
                        drugmanufacturerIdSelected,
                    });
                }
            } else {
                // add validation max limit 999
                if (typeof +value === 'number' && +value + 1 <= 999) {
                    handlePlus({
                        value,
                        setValue,
                        handleCheckboxOnChange,
                        updateQuantityRequired,
                        drugmanufacturerIdSelected,
                    });
                } else {
                    return;
                }
            }
        }
    };

    const handleMinusOnClick = () => {
        if (EX6100) {
            setLoadingProduct(true);
            if (EX7160) {
                if (genericsIdsAdd?.length > 0 && !isGenerics) {
                    //hay genericos agregados y este es el padre
                    handleCheckboxOnChange(Math.max(0, value - 1));
                    setValue((prev) => Math.max(0, prev - 1));
                } else {
                    handleCheckboxOnChange(Math.max(1, value - 1));

                    setValue((prev) => Math.max(1, prev - 1));
                }
            } else {
                handleCheckboxOnChange(Math.max(1, value - 1));

                setValue((prev) => Math.max(1, prev - 1));
            }
        } else {
            handleMinus({
                value,
                setValue,
                handleCheckboxOnChange,
                updateQuantityRequired,
                drugmanufacturerIdSelected,
                addedItems,
                EX4800,
                uncheckProducts,
                childrenGenerics,
                orderProductId,
                setGenericsIdsAdd,
            });
        }
    };

    const handleChangeEvent = (value, e: any) => {
        e.stopPropagation();
        const v = parseInt(value);
        setLoadingProduct(true);
        if (EX5937) {
            if (typeof +v === 'number') {
                if (maxQuantitySplit && +v > maxQuantitySplit) {
                    showTooltip();
                    setMessage(3, +v);
                    return handlerInputValidation(maxQuantitySplit, setValue);
                }
                if (+v > productMaxMin?.maxcant && !!productMaxMin?.maxcant) {
                    return handlerInputValidation(productMaxMin?.maxcant, setValue);
                } else if (+v < productMaxMin?.mincant && !!productMaxMin?.mincant) {
                    return handlerInputValidation(productMaxMin?.mincant, setValue);
                }
                return handlerInputValidation(v, setValue);
            } else {
                return;
            }
        } else {
            if (typeof +v === 'number' && +v <= 999) {
                if (maxQuantitySplit && +v >= maxQuantitySplit) {
                    return handlerInputValidation(maxQuantitySplit, setValue);
                }
                return handlerInputValidation(v, setValue);
            } else {
                return;
            }
        }
    };

    const validateInvalidChars = (key) => {
        const invalidChars = ['+', '-', 'e', 'E', '.', ','];
        if (invalidChars.includes(key)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (EX4800) {
            setIsZero(value === 0);
        }
    }, [value, setIsZero]);

    // useEffect(() => {
    //     if (!PERFOMANCE_01) {
    //         if (EX6100 && debouncedValue !== quantity) {
    //             handleCheckboxOnChange(debouncedValue);
    //         }
    //     }
    // }, [debouncedValue]);

    return (
        <RsuiteTooltip
            placement="bottomStart"
            trigger="hover"
            text={`${t('efficientPurchase.cellProduct.split_tooltip')}`}
            disabled={!disabledPlus}
            customStyle={{
                marginLeft: 34,
            }}
        >
            <InputGroup
                className={`${style['container-counterbox']} ${error ? style['error'] : ''} ${
                    disabled ? style['disabled'] : ''
                }`}
            >
                <InputGroup.Button
                    className={style['minus-button']}
                    disabled={disabledMinus}
                    onClick={handleMinusOnClick}
                >
                    <MinusIcon />
                </InputGroup.Button>
                <InputNumber
                    className={`${style['custom-input-number']} ${disabled ? style['counter-value-disabled'] : ''}`}
                    value={value}
                    ref={inputRef}
                    onKeyPress={(event: any) => {
                        event.stopPropagation();

                        if (EX6100) {
                            if (validateInvalidChars(event.key)) {
                                event.preventDefault();
                            }
                        }
                        if (event.key === 'Enter') inputRef.current.children[0].blur();
                    }}
                    onChange={handleChangeEvent}
                    onBlur={(event: any) => {
                        event.preventDefault();
                        handleValueChange({
                            event,
                            setValue,
                            handleCheckboxOnChange,
                            value: event.target.value,
                            updateQuantityRequired,
                            drugmanufacturerIdSelected,
                            EX5937,
                            parentGenericProduct,
                            isGenerics,
                            genericsIdsAdd
                        });
                    }}
                />
                <InputGroup.Button className={style['plus-button']} disabled={disabledPlus} onClick={handlePlusOnClick}>
                    <PlusIcon />
                </InputGroup.Button>
            </InputGroup>
        </RsuiteTooltip>
    );
};

export default CounterBox;
