import { memo } from 'react';
import { t } from 'i18next';
// configs
import { EX6674 } from 'config/flags';
// contexts
import { useDrugManufacturerWidgetContext } from 'context';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// utils && hooks
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
// interfaces
import { ISuggestedButtonsProps } from './SuggestedButtons.interface';
// styles
import styles from './SuggestedButtons.module.css';
import { useHubspotStatus } from 'context/HubspotStatusContext/HubspotStatusContext';
import { RsuiteTooltip } from '@commons/components';
import { Tooltip, Whisper } from 'rsuite';
import { useUser } from 'utils/hooks';

const SuggestedButtons = ({ child, handleRemove, onClickRequestVendor, isVendorRequested }: ISuggestedButtonsProps) => {
    const { triggerModal } = useDrugManufacturerWidgetContext();
    const vendorAccepted = child?.vendor?.status === 1; //vendor aceptado
    const isVendor = child?.type?.id === EDrugManufacturerTypeId.vendor && !vendorAccepted;
    const { disabledHubspotClient } = useHubspotStatus();

    const { user } = useUser({})
    const EX7402 = user?.EX7402

 

    return (
        <>
            {disabledHubspotClient && isVendor && EX7402 ? (
                <RsuiteTooltip
                    trigger="hover"
                    placement="top"
                    text='Contactá a un ejecutivo de cuentas'
                    disabled={!disabledHubspotClient}
                >
                    <span>
                        <ExButton
                            size="md"
                            appearance={isVendor ? 'primary' : 'outline'}
                            className={`${styles['button']} ${
                                !isVendor ? 'button__connect-wholesaler' : 'button__request-wholesaler-1'
                            }`}
                            onClick={() =>
                                isVendor
                                    ? onClickRequestVendor()
                                    : triggerModal(child, child.id, child.credential_types, child.type.id)
                            }
                            disabled={isVendorRequested || disabledHubspotClient }
                        >
                            {isVendor ? t('home.widgetButtonRequest') : t('home.widgetButtonConnect')}
                        </ExButton>
                    </span>
                </RsuiteTooltip>
            ) : (
                <ExButton
                    size="md"
                    appearance={isVendor ? 'primary' : 'outline'}
                    className={`${styles['button']} ${
                        !isVendor ? 'button__connect-wholesaler' : 'button__request-wholesaler-1'
                    }`}
                    onClick={() =>
                        isVendor
                            ? onClickRequestVendor()
                            : triggerModal(child, child.id, child.credential_types, child.type.id)
                    }
                    disabled={isVendorRequested}
                >
                    {isVendor ? t('home.widgetButtonRequest') : t('home.widgetButtonConnect')}
                </ExButton>
            )}
            <img
                src={'/assets/images/close_blue.png'}
                alt="close icon"
                width={16}
                height={16}
                draggable={false}
                className={`${styles['close-icon']} ${isVendor ? 'button__close-wholesaler-1' : ''} ${
                    isVendorRequested && EX6674 ? styles['disabled'] : ''
                }`}
                onClick={() => {
                    if (EX6674) {
                        if (!isVendorRequested) {
                            handleRemove();
                        }
                    } else {
                        handleRemove();
                    }
                }}
            />
        </>
    );
};

export default memo(SuggestedButtons);
