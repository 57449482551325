import { useEffect } from 'react';
import { t } from 'i18next';
// interface
import { IPath } from './Path.interface';
// next
import Link from 'next/link';
import { useRouter } from 'next/router';
// rsuite
import { Navbar } from 'rsuite';
// style
import style from './Path.module.css';
// mui v5
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { EX4378, EX4569, EX5057, EX5225 } from 'config/flags';
import { NewUseCart, useAlert, useCreateOrder, usePointOfSale, useSnackbar } from 'context';
import { useStatusContext } from 'context/control-state';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { useNewSearchProduct } from 'context/openSearch/newSearchDataContext';
import { useDrugManufacturersByOrderId, useUser } from 'utils/hooks';
import { handlerLink } from '../../Sidebar/BodySidebar/handlers/handler.routing';
import { isZettiFormat } from 'utils/pointOfSale';

const Path = ({ label, href, haveGoBack, isEfficientPurchases, isConfirmationEfficientPurchases, clientId }: IPath) => {
    const { user } = useUser({});
    const router = useRouter();
    const EX4904 = user?.EX4904;
    const EX5943 = user?.EX5943; //epic rediseño home

    const { showLoadingScreen, handleSwitchViewsCancelOrder } = useCreateOrder();
    const { setVisibleDragDrop } = useDragAndDrop();
    const { handleResetVisibility } = NewUseCart();
    const { counterStep } = useStatusContext();
    // show modal
    const snackbar = useSnackbar();
    const { show } = useAlert();
    const { pointOfSale } = usePointOfSale();

    const steps = ['#step-5', '#step-4'];
    const controlStep = steps.find((element) => counterStep === element);
    const { mutateDrugManufacturers } = useDrugManufacturersByOrderId(router?.query?.orderId);
    // context search
    const { handleOpen } = useNewSearchProduct();

    useEffect(() => {
        const handleRouteChangeStart = (url) => {
            if (localStorage?.getItem('redirectTo') === 'home') {
                localStorage?.removeItem('redirectTo');
            }
        };
        // Escucha el inicio del cambio de ruta
        router.events.on('routeChangeStart', handleRouteChangeStart);
        // Limpia el evento cuando el componente se desmonte
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
        };
    }, [router]);

    const handlerExit = (event) => {
        event.preventDefault();
        handleOpen(false);
        if (localStorage?.getItem('redirectTo') === 'home') {
            history.back();
        } else if (!!isEfficientPurchases) {
            router.replace(href);
        } else if (!!isConfirmationEfficientPurchases) {
            mutateDrugManufacturers();
            router.back();
        } else {
            router.push(href);
        }
    };

    // handler for the link click if the flag and showLoadingScreen is not active will use the href of Link component
    let posSelected = null;

    // retrieve a Point of Sale from localStorage, ensuring we are in a client-side rendering environment.
    if (typeof window !== 'undefined') {
        const posItem = localStorage.getItem('pdvSelected');
        if (posItem) {
            posSelected = JSON.parse(posItem);
        }
    }

    const hrefs = posSelected?.id
        ? `/${clientId}/efficient-purchase/${posSelected?.id}`
        : `/${clientId}/efficient-purchase`;

    const handleLinkClick = (event) => {
        if (EX4378 && showLoadingScreen) {
            event.preventDefault();
            show({
                className: '',
                title: EX5225
                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyEight')
                    : 'Descartar',
                cancel: true,
                size: 'xs',
                body: EX5225
                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyNine')
                    : 'Se descartará la obtención de datos.',
                action: EX5057 ? t('efficientPurchasing.comparative.labelConfirmDiscard') : 'Si, descartar',
                onConfirm: () => {
                    handlerLink(hrefs, router, clientId, posSelected?.id);
                    if (EX5943) {
                        if (!isZettiFormat(pointOfSale)) {
                            setVisibleDragDrop(false);
                        }
                    } else {
                        setVisibleDragDrop(false);
                    }
                    handleOpen(false);
                    handleResetVisibility();
                    setTimeout(() => {
                        handleSwitchViewsCancelOrder();
                        snackbar.show({
                            message: EX5225 ? t('snackbar.labelDiscardOrder') : 'Se descartó la compra.',
                        });
                    }, 100);
                },
                onCancel: () => {},
            });
        }
    };

    return (
        <div className={style['container-breadcrumb']}>
            {((!!EX4904 && router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation') ||
                (!!EX4569 && router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison')) && (
                <Link href={!EX4378 ? `/${clientId}/efficient-purchase` : hrefs} onClick={handleLinkClick}>
                    <div className={style['wrapper-image']}>
                        <img src="/assets/images/logo_white.svg" alt="isologo extendeal" />
                    </div>
                </Link>
            )}
            {!!haveGoBack && (
                <Navbar.Brand className={style['wrapper-icon']} onClick={(event) => handlerExit(event)}>
                    <ArrowBackIcon />
                </Navbar.Brand>
            )}

            <p
                className={
                    controlStep
                        ? style['path-container-blur']
                        : `${style['path-container']} ${!haveGoBack ? style['text-with-padding'] : ''}`
                }
            >
                {label}
            </p>
        </div>
    );
};

export default Path;
