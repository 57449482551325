import { ServiceId } from 'common/types';
import { useEffect, useState } from 'react';
import { useFetchingContext, usePointOfSale } from 'context';
import { useUser } from 'utils/hooks';
// import { useProductComparison } from 'context/product-comparison.context';

export const ServiceConfig = () => {
    //context
    const { user } = useUser({});
    const { pointOfSale } = usePointOfSale();
    // const { loading } = useProductComparison();
    const { loading } = useFetchingContext();

    //hooks
    const [tolerance, setTolerance] = useState(false);
    const [service, setService] = useState(0);
    const serviceIsPrioritiesOrDistribution = service === ServiceId.ByPercentage;

    useEffect(() => {
        const findServiceId = user.pointsOfSale.find((p) => pointOfSale?.id === p.id);
        setService(findServiceId?.service_id);
        handleTolerance();
    }, [user, loading]);

    const handleTolerance = () => {
        let findTolerance = user?.pointsOfSale.find((p) => pointOfSale?.id === p?.id);

        if (serviceIsPrioritiesOrDistribution) {
            setTolerance(findTolerance?.d_tolerance > 0);
        } else {
            setTolerance(findTolerance?.tolerance > 0);
        }
    };
    return { tolerance, service: serviceIsPrioritiesOrDistribution };
};
