import React, { useEffect, useState } from 'react';
import { DrawerHeader, DrawerFooter, DrawerBody } from '.';
import { NewUseCart, useCreateOrder, usePointOfSale } from 'context';
import styles from './DrawerCart.module.css';
import { useRouter } from 'next/router';
import { handlerLink } from 'commons/components/Layouts/Sidebar/BodySidebar/handlers/handler.routing';
import { itemsLinks } from 'commons/components/Layouts/Sidebar/routes/links';
import { useUser } from 'utils/hooks';
import { hiddenCart } from 'commons/components/Layouts/utils/hiddenCart';
import { useTranslation } from 'react-i18next';

const DrawerCart = () => {
    // state
    const [hasScroll, setHasScroll] = useState(false);

    // hooks & context
    const {
        visibility,
        toggle,
        isExpanded,
        expand,
        quantityCart,
        productsList,
        openModalDelete,
        setOpenModalDelete,
        animation,
        updateProduct,
        fetching,
        initComparation,
        animationInProduct,
    } = NewUseCart();

    const { isFetching } = useCreateOrder();

    const { user } = useUser({});

    const { pointOfSale } = usePointOfSale();

    // router
    const router = useRouter();

    //translation
    const { t } = useTranslation();

    // object used for push home
    const redirect = itemsLinks(t)[0];

    // listen scroll
    useEffect(() => {
        const drawerBody: any = document.getElementById('drawer-body'); // Reemplaza con el selector adecuado

        if (drawerBody.scrollHeight > drawerBody.clientHeight) {
            setHasScroll(true);
        } else {
            setHasScroll(false);
        }
    }, [productsList.length]);

    const validateOnlyInResults = router?.pathname === '/[clientId]/efficient-purchase/[posId]/search-results';

    return (
        <div
            className={`${styles['drawer-wrapper']} 
                ${isExpanded && !validateOnlyInResults && styles['is-expanded']}
                ${isExpanded && !!validateOnlyInResults && styles['is-expanded-results']}
                ${visibility && styles['drawer-fade-in']}
                ${animation && styles['drawer-fade-out']}`}
            style={{
                position: router.pathname === '/[clientId]/efficient-purchase/[posId]' ? 'fixed' : 'initial',
            }}
        >
            <DrawerHeader
                toggle={toggle}
                expand={expand}
                isExpanded={isExpanded}
                quantityCart={quantityCart()}
                setOpenModalDelete={() => setOpenModalDelete(!openModalDelete)}
                fetching={fetching}
                allowExpand={!hiddenCart(router.pathname)}
                handlePushRouter={() => {
                    handlerLink(redirect.href, router, user, pointOfSale);
                }}
                pointOfSaleName={pointOfSale?.name}
            />
            <DrawerBody
                products={productsList}
                quantityCart={quantityCart()}
                handleUpdateProduct={updateProduct}
                pointOfSale={pointOfSale}
                fetching={fetching}
                animationInProduct={animationInProduct}
            />
            {!!quantityCart() && !fetching && (
                <DrawerFooter initComparation={() => initComparation()} hasScroll={hasScroll} isFetching={isFetching} />
            )}
        </div>
    );
};

export default DrawerCart;
