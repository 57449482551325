// components
import { ActivateForm } from './ActivateForm';
import { ExpiredToken } from './ExpiredToken';
// utils && hooks
import { useActivateUser } from '../../hooks';

const Content = () => {
    const { loading, status, redirectToLogin } = useActivateUser();
    if (status === 100) return null;
    return (
        <>{status === 404 ? <ExpiredToken loading={loading} redirectToLogin={redirectToLogin} /> : <ActivateForm />}</>
    );
};

export default Content;
