import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
// apis
import { createAccountByFreeTrial } from 'api';
// components
import { Form as FormRsuite, Input, InputGroup, SelectPicker, InputNumber } from 'rsuite';
import { ExAutocompleteGmaps, ExButton } from '@commons/EXComponentsLibrary';
import { ErrorModal } from '@commons/components/ErrorModal';
// interfaces
import { IForm, IFormErrors, IFormTouched, IShowModal } from './interfaces';
// utils && hooks
import { checkMissingKeys, formattedPhone } from './utils';
import { LABELS, MESSAGE, TEXT } from './enum';
// styles
import styles from './Form.module.css';
import animationStyles from '../../../animations.module.css';

const _COUNTRYS = [
    {
        name: 'Argentina',
        abbr: 'AR',
        code: '54',
    },
    {
        name: 'Colombia',
        abbr: 'CO',
        code: '57',
    },
    {
        name: 'Mexico',
        abbr: 'MX',
        code: '52',
    },
    // En el caso de necesitar agregar paises
    // { name: 'España', abbr: 'ES', code: '34' },
    // { name: 'Estados Unidos', abbr: 'US', code: '1' },
    // { name: 'Brazil', abbr: 'BR', code: '55' },
];

const VOLUME_BY_COUNTRY = {
    AR: [
        { label: 'Hasta AR$ 2.000.000', value: 'A' },
        { label: 'Entre AR$ 2.000.000 y AR$ 20.000.000', value: 'B' },
        { label: 'Más de AR$ 20.000.000', value: 'C' },
        { label: 'Prefiero no decirlo', value: null },
    ],
    CO: [
        { label: 'Hasta COL$ 8.000.000', value: 'A' },
        { label: 'Entre COL$ 8.000.000 y COP$ 80.000.000', value: 'B' },
        { label: 'Más de COL$ 80.000.000', value: 'C' },
        { label: 'Prefiero no decirlo', value: null },
    ],
    MX: [
        { label: 'Hasta MXN$ 40.000', value: 'A' },
        { label: 'Entre MXN$ 40.000 y MXN$ 400.000', value: 'B' },
        { label: 'Más de MXN$ 400.000', value: 'C' },
        { label: 'Prefiero no decirlo', value: null },
    ],
};

const data = _COUNTRYS.map((item) => ({
    label: item.abbr,
    value: `${item.code}`,
}));



const KEYS = [
    'first_name',
    'last_name',
    'email',
    'country_alfa_2',
    'phone_number',
    'pharmacy_name',
    'pharmacy_address',
    'monthly_purchase_volume',
    'pos_count',
    'drug_manufacturer_count',
];

const Form = ({ setStep, formValues, setFormValues }: IForm) => {
    const [formTouched, setFormTouched] = useState<IFormTouched>({});
    const [formErrors, setFormErrors] = useState<IFormErrors>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [animationStep, setAnimationStep] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<IShowModal>({
        state: false,
        message: '',
    });
    const selectCountryRef: any = useRef(null);
    const parentRef: any = useRef(null);

    useEffect(() => {
        setFormTouched({});
        setFormErrors({});
        setIsLoading(false);
    }, []);

    const handleBlur = (field, value) => {
        setFormTouched((prev) => ({ ...prev, [field]: value }));
    };

    const handleChangePhone = (value) => {
        const onlyNumbers = value.replace(/\D/g, ''); // Elimina todo excepto números
        if (onlyNumbers.length === 0) {
            setFormErrors((prev) => ({
                ...prev,
                phone_number: TEXT.FIELD_REQUIRED,
            }));
            // Actualiza el valor en formValues
            setFormValues((prev) => ({
                ...prev,
                phone_number: '',
            }));
            return;
        }

        setFormErrors((prev) => ({
            ...prev,
            phone_number: false,
        }));

        setFormValues((prev) => ({
            ...prev,
            phone_number: formattedPhone(onlyNumbers),
        }));
    };

    const showErrorModal = (message) => {
        setShowModal({ state: true, message });
    };

    const handleSubmit = async () => {
        if (!isLoading) {
            if (
                checkMissingKeys(formValues, KEYS) ||
                Object.keys(formValues)?.length === 0 ||
                Object.keys(formErrors)?.length === 0 ||
                !Object.values(formErrors).every((value) => !value)
            ) {
                // Levantar errores
                setFormErrors((prevState) => {
                    const updatedErrors = { ...prevState };
                    KEYS.forEach((key) => {
                        if (!Object.prototype.hasOwnProperty.call(updatedErrors, key)) {
                            updatedErrors[key] = TEXT.FIELD_REQUIRED;
                        }
                    });
                    return updatedErrors;
                });

                setFormTouched((prevState) => {
                    const updatedErrors = { ...prevState };
                    KEYS.forEach((key) => {
                        if (!Object.prototype.hasOwnProperty.call(updatedErrors, key)) {
                            updatedErrors[key] = true;
                        }
                    });
                    return updatedErrors;
                });
                return;
            }
            setIsLoading(true);

            const payload = {
                ...formValues,
                country_alfa_2: formValues.country_alfa_2.label,
                phone_number: `+${formValues.country_alfa_2.value}${formValues?.phone_number?.replace(/\s+/g, '')}`,
                monthly_purchase_volume:formValues?.monthly_purchase_volume?.value
            };

            await createAccountByFreeTrial(payload)
                .then(async (res) => {
                    if (res.ok) {
                        setAnimationStep(true);
                    } else {
                        const response = await res.json();
                        if (response?.data?.validation) {
                            setFormErrors((prevState) => {
                                const validationErrors = Object.keys(response.data.validation).reduce((acc, key) => {
                                    acc[key] = response.data.validation[key];
                                    return acc;
                                }, {});
                                return {
                                    ...prevState,
                                    ...validationErrors,
                                };
                            });

                            setFormTouched((prevState) => {
                                const validationErrors = Object.keys(response.data.validation).reduce((acc, key) => {
                                    acc[key] = true;
                                    return acc;
                                }, {});
                                return {
                                    ...prevState,
                                    ...validationErrors,
                                };
                            });
                        } else {
                            showErrorModal(MESSAGE.PROCESSING_ERROR);
                        }
                    }
                })
                .catch((err) => {
                    showErrorModal(MESSAGE.PROCESSING_ERROR);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <div
            className={`${styles['container']} ${animationStyles['fade-in-right']} ${
                animationStep ? animationStyles['fade-out-left'] : ''
            }`}
            onAnimationEnd={(e) => {
                if (/fade-out-left/.test(e?.animationName)) {
                    setStep(1);
                }
            }}
        >
            <ErrorModal
                open={showModal.state}
                handleClose={setShowModal}
                title={'Error'}
                text={showModal.message}
                buttonText={'Entendido'}
            />
            <div className={styles['wrapper-form']}>
                <div className={styles['form']}>
                    <div>
                        <h2 className={styles['title-form']}>Crea tu cuenta</h2>
                    </div>
                    <FormRsuite
                        fluid
                        onSubmit={handleSubmit}
                        onChange={(e, event: any) => {
                            setFormErrors((prev) => ({
                                ...prev,
                                [event.target.name]: event.target.value?.trim() === '' ? TEXT.FIELD_REQUIRED : false,
                            }));

                            setFormValues(e);
                        }}
                        formValue={formValues}
                        className={styles['form-controls']}
                    >
                        {KEYS.map((key) => {
                            if (key === 'country_alfa_2') {
                                return (
                                    <FormRsuite.Group
                                        key={key}
                                        controlId={key}
                                        className={styles['country']}
                                        onFocus={() => handleBlur(key, false)}
                                        onBlur={() => handleBlur(key, true)}
                                        onClick={() => {
                                            if (selectCountryRef.current) {
                                                const inputElement = selectCountryRef.current.root.querySelector(
                                                    'input',
                                                );
                                                if (inputElement) {
                                                    inputElement.focus();
                                                }
                                            }
                                        }}
                                    >
                                        <FormRsuite.ControlLabel>{LABELS[key]}*</FormRsuite.ControlLabel>
                                        <SelectPicker
                                            container={
                                                typeof window !== 'undefined' && parentRef?.current
                                                    ? parentRef.current || document?.body
                                                    : undefined
                                            }
                                            ref={selectCountryRef}
                                            onClose={() => handleBlur(key, true)}
                                            onOpen={() => handleBlur(key, false)}
                                            name={key}
                                            value={formValues?.[key]?.label || null}
                                            onSelect={(value, item, ev) => {
                                                setFormErrors((prev) => ({
                                                    ...prev,
                                                    [key]: !item,
                                                }));
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    [key]: item,
                                                }));
                                            }}
                                            cleanable={false}
                                            data={data}
                                            onClean={() => {
                                                handleBlur(key, false);
                                                setFormErrors((prev) => ({
                                                    ...prev,
                                                    [key]: TEXT.FIELD_REQUIRED,
                                                }));
                                            }}
                                            className={`${styles['select']} ${
                                                formTouched?.[key] && !!formErrors?.[key] ? styles['country-error'] : ''
                                            }`}
                                            placeholder=" "
                                            searchable={false}
                                            placement="bottom"
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 4,
                                                        }}
                                                    >
                                                        <Image
                                                            src={`/assets/images/${label}.svg`}
                                                            alt="arg icon"
                                                            width={16}
                                                            height={16}
                                                        />{' '}
                                                        {label}
                                                    </div>
                                                );
                                            }}
                                            renderValue={(label) => {
                                                return (
                                                    <div>
                                                        <Image
                                                            src={`/assets/images/${label}.svg`}
                                                            alt="arg icon"
                                                            width={16}
                                                            height={16}
                                                        />{' '}
                                                        {label}
                                                    </div>
                                                );
                                            }}
                                        />
                                        <FormRsuite.ErrorMessage
                                            show={formTouched?.[key] && !!formErrors?.[key]}
                                            className={styles['error']}
                                        >
                                            {formErrors?.[key]}
                                        </FormRsuite.ErrorMessage>
                                        <div
                                            ref={parentRef}
                                            style={{ zIndex: 1000, position: 'relative' }}
                                            className={styles['dropdown']}
                                        ></div>
                                    </FormRsuite.Group>
                                );
                            }

                            if (key === 'phone_number') {
                                return (
                                    <FormRsuite.Group key={key} controlId={key} className={`${styles['phone']}`}>
                                        <FormRsuite.ControlLabel>{LABELS[key]}*</FormRsuite.ControlLabel>
                                        <InputGroup
                                            inside
                                            className={
                                                formTouched?.[key] && !!formErrors?.[key] ? styles['phone-error'] : ''
                                            }
                                            style={{ height: 56 }}
                                            onBlur={() => handleBlur(key, true)}
                                            onFocus={() => handleBlur(key, false)}
                                        >
                                            <InputGroup.Addon className={styles['addon']}>
                                                {!!formValues?.country_alfa_2
                                                    ? `+${formValues?.country_alfa_2.value}`
                                                    : '+52'}
                                            </InputGroup.Addon>
                                            <Input
                                                value={formValues?.[key] || ''}
                                                onChange={handleChangePhone}
                                                placeholder="1 55 1234 5678"
                                                style={{ width: '100%' }}
                                            />
                                        </InputGroup>
                                        <FormRsuite.ErrorMessage
                                            show={formTouched?.[key] && !!formErrors?.[key]}
                                            className={styles['error']}
                                        >
                                            {formErrors?.[key]}
                                        </FormRsuite.ErrorMessage>
                                    </FormRsuite.Group>
                                );
                            }

                            if (key === 'pharmacy_address') {
                                return (
                                    <FormRsuite.Group
                                        key={key}
                                        controlId={key}
                                        className={`${styles['address']}`}
                                        onClick={() => {
                                            const inputElement: any = document.querySelector(
                                                'input[name="pharmacy_address"]',
                                            );
                                            if (inputElement) {
                                                inputElement.focus();
                                            }
                                        }}
                                    >
                                        <FormRsuite.ControlLabel>{LABELS[key]}*</FormRsuite.ControlLabel>
                                        <ExAutocompleteGmaps
                                            label=""
                                            name={key}
                                            showLabel={false}
                                            isRequired
                                            placeholder=" "
                                            error={formTouched?.[key] && !!formErrors?.[key]}
                                            onSelect={(object) => {
                                                const { location, placeId, value } = object;
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    pharmacy_address: value,
                                                    latitude: location?.lat,
                                                    longitude: location?.lng,
                                                    geohash: placeId,
                                                }));
                                            }}
                                            country={
                                                !!formValues?.country_alfa_2 ? formValues?.country_alfa_2?.label : null
                                            }
                                            value={formValues?.[key]}
                                            onBlur={() => handleBlur(key, true)}
                                            onFocus={() => handleBlur(key, false)}
                                            disabled={false}
                                            maxWidth
                                            addLatitude={true}
                                            autoComplete={'off'}
                                        />
                                        <FormRsuite.ErrorMessage
                                            show={formTouched?.[key] && !!formErrors?.[key]}
                                            className={styles['error']}
                                        >
                                            {formErrors?.[key]}
                                        </FormRsuite.ErrorMessage>
                                    </FormRsuite.Group>
                                );
                            }
                            if (key === 'monthly_purchase_volume') {
                                const selectedCountry = formValues?.country_alfa_2?.label; // Obtiene el país seleccionado
                                const volumeOptions = selectedCountry ? VOLUME_BY_COUNTRY[selectedCountry] || [] : [];

                                return (
                                    <FormRsuite.Group
                                        key={key}
                                        controlId={key}
                                        className={styles['purchase-volumen']}
                                        onFocus={() => handleBlur(key, false)}
                                        onBlur={() => handleBlur(key, true)}
                                    >
                                        <FormRsuite.ControlLabel>{LABELS[key]}*</FormRsuite.ControlLabel>
                                        <SelectPicker
                                            container={
                                                typeof window !== 'undefined' && parentRef?.current
                                                    ? parentRef.current || document?.body
                                                    : undefined
                                            }
                                            ref={selectCountryRef}
                                            onClose={() => handleBlur(key, true)}
                                            onOpen={() => handleBlur(key, false)}
                                            name={key}
                                            value={formValues?.[key]?.value || null}
                                            onSelect={(value, item) => {
                                                setFormErrors((prev) => ({
                                                    ...prev,
                                                    [key]: !item,
                                                }));
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    [key]: item,
                                                }));
                                            }}
                                            cleanable={false}
                                            data={volumeOptions} // Filtra los datos según el país seleccionado
                                            onClean={() => {
                                                handleBlur(key, false);
                                                setFormErrors((prev) => ({
                                                    ...prev,
                                                    [key]: TEXT.FIELD_REQUIRED,
                                                }));
                                            }}
                                            className={`${styles['select']} ${
                                                formTouched?.[key] && !!formErrors?.[key] ? styles['country-error'] : ''
                                            }`}
                                            placeholder={selectedCountry ? 'Seleccione un volumen' : 'Seleccione un país primero'}
                                            disabled={!selectedCountry} // Deshabilita si no hay país seleccionado
                                            searchable={false}
                                            placement="bottom"
                                        />
                                        <FormRsuite.ErrorMessage
                                            show={formTouched?.[key] && !!formErrors?.[key]}
                                            className={styles['error']}
                                        >
                                            {formErrors?.[key]}
                                        </FormRsuite.ErrorMessage>
                                        <div ref={parentRef} style={{ zIndex: 1000, position: 'relative' }} className={styles['dropdown']}></div>
                                    </FormRsuite.Group>
                                );
                            }

                            if (key === 'pos_count' || key === 'drug_manufacturer_count') {
                                return (
                                    <FormRsuite.Group key={key} controlId={key}>
                                        <FormRsuite.ControlLabel>{LABELS[key]}*</FormRsuite.ControlLabel>
                            
                                        <FormRsuite.Control
                                            name={key}
                                            accepter={InputNumber}
                                            min={0} // Puedes ajustar esto según necesites
                                            size='lg'
                                            className={styles['input-number']}
                                            onBlur={() => handleBlur(key, true)}
                                            onFocus={() => handleBlur(key, false)}
                                        />
                            
                                        <FormRsuite.ErrorMessage
                                            show={formTouched?.[key] && !!formErrors?.[key]}
                                            className={styles['error']}
                                        >
                                            {formErrors?.[key]}
                                        </FormRsuite.ErrorMessage>
                                    </FormRsuite.Group>
                                );
                            }
                            return (
                                <FormRsuite.Group key={key} controlId={key}>
                                    <FormRsuite.ControlLabel>{LABELS[key]}*</FormRsuite.ControlLabel>
                                    <FormRsuite.Control
                                        name={key}
                                        className={`${styles['input']} ${
                                            formTouched?.[key] && !!formErrors?.[key] ? styles['input-error'] : ''
                                        }`}
                                        onBlur={() => handleBlur(key, true)}
                                        onFocus={() => handleBlur(key, false)}
                                    />
                                    <FormRsuite.ErrorMessage
                                        show={formTouched?.[key] && !!formErrors?.[key]}
                                        className={styles['error']}
                                    >
                                        {formErrors?.[key]}
                                    </FormRsuite.ErrorMessage>
                                </FormRsuite.Group>
                            );
                        })}

                        {/* button */}
                        <div className={styles['wrapper-button']}>
                            <ExButton
                                fullWidth
                                type="submit"
                                status="initial"
                                withArrow={true}
                                loading={isLoading}
                                className={styles['button']}
                            >
                                Crear cuenta
                            </ExButton>
                        </div>
                    </FormRsuite>
                </div>
            </div>
        </div>
    );
};

export default Form;
