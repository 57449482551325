import { RsuitePopover } from '@commons/components/RsuitePopover';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { useEffect, useMemo, useRef } from 'react';
import styles from './InsufficientCreditWarning.module.css';
import WarningCardContent from '../WarningCardContent/WarningCardContent';
// import { useProductComparison } from 'context/product-comparison.context';
import { IRsuitePopover } from '@commons/components/RsuitePopover/RsuitePopover.interface';
import { t } from 'i18next';
import { cellEvents } from 'utils/cellEvents';
import { useRouter } from 'next/router';
import { useInsuficientCreditContext } from 'context';

type Props = {
    truncateText: { texts: JSX.Element; hasEllipsis: boolean };
    manufacturerInfo: {
        id: string;
        name: string;
        credit: number;
    };
    placement: IRsuitePopover['placement'];
    isEnabled: boolean;
    fetchProductGroup?: () => void;
};

const InsufficientCreditWarning = ({ truncateText, manufacturerInfo, placement, isEnabled, fetchProductGroup }: Props) => {
    // const {
    //     insufficientCreditIDs,
    //     setInsufficientCreditIDs,
    //     openedInsufficientCreditIDs,
    //     setOpenedInsufficientCreditIDs,
    //     manuallyOpenedCreditTip,
    //     setManuallyOpenedCreditTip,
    // } = useProductComparison();
    const {
        insufficientCreditIDs,
        setInsufficientCreditIDs,
        openedInsufficientCreditIDs,
        setOpenedInsufficientCreditIDs,
        manuallyOpenedCreditTip,
        setManuallyOpenedCreditTip,
    } = useInsuficientCreditContext();

    const warningIconSrc = '/assets/images/warning_square.svg';

    const { query } = useRouter();

    const event = cellEvents(null, null, null, () => {}, isEnabled, manufacturerInfo.id, query, fetchProductGroup)

    const title = t('insufficientCredit.modal.availableCredit');
    const subtitle = t('insufficientCredit.modal.amountExceeded', { drugmanufacturer: manufacturerInfo.name });
    const credit = manufacturerInfo.credit;
    const availableCreditIsZero = credit === 0;

    const popoverRef = useRef<HTMLDivElement | null>(null);

    const onClosePopover = () => {
        setManuallyOpenedCreditTip(undefined);
        setOpenedInsufficientCreditIDs((prevState) => {
            return prevState.includes(+manufacturerInfo.id) ? prevState : prevState.concat([+manufacturerInfo.id]);
        });
        setInsufficientCreditIDs((prevIDs) =>
            prevIDs.map((prevID) => ({
                ...prevID,
                isWarningOpen: false,
            })),
        );
    };

    const onTurnOffDrugManufacturer = () => {
        event.handleOnOffEvent();
        onClosePopover();
    };

    const turnOffManufacturerBtn = {
        label: t('insufficientCredit.modal.button.turnOffManufacturer'),
        action: onTurnOffDrugManufacturer,
        appearance: 'ghost',
        isDisabled: !isEnabled,
    } as const;

    const buttons = [
        ...(availableCreditIsZero ? [turnOffManufacturerBtn] : []),
        { label: t('insufficientCredit.button.understood'), action: onClosePopover, appearance: 'primary' } as const,
    ];

    const isOpen = useMemo(() => {
        const insufficientCreditID = insufficientCreditIDs.find((creditID) => creditID.id === +manufacturerInfo.id);
        const shouldAutoOpen =
            insufficientCreditID.isWarningOpen &&
            !openedInsufficientCreditIDs.includes(+manufacturerInfo.id) &&
            isEnabled;
        const shouldManuallyOpen = manuallyOpenedCreditTip === +manufacturerInfo.id;
        return shouldAutoOpen || shouldManuallyOpen;
    }, [insufficientCreditIDs, openedInsufficientCreditIDs, manuallyOpenedCreditTip]);

    const onOpenPopover = () => {
        setManuallyOpenedCreditTip(+manufacturerInfo.id);
        setInsufficientCreditIDs((prevIDs) =>
            prevIDs.map((prevID) => ({
                ...prevID,
                isWarningOpen: +manufacturerInfo.id === prevID.id,
            })),
        );
    };

    useEffect(() => {
        if(isOpen) {
            const handleScroll = () => {
                onClosePopover();
            };
    
            document.addEventListener('scroll', handleScroll, true);
    
            return () => {
                document.removeEventListener('scroll', handleScroll, true);
            };
        }
    }, [isOpen]);

    return (
        <div className={styles['container-name']} ref={popoverRef}>
            <div className={styles['container-text-image']}>
                <img src={warningIconSrc} className={styles['image']} />
                <RsuiteTooltip
                    placement="top"
                    text={truncateText.texts}
                    trigger="hover"
                    disabled={!truncateText.hasEllipsis}
                >
                    <div className={styles['name']}>{truncateText.texts}</div>
                </RsuiteTooltip>
            </div>
            <RsuitePopover
                open={isOpen}
                placement={placement}
                popoverClassName={styles['popover-container']}
                onClick={onOpenPopover}
                showArrow
                childOne={
                    <WarningCardContent
                        title={title}
                        subtitle={subtitle}
                        credit={credit}
                        iconSrc={warningIconSrc}
                        buttons={buttons}
                        showTip={availableCreditIsZero}
                        tipMessage={{
                            label: t('insufficientCredit.modal.turnOffManufacturerTip'),
                            message: t('insufficientCredit.modal.turnOffManufacturerTipMessage', {
                                drugmanufacturer: manufacturerInfo.name,
                            }),
                        }}
                        onClose={onClosePopover}
                    />
                }
            >
                <p className={styles['error-text']}>{t('insufficientCredit.label.viewInfo')}</p>
            </RsuitePopover>
        </div>
    );
};

export default InsufficientCreditWarning;
