// routes
import { itemsSettings } from '../routes/settings';
// scripts
import { handlerOpenSettings } from './handlers/setting';
// rsuite
import { Nav, Whisper, Tooltip } from 'rsuite';
// style
import style from './FooterSidebar.module.css';
import { useEffect } from 'react';

const FooterSidebar = () => {
  const loadHubspotChat = () => {
    // @ts-ignore
    window.HubSpotConversations?.widget.load();

    return () => {
      // @ts-ignore
      window.HubSpotConversations.widget.remove();
      // @ts-ignore
      window.hsConversationsOnReady = [];
    };
  };

  useEffect(() => {
    loadHubspotChat();
  }, []);

  return (
    <Nav>
      <div className={style['sidenav-footer']}>
        {itemsSettings.map(({ label, icon, href }) => {
          return (
            <div className={style['wrapper-items-settings']} key={`footer_siderbar_${label}`}>
              <Whisper
                speaker={
                  <Tooltip
                    className={style['tooltip-component']}
                    placement='right'
                  >
                    {label}
                  </Tooltip>
                }
              >
                {/*  <div className={style['container-settins-icons']}> */}

                {/* ESTO ES POR EL BOTON DE HASPOT Y EL ONBOARDING */}
                <span
                  style={{ position: 'absolute', left: 96, top: 20 }}
                  /* className={`${style['content-icon-settings']} ${
                      label === 'Chat' ? style['microphone-icon'] : ''
                    }`} */
                  //onClick={() => handlerOpenSettings(label, href)}
                  id={label === 'Ayuda' ? 'step-6' : ''}
                >
                  {icon}
                </span>
                {/* {label === 'Chat' && (
                    <div className={style['circle-badge']}></div>
                  )} */}
                {/* </div> */}
              </Whisper>
            </div>
          );
        })}
      </div>
    </Nav>
  );
};

export default FooterSidebar;
