import { Modal } from 'components';
import style from './ModalConfirmUser.module.css';
import { ButtonGhost, ButtonSolid } from '@commons/components/Button';
import { EX4914 } from 'config/flags';

interface ModalConfirmUser {
    modal: any;
    onSubmit?: any;
    setSendValues?: any;
    credentials: string;
    credential_username: string;
}

const ModalConfirmUser = ({ modal, onSubmit, setSendValues, credentials, credential_username }: ModalConfirmUser) => {
    let regularExpression = /\[(.*?)\]/;
    let duplicateCredential = regularExpression.exec(credentials);

    const handleConnectCredential = () => {
        if (onSubmit) {
            onSubmit(null, true);
        }
        if (setSendValues) {
            setSendValues(true);
        }
        modal.toggle();
    };

    const handleClose = () => {
        if (setSendValues) {
            setSendValues(false);
        }
        modal.toggle();
    };

    return (
        <Modal Width={'370px'} fullWidth maxWidth="sm" open={modal.visibility} onClose={() => {}}>
            <div className={style['icon']} onClick={() => modal.toggle()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M11.9998 13.4L7.0998 18.3C6.91647 18.4834 6.68314 18.575 6.3998 18.575C6.11647 18.575 5.88314 18.4834 5.6998 18.3C5.51647 18.1167 5.4248 17.8834 5.4248 17.6C5.4248 17.3167 5.51647 17.0834 5.6998 16.9L10.5998 12L5.6998 7.10005C5.51647 6.91672 5.4248 6.68338 5.4248 6.40005C5.4248 6.11672 5.51647 5.88338 5.6998 5.70005C5.88314 5.51672 6.11647 5.42505 6.3998 5.42505C6.68314 5.42505 6.91647 5.51672 7.0998 5.70005L11.9998 10.6L16.8998 5.70005C17.0831 5.51672 17.3165 5.42505 17.5998 5.42505C17.8831 5.42505 18.1165 5.51672 18.2998 5.70005C18.4831 5.88338 18.5748 6.11672 18.5748 6.40005C18.5748 6.68338 18.4831 6.91672 18.2998 7.10005L13.3998 12L18.2998 16.9C18.4831 17.0834 18.5748 17.3167 18.5748 17.6C18.5748 17.8834 18.4831 18.1167 18.2998 18.3C18.1165 18.4834 17.8831 18.575 17.5998 18.575C17.3165 18.575 17.0831 18.4834 16.8998 18.3L11.9998 13.4Z"
                        fill="#40435B"
                    />
                </svg>
            </div>
            <Modal.Content Width={'370px'} style={{ padding: 24 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M4.54197 35C4.23641 35 3.95863 34.9236 3.70863 34.7708C3.45863 34.6181 3.26419 34.4167 3.1253 34.1667C2.98641 33.9167 2.91002 33.6458 2.89613 33.3542C2.88224 33.0625 2.95863 32.7778 3.1253 32.5L18.542 5.83333C18.7086 5.55556 18.9239 5.34722 19.1878 5.20833C19.4517 5.06944 19.7225 5 20.0003 5C20.2781 5 20.5489 5.06944 20.8128 5.20833C21.0767 5.34722 21.292 5.55556 21.4586 5.83333L36.8753 32.5C37.042 32.7778 37.1184 33.0625 37.1045 33.3542C37.0906 33.6458 37.0142 33.9167 36.8753 34.1667C36.7364 34.4167 36.542 34.6181 36.292 34.7708C36.042 34.9236 35.7642 35 35.4586 35H4.54197ZM7.41697 31.6667H32.5836L20.0003 10L7.41697 31.6667ZM20.0003 30C20.4725 30 20.8684 29.8403 21.1878 29.5208C21.5072 29.2014 21.667 28.8056 21.667 28.3333C21.667 27.8611 21.5072 27.4653 21.1878 27.1458C20.8684 26.8264 20.4725 26.6667 20.0003 26.6667C19.5281 26.6667 19.1322 26.8264 18.8128 27.1458C18.4934 27.4653 18.3336 27.8611 18.3336 28.3333C18.3336 28.8056 18.4934 29.2014 18.8128 29.5208C19.1322 29.8403 19.5281 30 20.0003 30ZM20.0003 25C20.4725 25 20.8684 24.8403 21.1878 24.5208C21.5072 24.2014 21.667 23.8056 21.667 23.3333V18.3333C21.667 17.8611 21.5072 17.4653 21.1878 17.1458C20.8684 16.8264 20.4725 16.6667 20.0003 16.6667C19.5281 16.6667 19.1322 16.8264 18.8128 17.1458C18.4934 17.4653 18.3336 17.8611 18.3336 18.3333V23.3333C18.3336 23.8056 18.4934 24.2014 18.8128 24.5208C19.1322 24.8403 19.5281 25 20.0003 25Z"
                        fill="#FFC700"
                    />
                </svg>
                {EX4914 ? (
                    <>
                        {' '}
                        <p className={style['title']}>
                            El usuario {credential_username} ya está conectado en:{' '}
                            {duplicateCredential && duplicateCredential[1]}
                        </p>
                        <p className={style['subtitle']}>
                            Los productos van a llegar a la direccion registrada para {credential_username} en{' '}
                            {duplicateCredential && duplicateCredential[1]}
                            <br />
                            <br />
                            ¿Estas seguro de conectarlo a este punto de venta?
                        </p>{' '}
                    </>
                ) : (
                    <>
                        <p className={style['title']}>El usuario ya está conectado en otro punto de venta</p>
                        <p className={style['subtitle']}>
                            Está conectado en: {duplicateCredential && duplicateCredential[1]}.
                            <br />
                            ¿También te gustaría conectarlo en este punto de venta?
                        </p>
                    </>
                )}
                <div>
                    <ButtonSolid
                        text={'Si, conectar'}
                        width={322}
                        height={48}
                        margin="32px 0px 0px"
                        onClick={handleConnectCredential}
                    />
                    <ButtonGhost
                        text={'Conectar otro usuario'}
                        width={322}
                        height={48}
                        margin="8px 0px 0px"
                        onClick={handleClose}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default ModalConfirmUser;
