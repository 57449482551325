import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { useStatusContext } from 'context/control-state';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import style from './ModalOnboarding.module.css';
import { EX5057 } from 'config/flags';

function ModalOnboarding() {
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    useChangeLanguage(userLocaleCountryCode);
    //context onboarding
    const { setIsTourOpen, setDisplayModalOnBoarding } = useStatusContext();

    const handleOnBoarding = () => {
        setIsTourOpen(true);
    };
    const handleFirstTime = () => {
        localStorage.setItem('checkOnboarding', 'true');
        setDisplayModalOnBoarding(false);
    };

    return (
        <div className={style['modal-onBoarding']}>
            <div className={style['icon-close-container']} onClick={handleFirstTime}>
                <CloseIcon className={style['icon-close']} />
            </div>
            <img src={'/assets/images/IsologoHorizontal.svg'} alt="IsologoHorizontal" className={style['icon']} />
            <p className={style['title']}>{t('shoppingCart.walkthrough.shoppingCart_walkthrough_Four')}</p>
            <span className={style['subtitle']}>{t('shoppingCart.walkthrough.shoppingCart_walkthrough_Nine')}</span>
            <button className={`button__experience-start ${style['button']}`} onClick={handleOnBoarding}>
                {!!EX5057 ? t('walkthrough.labelBegin') : 'Comenzar'} <ArrowForwardIcon className={`button__experience-start ${style['arrow']}`} />
            </button>
        </div>
    );
}

export default ModalOnboarding;
