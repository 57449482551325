import { memo } from 'react';
// contexts
import { useGenericsContext, usePointOfSale, useProductsContext } from 'context';
// components
import { GenericSwitch } from '../GenericSwitch';
import { ToolbarCE } from '@commons/modules';
// utils && hooks
import { auxiliaryUtilsFunctions } from './utils/aux.utils';
import { useUser } from 'utils/hooks';
import { isZettiFormat } from 'utils/pointOfSale';
import { useAlertBanner } from '../hooks';
import { useAlerts } from '@commons/modules/TableProductComparisonV2/hooks';
// styles
import styles from './TableToolbar.module.css';

const ToolbarSection = () => {
    // const { productGroups } = useProductComparison();
    const { productGroups } = useProductsContext();

    const { showAllGenerics, setShowAllGenerics } = useGenericsContext();

    const { user } = useUser({});
    // validation is zetti
    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);
    // ff
    const EX4800 = user?.EX4800 && !isZetti;
    const EX6144 = user?.EX6144;
    // aux
    const fx = auxiliaryUtilsFunctions();

    const haveGenerics = fx.handleHaveGenerics(productGroups);

    const showAlert: boolean = useAlertBanner();

    const { hasAlert } = useAlerts();

    const isShowSomeAlert: boolean = (showAlert || hasAlert);

    return (
        <div
            className={`${styles['toolbar-section']} ${EX6144 ? styles['custom-position'] : ''}`}
            style={{
                gridRow: EX6144 ? isShowSomeAlert ? 3 : 2 : 1,
            }}
        >
            <ToolbarCE setOpenDropdown={() => {}} openDropdown={() => {}} />
            {EX4800 && haveGenerics && (
                <GenericSwitch
                    enabled={haveGenerics}
                    onChangeToggle={() => setShowAllGenerics(!showAllGenerics)}
                />
            )}
        </div>
    );
};

export default memo(ToolbarSection);
