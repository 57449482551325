import { useState, useEffect, useRef } from 'react';
import { t } from 'i18next';
import { Modal } from 'components';
import { useStyles } from './ModalPercentageDistribution.styles';
import Tolerance from 'components/Tolerance/Tolerance';
import {
    deletePercentageOfTolerance,
    deletePercentageOfToleranceOrderID,
    postPercentageOfTolerance,
    postPercentageOfToleranceOrderID,
} from 'api';
import { useSnackbar } from 'context';
import { useReloadProducts } from 'context/reload_products';
import ConfirmDeletePercentage from './ConfirmDeletePercentage';
import { EX4201, EX5057, EX6381 } from 'config/flags';
import { useUser } from 'utils/hooks';
import { DrugManufacturersId } from 'utils/interfaces';
import { OfferCenters } from 'utils/enums';
import InputRow from '@commons/modules/InitialConfig/PointOfSales/PercentageDistribution/InputRow/InputRow';
import ActionsButtons from '@commons/modules/InitialConfig/PointOfSales/PercentageDistribution/ActionsButtons/ActionsButtons';
import { COUNTRY } from 'utils/country';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';

const ModalPercentageDistribution = ({
    open,
    isOrderId,
    handleClose,
    deletePercentage,
    drugManufacturers,
    ID,
    mutateUser,
    percentages,
    handlePercentages,
    tolerance,
    setTolerance,
    setReloadDistribution,
    reloadDistribution,
    newDrugmanufacturer,
}) => {
    const classes = useStyles();
    const buttonRef = useRef(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [totalError, setTotalError] = useState('');
    const [buttonRefState, setButtonRefState] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [sumTotal, setSumTotal] = useState(0);
    const [disableSave, setDisableSave] = useState(false);
    const snackbar = useSnackbar();
    const [errors, setErrors] = useState(Array(percentages?.length).fill(''));
    const { setReload } = useReloadProducts();
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const { user, userLocaleCountryCode } = useUser({});
    const EX5713 = user?.EX5713;
    const FFEX6381 = EX6381;
    // new provider priorities and percentages context
    const { refetch } = usePrioritiesPercentagesCeContext();

    useEffect(() => {
        setButtonRefState(buttonRef.current);
        setDisableSave(true);
    }, []);

    useEffect(() => {
        let sum = 0;
        percentages.forEach((value) => {
            if (value) {
                sum = sum + value?.percentage;
            }
        });
        setSumTotal(sum);
    }, [percentages]);

    const handleChange = (id, value) => {
        setDisableSave(false);
        setTotalError('');
        const updatedValues = [...percentages];
        updatedValues[id] = { drug_manufacturer_id: id, percentage: value };
        handlePercentages(updatedValues);

        const updatedErrors = [...errors];
        updatedErrors[id] = '';
        setErrors(updatedErrors);
    };

    const handleFilterSendData = async () => {
        setDisableSave(true);
        let PercentageDistribution = [];
        percentages?.forEach((value) => {
            if (value) {
                PercentageDistribution.push(value);
            }
        });
        handleSendData(PercentageDistribution);
    };

    const handleSendData = async (PercentageDistribution) => {
        let res = null;
        if (isOrderId) {
            res = await postPercentageOfToleranceOrderID(ID, {
                distributions: PercentageDistribution,
                d_tolerance: parseFloat(tolerance.replace(',', '.')),
            });
        } else {
            res = await postPercentageOfTolerance(ID, {
                distributions: PercentageDistribution,
                d_tolerance: parseFloat(tolerance.replace(',', '.')),
            });
        }
        if (res?.status === 200) {
            setDisableSave(true);
            setReloadDistribution(!reloadDistribution);
            mutateUser();
            handleCloseModal();
            snackbar.show({
                message: EX3526
                    ? t('efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionEleven')
                    : 'La configuración se ha guardado.',
            });
        }

        if (!!isOrderId) setReload(true);
    };

    const handleButtonClick = () => {
        let submit = true;
        setShowErrors(false);
        let zeroCount = 0;
        const updatedErrors = [...errors];
        if (sumTotal !== 0) {
            if (!EX5713) {
                percentages?.forEach((value) => {
                    if (value?.percentage === 0) {
                        zeroCount++;
                        updatedErrors[value?.drug_manufacturer_id] = !EX4201
                            ? 'Solo una droguería puede estar en 0%'
                            : t(
                                  'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionTen',
                              );
                        if (zeroCount > 1) {
                            setTotalError(
                                !EX4201
                                    ? 'Solo una droguería puede estar en 0%'
                                    : t(
                                          'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionTen',
                                      ),
                            );
                            setShowErrors(true);
                            setDisableSave(true);
                            submit = false;
                        }
                    }
                });
            }

            setErrors(updatedErrors);

            if (sumTotal !== 100) {
                setTotalError(
                    t('efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionNineteen'),
                );
                submit = false;
                setDisableSave(true);
            } else if (submit) {
                handleFilterSendData();
            }
        }
    };

    const handleCloseModal = () => {
        setShowErrors(false);
        setTotalError('');
        handleClose('');
    };

    const handleDeletePercentage = async () => {
        let res = null;
        if (isOrderId) {
            res = await deletePercentageOfToleranceOrderID(ID);
            refetch();
        } else {
            res = await deletePercentageOfTolerance(ID);
        }
        if (res?.status === 204) {
            setReloadDistribution(!reloadDistribution);
            handleConfirmDelete();
            handleCloseModal();
            setSumTotal(0);
            mutateUser();
            snackbar.show({
                message: t('modals.comparativeSnakcBarOne') || 'Se eliminó la configuración.',
            });
            if (!!isOrderId) setReload(true);
        }
    };

    const handleConfirmDelete = () => {
        setConfirmDelete(!confirmDelete);
    };

    const handleTolerance = (value) => {
        if (sumTotal !== 0) {
            setDisableSave(false);
        }
        setTolerance(value);
    };

    return (
        <>
            <ConfirmDeletePercentage
                modal={confirmDelete}
                handleDeletePercentage={handleDeletePercentage}
                handleConfirmDelete={handleConfirmDelete}
            />
            <Modal fullWidth Width="560px" Height="560px" Percentage={true} open={open}>
                <Modal.Title>
                    {EX5057
                        ? t('efficientPurchasing.priorityAndTolerance.labelTitleModalConfigPercentage')
                        : 'Configurar porcentajes'}
                </Modal.Title>
                <Modal.Content Height={'388px'} style={{ overflowX: 'hidden' }}>
                    <p className={classes.subtitle}>
                        {!EX4201
                            ? 'Define un porcentaje a cada droguería, para que el sistema distribuya la selección a precios iguales. También puedes ingresar una tolerancia.'
                            : t(
                                  'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionSeven',
                              )}
                    </p>
                    <div className={classes.toleranceInputContainer} ref={buttonRef}>
                        <div className={classes.toleranceTextContainer}>
                            <span className={classes.toleranceText}>
                                {EX5057 ? t('efficientPurchasing.comparative.labelTolerance') : 'tolerancia'}
                            </span>
                            <span style={{ color: '#333333', fontSize: 16, marginLeft: 8 }}>
                                {userLocaleCountryCode === COUNTRY.US ? '(optional)' : '(opcional)'}
                            </span>
                        </div>
                        <Tolerance
                            tolerance={tolerance}
                            buttonRef={buttonRef}
                            setTolerance={handleTolerance}
                            buttonRefState={buttonRefState}
                            setButtonRefState={setButtonRefState}
                        />
                    </div>
                    {drugManufacturers
                        ?.filter((drugManufacturer) => {
                            if (FFEX6381) {
                                if (
                                    (drugManufacturer?.id !== DrugManufacturersId.CentralDeOfertas &&
                                        !!drugManufacturer?.vendor_accepted &&
                                        drugManufacturer?.type_id === EDrugManufacturerTypeId.vendor) ||
                                    drugManufacturer?.type_id !== EDrugManufacturerTypeId.vendor
                                ) {
                                    return drugManufacturer;
                                } else if (
                                    drugManufacturer?.id === DrugManufacturersId.CentralDeOfertas &&
                                    drugManufacturer?.cdo_status_id === OfferCenters.Enabled
                                ) {
                                    return drugManufacturer;
                                }
                            } else {
                                if (
                                    drugManufacturer?.id !== DrugManufacturersId.CentralDeOfertas &&
                                    drugManufacturer?.type_id !== EDrugManufacturerTypeId.vendor
                                ) {
                                    return drugManufacturer;
                                } else if (
                                    drugManufacturer?.id === DrugManufacturersId.CentralDeOfertas &&
                                    drugManufacturer?.cdo_status_id === OfferCenters.Enabled
                                ) {
                                    return drugManufacturer;
                                }
                            }
                        })
                        .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar por nombre
                        .map((drugManufacturer) => (
                            <InputRow
                                open={open}
                                newDrugmanufacturer={newDrugmanufacturer}
                                drugmanufacturer={drugManufacturer}
                                errors={errors}
                                handleChange={handleChange}
                                percentages={percentages}
                                showErrors={showErrors}
                            />
                        ))}
                </Modal.Content>
                <ActionsButtons
                    sumTotal={sumTotal}
                    disableSave={disableSave}
                    totalError={totalError}
                    deletePercentage={deletePercentage}
                    handleDeletePercentage={handleConfirmDelete}
                    handleButtonClick={handleButtonClick}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>
        </>
    );
};

export default ModalPercentageDistribution;
